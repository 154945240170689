import { AutoComplete, Button, notification } from "antd";
import axios from "axios";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useAuth } from "../../context/auth";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 500px;
  max-width: 100%;
`;

const ModalTitle = styled.h2`
  margin-top: 0;
  font-size: 20px;
`;

const TemplateSelect = styled.select`
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
`;

const TemplateExportModal = ({ isOpen, onClose, onExport, locationId, claimData, fileNumber }) => {
  const [auth] = useAuth();
  const [templates, setTemplates] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState("");
  const [loading, setLoading] = useState(false);
  const [documentName, setDocumentName] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  // Fetch templates on modal open
  useEffect(() => {
    if (isOpen && locationId) {
      fetchTemplatesByLocation();
    }
  }, [isOpen, locationId]);

  const fetchTemplatesByLocation = async () => {
    try {
      const response = await axios.get(`/api/v1/templates/location/${locationId}`, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      });
      const sortedTemplates = (response.data || []).sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setTemplates(sortedTemplates);
    } catch (error) {
      console.error("Error fetching templates:", error);
      notification.error({
        message: "Failed to fetch templates",
        description: error.response?.data?.message || "Error loading templates.",
      });
      setTemplates([]);
    }
  };

  const fetchTemplateUrl = async (templateId) => {
    try {
      const response = await axios.get(`/api/v1/templates/fetch/${templateId}`, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      });
      return response.data.url;
    } catch (error) {
      console.error("Error fetching template URL:", error);
      notification.error({
        message: "Failed to fetch template file",
        description: error.response?.data?.message || "Error fetching template URL.",
      });
      return null;
    }
  };
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const [year, month, day] = dateString.split("T")[0].split("-");
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    return `${monthNames[parseInt(month, 10) - 1]} ${parseInt(day, 10)}, ${year}`;
  };

  const handleExport = async () => {
    if (!selectedTemplateId || !documentName.trim()) {
      notification.warning({ message: "Please select a template and enter a document name." });
      return;
    }
  
    setLoading(true);
    try {
      // Fetch the signed S3 URL for the selected template
      const templateUrl = await fetchTemplateUrl(selectedTemplateId);
      if (!templateUrl) return;
  
      // Populate claim data
      const populatedData = {
        claimNumber: claimData?.clients?.[0]?.claimNumber || "",
        claimNumber2: claimData?.clients?.[1]?.claimNumber || "",
        claimNumber3: claimData?.clients?.[2]?.claimNumber || "",
        claimNumber4: claimData?.clients?.[3]?.claimNumber || "",
        claimNumber5: claimData?.clients?.[4]?.claimNumber || "",
        claimNumber6: claimData?.clients?.[5]?.claimNumber || "",
        claimNumber7: claimData?.clients?.[6]?.claimNumber || "",
        insured: claimData?.policyDetails?.name || "",
        lossDescription: claimData?.lossDetail || "",
        dateOfLoss: formatDate(claimData?.dateOfLoss),
        proscriptionDate: formatDate(claimData?.proscriptionDate),
        mailingAddress: claimData?.policyDetails?.address?.addressLine || "",
        mailingCity: claimData?.policyDetails?.address?.city || "",
        mailingProvince: claimData?.policyDetails?.address?.province || "",
        mailingPostalCode: claimData?.policyDetails?.address?.postalCode || "",
        lossAddress: claimData?.lossAddress || "",
        lossCity: claimData?.lossCity || "",
        lossProvince: claimData?.lossProvince || "",
        lossPostalCode: claimData?.lossPostalCode || "",
        client: claimData?.clients?.[0]?.client.name || "",
        client2: claimData?.clients?.[1]?.client.name || "",
        client3: claimData?.clients?.[2]?.client.name || "",
        client4: claimData?.clients?.[3]?.client.name || "",
        client5: claimData?.clients?.[4]?.client.name || "",
        client6: claimData?.clients?.[5]?.client.name || "",
        client7: claimData?.clients?.[6]?.client.name || "",
        deductible:
          claimData?.policyDetails?.deductible
            ? `$${claimData.policyDetails.deductible}.00`
            : "",
            effectiveDate: formatDate(claimData?.policyDetails?.effectiveDate),
            expirationDate: formatDate(claimData?.policyDetails?.expiryDate),
        fileNumber: claimData?.fileNumber || "",
        policyNumber: claimData?.policyDetails?.policyNumber || "",
        lossType: claimData?.typeOfLossId?.description || "",
        sharePercentage: claimData?.clients[0]?.sharePercentage || "",
        sharePercentage2: claimData?.clients[1]?.sharePercentage || "",
        sharePercentage3: claimData?.clients[2]?.sharePercentage || "",
        sharePercentage4: claimData?.clients[3]?.sharePercentage || "",
        sharePercentage5: claimData?.clients[4]?.sharePercentage || "",
        sharePercentage6: claimData?.clients[5]?.sharePercentage || "",
        sharePercentage7: claimData?.clients[6]?.sharePercentage || "",
        examiner: claimData?.examinerId?.name || "",
        adjuster: claimData?.adjusterId?.name || "",
        broker: claimData?.brokerId?.name || "",
        phone: claimData?.policyDetails?.phone || "",
      };
  
      // Fetch template as array buffer
      const response = await fetch(templateUrl);
      const content = await response.arrayBuffer();
      const zip = new PizZip(content);
      const doc = new Docxtemplater(zip, { nullGetter: () => "" });
      doc.render(populatedData);
  
      // Generate the document blob
      const blob = doc.getZip().generate({
        type: "blob",
        mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });
  
      // Prepare form data for upload
      const formData = new FormData();
      formData.append("files", blob, `${documentName}.docx`); // Backend expects 'files'
      formData.append("folder", ""); // Specify folder if needed
  
      // Upload the document
      const uploadUrl = `/api/v1/claims/${fileNumber}/documents/upload`;
      await axios.post(uploadUrl, formData, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          "Content-Type": "multipart/form-data",
        },
      });
  
      notification.success({ message: "Document uploaded successfully!" });
  
      // Poll for document availability on SharePoint
      const pollForDocument = async () => {
        const maxRetries = 10;
        const interval = 2000;
        let attempts = 0;
  
        while (attempts < maxRetries) {
          try {
            const { data } = await axios.get(`/api/v1/claims/${fileNumber}/documents`, {
              headers: { Authorization: `Bearer ${auth?.token}` },
            });
  
            const documentExists = data.documents.some((doc) => doc.name === `${documentName}.docx`);
  
            if (documentExists) {
              notification.success({
                message: "Document is now available for editing!",
              });
              onExport(populatedData); // Notify parent and close modal
              return true;
            }
          } catch (error) {
            console.error("Polling error:", error);
          }
  
          attempts += 1;
          await new Promise((resolve) => setTimeout(resolve, interval)); // Wait before retrying
        }
  
        notification.error({
          message: "Document availability could not be verified.",
          description: "Please try refreshing the page.",
        });
        return false;
      };
  
      await pollForDocument();
    } catch (error) {
      console.error("Error exporting template:", error);
      notification.error({ message: "Failed to upload document." });
    } finally {
      setLoading(false);
    }
  };
  
   if (!isOpen) return null;

   return (
    <ModalOverlay>
      <ModalContent>
        <ModalTitle>Select a Template</ModalTitle>
        <AutoComplete
  options={templates.map((template) => ({
    value: template._id, // Used for internal selection
    label: template.name, // Displayed to the user
  }))}
  style={{ width: "100%", height: "50px" }}
  placeholder="Choose a template..."
  onSelect={(value) => {
    // Set both the selected template ID and name
    const selected = templates.find((template) => template._id === value);
    setSelectedTemplateId(selected ? selected._id : ""); // Set the ID
    setSelectedTemplate(selected ? selected.name : ""); // Set the name
  }}
  onSearch={(value) => {
    setSelectedTemplate(value); // Allow typed input
  }}
  value={selectedTemplate} // Display the name in the input field
  filterOption={(inputValue, option) =>
    option.label.toLowerCase().includes(inputValue.toLowerCase())
  }
/>

        <input
          type="text"
          placeholder="Enter document name"
          value={documentName}
          onChange={(e) => setDocumentName(e.target.value)}
          style={{ width: "100%", marginTop: "10px", padding: "10px" }}
        />
        <ButtonContainer>
          <Button type="primary" onClick={handleExport} loading={loading}>
            Create
          </Button>
          <Button onClick={onClose} style={{ background: "grey", color: "white" }}>
            Cancel
          </Button>
        </ButtonContainer>
      </ModalContent>
    </ModalOverlay>
  );
};

export default TemplateExportModal;
