import { Select, Table } from "antd";
import axios from "axios";
import jsPDF from "jspdf";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import styled from "styled-components";
import * as XLSX from "xlsx";
import { useAuth } from "../../context/auth";
import Modal from "../common/Modal";
import AddNoteForm from "./AddNoteForm";

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  height: 5vh;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const LeftButtonContainer = styled.div`
  display: flex;
  margin-left: 10px;
  align-items: center;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`;

const RightButtonContainer = styled.div`
  display: flex;
  margin-right: 10px;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
`;

const Btn = styled.button`
  padding: 10px;
  background: #6495ed;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 4px;
  color: #ffffff;
  align-items: center;
  border: none;
  cursor: pointer;
  &:hover {
    background: #e0e4f3;
  } 
  '}`;

const TableContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 10px;
  flex-grow: 1;
  @media only screen and (max-width: 768px) {
    overflow-x: auto; /* Enable horizontal scrolling */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;
  }
`;
const { Option } = Select;

const Notes = ({ claimId }) => {
  const [notes, setNotes] = useState([]);
  const [auth, setAuth] = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalNotes, setTotalNotes] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({ description: "", userCode: "" });

  useEffect(() => {
    fetchNotes();
  }, []);

  useEffect(() => {
    // Recalculate total pages whenever notes or rowsPerPage change
    setTotalPages(Math.ceil(notes.length / rowsPerPage));
  }, [notes, rowsPerPage]);


  const fetchNotes = async () => {
    try {
      const { data } = await axios.get(`/api/v1/getNotes?claim_id=${claimId}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
  
      if (data.success) {
        const sortedNotes = data.party.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setNotes(sortedNotes);
        setTotalNotes(data.totalNotes); // Assuming API provides total notes
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("Error fetching notes.");
    }
  };
  
  useEffect(() => {
    setTotalPages(Math.ceil(totalNotes / rowsPerPage)); // Use totalNotes
  }, [totalNotes, rowsPerPage]);
  

  const handleAddNote = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `/api/v1/createNotes`,
        {
          ...formData,
          userCode: auth?.user?.user_code,
          claim_id: claimId,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );

      if (data.success) {
        fetchNotes();
        setFormData({ description: "", userCode: "" });
        setIsModalOpen(false);
        toast.success("Note added successfully.");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("Error adding note.");
    }
  };

  const displayedNotes = notes.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const downloadPDF = () => {
    const doc = new jsPDF();
    const pageHeight = doc.internal.pageSize.height;
    let y = 10;
  
    doc.setFontSize(12);
    doc.text("Notes List", 10, y);
    y += 10;
  
    notes.forEach((note, index) => {
      const text = `Date: ${note.createdAt}\nCode: ${note.UserCode}\nDescription: ${note.description}`;
      const lines = doc.splitTextToSize(text, 180);
      lines.forEach((line) => {
        if (y > pageHeight - 10) {
          doc.addPage();
          y = 10;
        }
        doc.text(line, 10, y);
        y += 10;
      });
    });
  
    doc.save("notes.pdf");
  };
  
  

  // Excel Download Function
  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      notes.map((note) => ({
        UserCode: note.UserCode,
        Date: note.createdAt,
        Description: note.description,
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Notes");
    XLSX.writeFile(workbook, "notes.xlsx");
  };

  const formatDate = (isoDate) => {
    if (!isoDate) return "";
    const date = new Date(isoDate);
    return date.toLocaleString(); // Format as 'MM/DD/YYYY, HH:MM AM/PM'
  };
  
  const columns = [
    {
      title: "User",
      dataIndex: "UserCode",
      key: "UserCode",
      sorter: (a, b) => (a.UserCode || "").localeCompare(b.UserCode || ""),
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 120, // Set the column width
      render: (date) => formatDate(date), // Use formatDate function here
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text) => text || "N/A",
      sorter: (a, b) => (a.description || "").localeCompare(b.description || ""),
    },
  ];
  

  return (
    <Container>
      <h5>Notes</h5>
      <HeaderContainer>
        <LeftButtonContainer>
          <Btn onClick={() => setIsModalOpen(true)}>Add</Btn>
        </LeftButtonContainer>
        <RightButtonContainer>
          <Btn
            onClick={() => {
              if (
                window.confirm("Are you sure you want to download the PDF?")
              ) {
                downloadPDF();
              }
            }}
          >
            Download PDF
          </Btn>

          <Btn
            onClick={() => {
              if (
                window.confirm(
                  "Are you sure you want to download the Excel file?"
                )
              ) {
                downloadExcel();
              }
            }}
          >
            Download Excel
          </Btn>
        </RightButtonContainer>
      </HeaderContainer>
      <TableContainer>
      <Table
        columns={columns}
        dataSource={notes}
        pagination={{
          current: currentPage,
          pageSize: rowsPerPage,
          onChange: (page) => setCurrentPage(page),
        }}
        rowKey={(record) => record._id}
        style={{ marginTop: "20px" }}
      />
      </TableContainer>
     
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <AddNoteForm
          formData={formData}
          setFormData={setFormData}
          handleSubmit={handleAddNote}
          title={"Add Note"}
        />
      </Modal>
    </Container>
  );
};

export default Notes;
