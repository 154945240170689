import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { Button, Input } from "reactstrap";
import styled from "styled-components";
import Layout from "../../components/Layout/Layout";
import { useAuth } from "../../context/auth";
import useRoles from "../../hooks/useRoles";

const Container = styled.div`
  display: flex;
  background: white;
  padding: 20px;
  border-radius: 8px;
  flex-direction: column;
  flex-grow: 1;
`;

const HeaderContainer = styled.div`
  height: 5vh;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid grey;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    height: 10vh;
  }
`;

const FilterDropdown = styled.select`
  padding: 8px;
  margin-left: 20px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
`;

const Option = styled.option`
  font-size: 14px;
`;

const InputsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Corrected typo */
  padding: 10px;
  @media only screen and (max-width: 768px) {
    flex-direction: column; /* Corrected typo */
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(
    33.33% - 10px
  ); /* Adjust width for three columns with space in between */
  margin-bottom: 20px; /* Margin between rows */
  box-sizing: border-box;
`;

const SubText = styled.div`
  color: rgba(0, 0, 0, 45%);
  font-size: 14px;
  margin: 20px 0px 0px 20px;
`;

const OptionLabel = styled.label`
  margin: 10px 10px 0px 10px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
`;

const CheckboxInput = styled.input`
  margin-right: 5px;
  width: 16px;
  height: 16px;
`;

const categories = [
  {
    name: "Reports",
    options: [
      "create:Reports",
      "update:Reports",
      "read:Reports",
      "delete:Reports",
    ],
  },
  {
    name: "Templates",
    options: [
      "create:Templates",
      "update:Templates",
      "read:Templates",
      "delete:Templates",
    ],
  },
  {
    name: "Clients",
    options: [
      "search:Clients",
      "create:Clients",
      "update:Clients",
      "read:Clients",
      "delete:Clients",
      "delete:Contacts",
    ],
  },
  {
    name: "Vendors",
    options: [
      "search:Vendors",
      "create:Vendors",
      "update:Vendors",
      "read:Vendors",
      "delete:Vendors",
      "delete:Contacts",
      "create:Contacts",
    ],
  },
  {
    name: "Brokers",
    options: [
      "search:Brokers",
      "create:Brokers",
      "update:Brokers",
      "read:Brokers",
      "delete:Brokers",
      "delete:Contacts",
      "create:Contacts",
    ],
  },
  {
    name: "Menu",
    options: ["create:Menu", "update:Menu", "read:Menu", "delete:Menu"],
  },

  {
    name: "Admin",
    options: ["create:Admin", "update:Admin", "read:Admin", "delete:Admin"],
  },
  {
    name: "User",
    options: ["create:User", "update:User", "read:User", "delete:User"],
  },

  {
    name: "Location",
    options: [
      "create:location",
      "update:location",
      "read:location",
      "delete:location",
    ],
  },
  {
    name: "Roles",
    options: ["create:role", "update:role", "read:role", "delete:role"],
  },
  {
    name: "Permissions",
    options: [
      "create:Permissions",
      "update:Permissions",
      "read:Permissions",
      "delete:Permissions",
    ],
  },

  {
    name: "Automation",
    options: [
      "create:Automation",
      "update:Automation",
      "read:Automation",
      "delete:Automation",
    ],
  },
  {
    name: "History",
    options: [
      "create:History",
      "update:History",
      "read:History",
      "delete:History",
    ],
  },
  {
    name: "Reserves",
    options: [
      "create:Reserve",
      "update:Reserve",
      "read:Reserve",
      "delete:Reserve",
    ],
  },
 {
  name: "Documents",
  options: [
    "create:Documents",
    "update:Documents",
    "read:Documents",
    "delete:Documents",
  ],
},
  {
    name: "Claim",
    options: ["create:Claim", "update:Claim", "read:Claim", "delete:Claim"],
  },
  {
    name: "TimeExpense",
    options: ["create:TimeExpense", "update:TimeExpense", "read:TimeExpense", "delete:TimeExpense"],
  },
  {
    name: "Invoice",
    options: ["create:Invoice", "reverse:Invoice", "read:Invoice"],
  },
];

const Permissions = () => {
  const roles = useRoles();
  const [auth] = useAuth();

  const [selectedRole, setSelectedRole] = useState("");
  const [rolePermissions, setRolePermissions] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  const handleRoleChange = async (e) => {
    setSelectedRole(e.target.value);

    try {
      const { data } = await axios.get(`/api/v1/roles/${e.target.value}`, {
        headers: {
          'Authorization': `Bearer ${auth?.token}`,
        }
      });
      if (data.success) {
        setSelectedPermissions(data?.role?.permissions);
        setRolePermissions(data?.role?.permissions);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("something went wrong fetching role permissions");
    }
  };

  const handlePermissionChange = (e, option) => {
    if (selectedRole) {
      let permissions = [...selectedPermissions];

      if (!e.target.checked) {
        if (permissions?.includes(option)) {
          permissions = permissions.filter((item) => item !== option);
        }
      } else {
        if (!permissions.includes(option)) {
          permissions = [...permissions, option];
        }
      }

      setSelectedPermissions(permissions);
    } else {
      toast.error("Select role first");
    }
  };

  const handleSubmit = async () => {
    if (selectedRole) {
      try {
        const { data } = await axios.put(`/api/v1/updatePremissions`, {
          roleId: selectedRole,
          permissions: selectedPermissions,
        }, {
          headers: {
            'Authorization': `Bearer ${auth?.token}`,
          }
        });
        toast.success(data.message);
      } catch (error) {
        toast.error("something went wrong fetching role permissions");
      }
    } else {
      toast.error("Select role first");
    }
  };

  return (
    <Layout>
      <Container>
        <HeaderContainer>
          <div
            style={{ fontSize: "25px", display: "flex", alignItems: "center" }}
          >
            Permissions
          </div>
          <div>
            <FilterDropdown
              id="role_id"
              name="role_id"
              value={selectedRole}
              onChange={handleRoleChange}
            >
              <Option value="">Select Role</Option>
              {roles.map((role) => (
                <Option key={role.id} value={role._id}>
                  {role.name}
                </Option>
              ))}
            </FilterDropdown>
          </div>
        </HeaderContainer>
        <InputsContainer>
          {categories.map((category, index) => (
            <Item key={index}>
              <SubText>{category.name}</SubText>
              {category.options.map((option, idx) => (
                <OptionLabel
                  key={idx}
                  className="d-flex align-items-center gap-2"
                >
                  <Input
                    type="checkbox"
                    checked={
                      selectedPermissions?.includes(option) ? true : false
                    }
                    onChange={(e) => handlePermissionChange(e, option)}
                  />
                  {option}
                </OptionLabel>
              ))}
            </Item>
          ))}
          <Button onClick={handleSubmit}>Submit</Button>
        </InputsContainer>
      </Container>
    </Layout>
  );
};

export default Permissions;
