import { Button, Checkbox, Table } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useAuth } from "../../context/auth";
import Modal from "../common/Modal";

const SelectPartiesModal = ({ visible, onClose, claimId, onSelect }) => {
  const [auth] = useAuth();
  const [parties, setParties] = useState([]);
  const [selectedParties, setSelectedParties] = useState([]);

  useEffect(() => {
    if (visible && claimId) {
      fetchParties();
    }
  }, [visible, claimId]);
  useEffect(() => {
    if (!visible) {
      setSelectedParties([]);  // Clear selections when modal is closed
    }
  }, [visible]);
  

  const fetchParties = async () => {
    try {
      const { data } = await axios.get(`/api/v1/parties?claim_id=${claimId}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
  
      if (data.success) {
        // Sorting parties by claimRole alphabetically
        const sortedParties = data.parties1.sort((a, b) =>
          a.claimRole.localeCompare(b.claimRole)
        );
  
        setParties(sortedParties);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("Something went wrong while getting the parties");
    }
  };
  

  const handlePartySelect = (party) => {
    setSelectedParties((prev) =>
      prev.includes(party) ? prev.filter((p) => p !== party) : [...prev, party]
    );
  };

  const handleConfirmSelection = () => {
    const uniqueSelectedParties = selectedParties.filter(
      (party, index, self) =>
        index === self.findIndex((p) => p.email === party.email)
    );
  
    onSelect(uniqueSelectedParties);
    setSelectedParties([]);  // Reset selections after adding
    onClose();
  };
  
  if (!visible) return null;

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => (
        <Checkbox
          onChange={() => handlePartySelect(record)}
          checked={selectedParties.includes(record)}
        >
          {text}
        </Checkbox>
      ),
    },
    {
      title: "Claim Role",
      dataIndex: "claimRole",
      sorter: (a, b) => a.claimRole.localeCompare(b.claimRole),
    },
    {
      title: "Company",
      dataIndex: "company",
      sorter: (a, b) => a.company.localeCompare(b.company),
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (email) =>
        Array.isArray(email) && email.length > 0
          ? email.map((emailObj) => emailObj.address).join(", ")
          : "No email provided",
    },
  ];

  return (
    <Modal open={visible} onClose={onClose} width={1000}>
      <h5>Select Parties</h5>
      <Table
        dataSource={parties}
        columns={columns}
        rowKey="_id"
        pagination={{ pageSize: 10 }}
      />
      <div style={{ marginTop: "20px", textAlign: "right" }}>
        <Button type="primary" onClick={handleConfirmSelection}>
          Add Selected
        </Button>
        <Button onClick={onClose} style={{ marginLeft: "10px" }}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default SelectPartiesModal;

