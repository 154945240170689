import axios from 'axios';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import styled from 'styled-components';
import { useAuth } from '../../../context/auth';
import Button from '../../common/Button';
import Modal from '../../common/Modal';
import BrokerContactForm from './ContactForm';
import BrokerContactTable from './ContactTable';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const HeaderContainer = styled.div`
  height: 5vh;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const BrokerContacts = ({ broker }) => {
  const [auth] = useAuth();
  const [loading, setLoading] = useState(true);
  const [brokerContacts, setBrokerContacts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBrokerContact, setSelectedBrokerContact] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    role: '',
    phone: '',
  });

  useEffect(() => {
    const fetchBrokerContacts = async () => {
      try {
        const { data } = await axios.get(`/api/v1/broker/brokerContacts/${broker._id}`,{
          headers: {
            'Authorization': `Bearer ${auth?.token}`,
          }
        });
        if (data.success) {
          setBrokerContacts(data.brokerContacts);
        } else {
          toast.error('Failed to fetch brokerContacts');
        }
      } catch (error) {
        console.error('Error fetching brokerContacts:', error);
        toast.error('Something went wrong while fetching brokerContacts');
      } finally {
        setLoading(false);
      }
    };
    fetchBrokerContacts();
  }, [broker._id]);

  const handleAddBrokerContact = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(`/api/v1/brokerContacts/${broker._id}`, {formData}, {
        headers: {
          'Authorization': `Bearer ${auth?.token}`,
        }
      });

      if (data.success) {
        setBrokerContacts([data.brokerContact, ...brokerContacts]);
        setFormData({ name: '', email: '', role: '', phone: '' });
        toast.success('BrokerContact added successfully');
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error('Error adding brokerContact:', error);
      toast.error('Something went wrong while adding the brokerContact');
    }
  };

  const handleUpdateBrokerContact = async (e) => {
    e.preventDefault();
    try {
      const {data} = await axios.put(`/api/v1/brokerContacts/${selectedBrokerContact._id}`, {formData}, {
        headers: {
          'Authorization': `Bearer ${auth?.token}`,
        }
      });
      if (data.success) {
        const updatedBrokerContacts = brokerContacts.map(brokerContact =>
          brokerContact._id === selectedBrokerContact._id ? data.brokerContact : brokerContact
        );
        setBrokerContacts(updatedBrokerContacts);
        setSelectedBrokerContact(null);
        setIsModalOpen(false);
        setFormData({ name: '', email: '', role: '', phone: '' });
        toast.success('BrokerContact updated successfully');
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error('Error updating brokerContact:', error);
      toast.error('Something went wrong while updating the brokerContact');
    }
  };

  const handleDeleteBrokerContact = async (id) => {
    try {
      const { data } = await axios.delete(`/api/v1/brokerContacts/${id}`, {
        headers: {
          'Authorization': `Bearer ${auth?.token}`,
        }
      });
      if (data.success) {
        setBrokerContacts(brokerContacts.filter(brokerContact => brokerContact._id !== id));
        toast.success('BrokerContact deleted successfully');
      } else {
        toast.error('Failed to delete brokerContact');
      }
    } catch (error) {
      console.error('Error deleting brokerContact:', error);
      toast.error('Something went wrong while deleting the brokerContact');
    }
  };

  const handleEditBrokerContact = (brokerContact) => {
    setSelectedBrokerContact(brokerContact);
    setFormData({ name: brokerContact.name, email: brokerContact.email, role: brokerContact.role, phone: brokerContact.phone });
    setIsModalOpen(true);
  };

  return (
    <Container>
      <HeaderContainer>
        <div style={{ marginLeft: 'auto' }}>
          <Button onClick={() => setIsModalOpen(true)}>Add</Button>
        </div>
      </HeaderContainer>
      <BrokerContactTable
        loading={loading}
        brokerContacts={brokerContacts}
        onEdit={handleEditBrokerContact}
        onDelete={handleDeleteBrokerContact}
      />
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <BrokerContactForm
          formData={formData}
          setFormData={setFormData}
          handleSubmit={selectedBrokerContact ? handleUpdateBrokerContact : handleAddBrokerContact}
          title={selectedBrokerContact ? 'Update BrokerContact' : 'Add BrokerContact'}
        />
      </Modal>
    </Container>
  );
};

export default BrokerContacts;
