import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import toast from "react-hot-toast";
import Modal from "../../../components/common/Modal";
import { FaEdit, FaTrashAlt } from "react-icons/fa"; // Importing icons for edit and delete
import { useAuth } from "../../../context/auth";
import {
  Row,
  Col,
} from "reactstrap";
import FillBtn from "../../../shared/Buttons/FillBtn";
import AddAutomationForm from "./addAutomationForm";
import ConfirmationModal from "../../../shared/Confirmation";

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;


const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  flex-grow: 1;
  @media only screen and (max-width: 768px) {
    overflow-x: auto; /* Enable horizontal scrolling */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;


const TableHeader = styled.th`
  padding: 15px;
  position: relative;
  text-align: left;
  background: #e0e4f3;
  color: #444444;
  font-family: arial;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  white-space: nowrap;
  letter-spacing: 0.20000000298023224px;
`;


const TableRow = styled.tr`
  border-bottom: 1px solid #ddd;
`;

const TableCell = styled.td`
  text-align: left;
  padding: 8px;
  padding: 15px;
  white-space: nowrap;
  color: #9fa2b4;
`;

const AllAutomations = ({ claimId }) => {
  useEffect(() => {
    fetchAutomations();
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const [automationId, setAutomationId] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    dueDate: "",
    description: "",
    status: "",
    action: "",
  });
  const [auth] = useAuth();

  const fetchAutomations = async () => {
    try {
      const { data } = await axios.get(
        `/api/v1/getAllAutomationTask?claimId=${claimId}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );

      if (data.success) {
        console.log("automatedData----", data);
        setTasks(data?.automation);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("Something went wrong while getting the automations");
    }
  };

  const handleAddSubmit = async (event, resetForm) => {
    event.preventDefault();
    try {
      const bodyData = {
        dueDate: formData.dueDate,
        description: formData.description,
        status: formData.status,
        action: formData.action,
        claim_id: claimId,
      };

      const { data } = await axios.post(
        `/api/v1/createAutomationTask`,
        bodyData,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );

      if (data.success) {
        resetForm();
        fetchAutomations();
        setIsModalOpen(false);
        toast.success("Automation added successfully!");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("Error adding automation");
    }
  };

  const handleEditSubmit = async (event, resetForm) => {
    event.preventDefault();
    try {
      const bodyData = {
        dueDate: formData.dueDate,
        description: formData.description,
        status: formData.status === "true",  // Convert to boolean here
        action: formData.action,
        claim_id: claimId,
      };
  
      const { data } = await axios.put(
        `/api/v1/updateAutomationTask/${automationId}`,
        bodyData,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
  
      if (data.success) {
        resetForm();
        fetchAutomations();
        setIsModalOpen(false);
        setAutomationId(null);
        toast.success("Automation updated successfully!");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("Error updating automation");
    }
  };
  

  const handleEditClick = (row) => {
    setFormData({
      dueDate: row?.dueDate || "",
      description: row?.description || "",
      status: row?.status === true ? "true" : row?.status === false ? "false" : "",
      action: row?.action || "",
    });
    setAutomationId(row?._id);
    setIsModalOpen(true);
  };
  const openConfirmationModal = (id) => {
    setAutomationId(id); // Set the ID of the automation to be deleted
    setIsConfirmationModalOpen(true); // Open the confirmation modal
  };

  const handleDelete = async () => {
    try {
      const { data } = await axios.delete(
        `/api/v1/deleteAutomationTask?id=${automationId}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );

      if (data.success) {
        fetchAutomations();
        toast.success("Automation deleted!");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("Error deleting automation");
    } finally {
      setIsConfirmationModalOpen(false); // Close the confirmation modal
      setAutomationId(null); // Reset the ID
    }
  };
  return (
    <Container>
      <Row className="align-items-center  my-2">
        <Col md={9}>
        Automations
        </Col>
        <Col md={3}>
        <FillBtn
            className="w-100"
            text="Add Automation"
            handleOnClick={() => setIsModalOpen(true)}
          />
        </Col>
      </Row>
    

      <TableContainer>
        <Table>
          <thead>
            <TableRow>
              <TableHeader>Due Date</TableHeader>
              <TableHeader>Description</TableHeader>
              <TableHeader>Repeat Task</TableHeader>
              <TableHeader>Action</TableHeader>
              <TableHeader>Edit</TableHeader>
              <TableHeader>Delete</TableHeader>
            </TableRow>
          </thead>
          <tbody>
            {tasks?.map((row) => (
              <TableRow key={row?._id}>
                <TableCell>{row?.dueDate ?? row?.createdAt}</TableCell>
                <TableCell>{row?.description}</TableCell>
                <TableCell>{row.status === true ? "True" : "False"}</TableCell>
                <TableCell>{row?.action}</TableCell>
                <TableCell>
                  <FaEdit 
                  size={20} 
                  onClick={() => handleEditClick(row)} 
                  style={{ cursor: "pointer" }}
                  />
                </TableCell>
                <TableCell>
                  {" "}
                  <FaTrashAlt
                    size={20}
                    style={{ cursor: "pointer" }}
                    onClick={() => openConfirmationModal(row._id)} 
                  />
                </TableCell>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </TableContainer>

      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <AddAutomationForm
          formData={formData}
          setFormData={setFormData}
          handleSubmit={automationId ? handleEditSubmit : handleAddSubmit}
          title={automationId ? "Edit Automation" : "Add Automation"}
        />
      </Modal>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        toggle={() => setIsConfirmationModalOpen(!isConfirmationModalOpen)} // Toggle the confirmation modal
        title="Confirm Action"
        body="Are you sure you want to delete this automation?"
        onConfirm={handleDelete} // Call the delete function on confirm
      />
    </Container>
  );
};

export default AllAutomations;
