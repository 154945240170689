import React, { useEffect, useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 4px;
  justify-content: space-between;
  background-color: #fff;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  border-radius: 4px;
  justify-content: space-between;
  background-color: #fff;
`;

const Label = styled.div``;

const Btn = styled.button`
  background-color: #6495ed;
  color: white;
  border: none;
  margin-right: 10px; /* Add margin to create space */
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #0069d9;
  }
`;

const CncBtn = styled.button`
  background-color: #28a745;
  color: white;
  border: none;
  margin-left: 10px; /* Add margin to create space */
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #218838;
  }
  &:disabled {
    background-color: #c3e6cb;
    cursor: not-allowed;
  }
`;

const AddReserveForm = ({ reserve, onSave, onClose }) => {
  const [formData, setFormData] = useState({
    lineOfReserve: "",
    limits: "",
    recommendedReserve: "",
  });

  useEffect(() => {
    if (reserve) {
      setFormData({
        lineOfReserve: reserve.lineOfReserve || "",
        limits: reserve.limits ?? null, // Ensure limits defaults to null
        recommendedReserve: reserve.recommendedReserve || "",
      });
    } else {
      setFormData({
        lineOfReserve: "",
        limits: null,
        recommendedReserve: "",
      });
    }
  }, [reserve]);
  
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: name === "limits" || name === "recommendedReserve"
        ? value === "" ? null : Number(value)
        : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.lineOfReserve || formData.recommendedReserve === null) {
      alert("Please fill all required fields.");
      return;
    }

    const updatedData = { 
      ...formData, 
      id: reserve?.id || new Date().getTime(), // Maintain ID for existing items
    };

    onSave(updatedData);
    onClose();
  };

  const handleCancel = () => {
    setFormData({
      lineOfReserve: "",
      limits: null,
      recommendedReserve: "",
    });
    onClose();
  };
  
  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Label>Line of Reserve:</Label>
        <input
          type="text"
          name="lineOfReserve"
          value={formData.lineOfReserve}
          onChange={handleChange}
          required
        />

        <Label>Limits:</Label>
        <input
          type="number"
          name="limits"
          value={formData.limits}
          onChange={handleChange}
        />

        <Label>Recommended Reserve:</Label>
        <input
          type="number"
          name="recommendedReserve"
          value={formData.recommendedReserve}
          onChange={handleChange}
          required
        />

        <ButtonContainer>
          <div>
            <Btn type="submit">Save</Btn>
          </div>
          <div>
            <CncBtn type="button" onClick={handleCancel}>
              Cancel
            </CncBtn>
          </div>
        </ButtonContainer>
      </Container>
    </form>
  );
};

export default AddReserveForm;
