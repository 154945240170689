import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useAuth } from "../../context/auth";

const PageContainer = styled.div`
  display: flex; 
  justify-content: center; 
  align-items: center; 
  height: 100vh; 
  background-color: #f1f1f1; 
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 500px;
  background-color: white;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); 
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  input {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
`;

const Btn = styled.button`
  padding: 10px 20px;
  background-color: #040848;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [auth, setAuth] = useAuth();

  const navigate = useNavigate();
  const location = useLocation();

  const loginUrl = `/api/v1/auth/login`;

  // Redirect if already logged in
  useEffect(() => {
    if (auth?.token) {
      navigate("/dashboard/user");
    }
  }, [auth, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(loginUrl, { email, password });
  
      if (res.data.success) {
        toast.success(res.data.message);
        setAuth({
          ...auth,
          user: res.data.user,
          token: res.data.token,
        });
        localStorage.setItem("auth", JSON.stringify(res.data));
        navigate(location.state || "/dashboard/user");
      }
    } catch (error) {
      console.error("Login error:", error);
  
      // Check if error response exists
      if (error.response) {
        console.log("Error response received:", error.response);
  
        // Extract error message safely
        const errorMessage = error.response.data?.message || "Invalid email or password.";
        toast.error(errorMessage);
      } else if (error.request) {
        console.log("No response received:", error.request);
        toast.error("No response from server. Please check your connection.");
      } else {
        console.log("Unexpected error:", error.message);
        toast.error("Something went wrong. Please try again.");
      }
    }
  };
  
  return (
    <PageContainer>
      <Container>
        <Form onSubmit={handleSubmit}>
          <h1>Login Form</h1>
          <Input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            id="exampleInputEmail1"
            placeholder="Enter Your Work Email"
            required
          />
          <Input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            id="exampleInputPassword1"
            placeholder="Enter Your Password"
            required
          />
          <a href="/forgot-password">Forgot password?</a>
          <Btn type="submit">Login</Btn>
        </Form>
      </Container>
    </PageContainer>
  );
};

export default Login;
