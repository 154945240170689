import {
  Breadcrumb,
  Button,
  Input,
  Modal,
  notification,
  Select,
  Spin,
  Table,
  Upload,
} from "antd";
import axios from "axios";
import imageCompression from "browser-image-compression";
import { saveAs } from "file-saver"; // Install via npm: npm install file-saver
import moment from "moment";
import React, { useEffect, useState } from "react";
import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css"; // Lightbox styles
import { FaEllipsisV } from "react-icons/fa";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import styled from "styled-components";
import Layout from "../../components/Layout/Layout";
import { useAuth } from "../../context/auth";

const Img = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
  cursor: pointer;
`;
const { Option } = Select;

const Container = styled.div`
  padding: 3px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Folders = styled.div`
  display: flex;
  flex-direction: row;
  padding: 3px;
  gap: 10px; /* Added gap between folders and actions dropdown */
`;

const Documents = ({ onCancel, visible}) => {
  const [filterText, setFilterText] = useState("");
  const [auth] = useAuth();
  const [documents, setDocuments] = useState([]);
  const [folders, setFolders] = useState([]);
  const [breadcrumb, setBreadcrumb] = useState(["Home"]);
  const [newFolderName, setNewFolderName] = useState("");
  const [loading, setLoading] = useState(false);
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [menuDropdownOpen, setMenuDropdownOpen] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [renameModalVisible, setRenameModalVisible] = useState(false);
  const [documentToRename, setDocumentToRename] = useState(null);
  const [newDocumentName, setNewDocumentName] = useState("");
  const [duplicateModalVisible, setDuplicateModalVisible] = useState(false);
  const [moveModalVisible, setMoveModalVisible] = useState(false);
  const [targetFolder, setTargetFolder] = useState("");
  const [documentToDuplicate, setDocumentToDuplicate] = useState(null);
  const [duplicateDocumentName, setDuplicateDocumentName] = useState("");
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [message, setMessage] = useState("");
  
  const [createFolderModalVisible, setCreateFolderModalVisible] =
    useState(false);

  const toggleDropdown = (id) => {
    setOpenDropdownId((prevId) => (prevId === id ? null : id));
  };

  const toggleMenuDropdown = () => {
    setMenuDropdownOpen((prevState) => !prevState);
  };
  const [templateModalVisible, setTemplateModalVisible] = useState(false);

  // Function to toggle the TemplateExportModal
  const toggleTemplateModal = () =>
    setTemplateModalVisible(!templateModalVisible);

  const currentFolder =
    breadcrumb.length > 1 ? breadcrumb.slice(1).join("/") : null; // Use null for root

  useEffect(() => {
    if (breadcrumb.length > 0 || currentFolder) {
      console.log("Breadcrumb updated:", breadcrumb);
      console.log("Current folder:", currentFolder || "Root");
    }
  }, [breadcrumb, currentFolder]);

  const hasAccessToModify =
  auth?.user?.role_id?.name?.toLowerCase() === "manager" ||
  auth?.user?.role_id?.name?.toLowerCase() === "office admin" ||
  auth?.user?.role_id?.name?.toLowerCase() === "supervisor";
  const fetchContents = async () => {
    setLoading(true);
    try {
      const folderPath =
        breadcrumb.length > 1
          ? breadcrumb.slice(1).map(encodeURIComponent).join("/")
          : "";

      console.log(
        folderPath
      );

      const { data } = await axios.get(
        `/api/v1/resources/documents`,
        {
          params: { folder: folderPath },
          headers: { Authorization: `Bearer ${auth?.token}` },
        }
      );
      const normalizedDocuments = data.documents.map((doc) => {
        let cleanUrl = `https://hgmadjusters.sharepoint.com/sites/HGMDocuments/Shared%20Documents/resources/${folderPath}/${doc.name}`;
        if (cleanUrl) {
          cleanUrl = cleanUrl.replace(/([^:])\/\//g, "$1/"); // Normalize slashes
        }
        const isFolder = !doc.file; // Assuming `file` is undefined for folders
        const mimeType = isFolder ? "folder" : inferMimeType(doc.name);
        return {
          key: doc.id,
          sharePointId: doc.id,
          name: doc.name,
          url: cleanUrl,
          size: doc.file ? doc.size : null,
          type: mimeType, // Distinguish between files and folders
          createdDateTime: doc.fileSystemInfo?.createdDateTime,
          lastModifiedDateTime: doc.fileSystemInfo?.lastModifiedDateTime,
        };
      });

      // Separate folders and files
      const folders = normalizedDocuments.filter(
        (doc) => doc.type === "folder"
      );
      const files = normalizedDocuments.filter((doc) => doc.type !== "folder");

      console.log("Folders:", folders, "Files:", files);

      setFolders(folders);
      setDocuments(files);
    } catch (error) {
      console.error("Error fetching folder contents:", error.message);
      notification.error({ description: `Folder Empty or Unable to Fetch` });
    } finally {
      setLoading(false);
    }
  };
  const inferMimeType = (fileName) => {
    const extension = fileName.split(".").pop().toLowerCase();
    switch (extension) {
      case "jpg":
      case "jpeg":
        return "image/jpeg";
      case "png":
        return "image/png";
      case "gif":
        return "image/gif";
      default:
        return "application/octet-stream"; // Default MIME type
    }
  };
  const handleOpenMoveModal = () => {
    if (selectedDocuments.length === 0) {
      notification.warning({
        message: "No Documents Selected",
        description: "Please select at least one document to move.",
      });
      return;
    }
    setMoveModalVisible(true);
  };
  
  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setIsLightboxOpen(true);
  };

  const handleNextImage = () => {
    setCurrentImageIndex((currentImageIndex + 1) % imageDocuments.length);
  };

  const handlePreviousImage = () => {
    setCurrentImageIndex(
      (currentImageIndex - 1 + imageDocuments.length) % imageDocuments.length
    );
  };

  // Filter only image files
  const imageDocuments = documents.filter((doc) =>
    ["image/jpeg", "image/png", "image/gif"].includes(doc.type)
  );

  const handleDuplicateItem = (document) => {
    const nameWithoutExtension = document.name.split('.').slice(0, -1).join('.');
    setDocumentToDuplicate(document);
    setDuplicateDocumentName(`${nameWithoutExtension}_copy`);
    setDuplicateModalVisible(true);
  };
  const handleConfirmDuplicate = async () => {
    if (!documentToDuplicate || !duplicateDocumentName.trim()) {
      notification.error({
        message: "Error",
        description: "Invalid document data or new name cannot be empty.",
      });
      return;
    }
  
    // Retrieve the original file extension and append it
    const fileExtension = documentToDuplicate.name.split('.').pop();
    const fullNewName = `${duplicateDocumentName.trim()}.${fileExtension}`;
  
    try {
      await axios.post(
        `/api/v1/resources/documents/duplicate`,
        {
          documentId: documentToDuplicate.key,
          newName: fullNewName,
          folderPath: breadcrumb.length > 1 ? breadcrumb.slice(1).join("/") : null,
        },
        {
          headers: { Authorization: `Bearer ${auth?.token}` },
        }
      );
  
      notification.success({
        message: "Success",
        description: `Document duplicated successfully as "${fullNewName}".`,
      });
  
      fetchContents();
      setDuplicateModalVisible(false);
    } catch (error) {
      console.error("Error duplicating document:", error);
      notification.error({
        message: "Error",
        description: "Failed to duplicate document. Please try again.",
      });
    }
  };
  

  const handleRenameDocument = async () => {
  
    if (!documentToRename || !documentToRename.key) {
      notification.error({
        message: "Error",
        description: "Invalid document data or missing SharePoint ID.",
      });
      return;
    }
  
    if (!newDocumentName || !newDocumentName.trim()) {
      notification.error({
        message: "Error",
        description: "New document name cannot be empty.",
      });
      return;
    }
  
    // Extract the current extension and validate the new name
    const currentExtension = documentToRename.name.split(".").pop();
    const sanitizedNewName = newDocumentName.split(".")[0].trim(); // Exclude any extension entered by the user
  
    if (!sanitizedNewName) {
      notification.error({
        message: "Error",
        description: "Document name cannot be empty or invalid.",
      });
      return;
    }
  
    try {
      // Construct the full new name with the original extension
      const fullNewName = `${sanitizedNewName}.${currentExtension}`;
  
      await axios.put(
        `/api/v1/resources/documents/${documentToRename.key}`, // SharePoint ID
        {
          newName: fullNewName,
          folderPath:
            breadcrumb.length > 1 ? breadcrumb.slice(1).join("/") : null, // Include intermediate folders
        },
        {
          headers: { Authorization: `Bearer ${auth?.token}` },
        }
      );
  
      notification.success({
        message: "Success",
        description: `Document renamed successfully to "${fullNewName}".`,
      });
  
      fetchContents(); // Refresh the document list
      setRenameModalVisible(false); // Close modal
    } catch (error) {
      console.error("Error renaming document:", error);
      notification.error({
        message: "Error",
        description: "Failed to rename document. Please try again.",
      });
    }
  };  

  const handleConvertUpload = async (document) => {
    const folderPath = breadcrumb.length > 1
        ? breadcrumb.slice(1).map(encodeURIComponent).join("/")
        : "";

    if (!document || !document.sharePointId || !document.name) {
        notification.error({ message: "Error", description: "Invalid document data." });
        return;
    }

    setLoading(true);
    setMessage("");

    try {
        // Convert the file name to PDF format (same name, different extension)
        const newFileName = document.name.replace(/\.[^/.]+$/, "");

        console.log("Converting document:", document.sharePointId, "New filename:", newFileName);

        // Handle folder path correctly to avoid double slashes
        const folderPathFormatted = folderPath ? `/${folderPath}` : "";

        // Axios request with proper string interpolation and headers
        const response = await axios.post(
            `/api/v1/resources/documents${folderPathFormatted}/convert-upload`, 
            {
                documentId: document.sharePointId,
                newFileName
            },
            {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                    "Content-Type": "application/json",
                },
            }
        );

        if (response.data.success) {
            notification.success({ 
                message: "Success", 
                description: "Document converted and uploaded successfully!" 
            });
            fetchContents(); // Refresh document list after upload
        } else {
            notification.error({ message: "Error", description: "Failed to convert the document." });
        }
    } catch (error) {
        console.error("Error processing document:", error);
        
        notification.error({ 
            message: "Error", 
            description: error.response?.data?.message || "Failed to convert document." 
        });
    } finally {
        setLoading(false);
    }
};

  const handleUpload = async ({ fileList, onProgress, onSuccess, onError }) => {
    const folder = currentFolder || ""; // Use the current folder path
    const maxRetries = 3;
    const maxConcurrentUploads = 5; // Limit the number of parallel uploads

    // Function to handle a single file upload
    const uploadFile = async (originalFile) => {
      let fileToUpload = originalFile;

      try {
        // Compress image if it's larger than 4MB
        if (
          originalFile.type.startsWith("image/") &&
          originalFile.size > 4 * 1024 * 1024
        ) {
          const options = {
            maxSizeMB: 4,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
          };

          const compressedFile = await imageCompression(originalFile, options);
          fileToUpload = new File([compressedFile], originalFile.name, {
            type: originalFile.type,
          });
        }

        // Prepare the FormData for this file
        const formData = new FormData();
        formData.append("files", fileToUpload);

        // Attempt to upload the file
        for (let attempt = 1; attempt <= maxRetries; attempt++) {
          try {
            await axios.post(
              `/api/v1/resources/documents/upload`,
              formData,
              {
                params: { folder },
                headers: {
                  Authorization: `Bearer ${auth?.token}`,
                  "Content-Type": "multipart/form-data",
                },
                onUploadProgress: (event) => {
                  const percentCompleted = Math.round(
                    (event.loaded * 100) / event.total
                  );
                  if (onProgress) {
                    onProgress({
                      percent: Math.min(percentCompleted, 100),
                      fileName: fileToUpload.name,
                    });
                  }
                },
              }
            );

            // File upload successful
            onSuccess(`File ${fileToUpload.name} uploaded successfully.`);
            return; // Exit retry loop
          } catch (uploadError) {
            if (attempt === maxRetries) {
              console.error(
                `Error uploading file ${fileToUpload.name}:`,
                uploadError
              );
              notification.error({
                message: "Upload Error",
                description: `Failed to upload file: ${fileToUpload.name} after multiple attempts.`,
              });
              throw uploadError; // Propagate error for this file
            }
          }
        }
      } catch (error) {
        console.error(`Error processing file ${originalFile.name}:`, error);
        notification.error({
          message: "Error",
          description: `An error occurred while processing file: ${originalFile.name}.`,
        });
        throw error; // Propagate error for this file
      }
    };

    // Concurrency control using a pool
    const pool = [];
    const results = [];

    for (const file of fileList) {
      const uploadTask = uploadFile(file)
        .then((result) => results.push(result))
        .catch((error) => results.push(error));

      pool.push(uploadTask);

      if (pool.length >= maxConcurrentUploads) {
        await Promise.race(pool); // Wait for one upload to finish before adding more
        pool.splice(
          pool.findIndex((p) => p === uploadTask),
          1
        );
      }
    }

    await Promise.all(pool); // Wait for all remaining uploads

    fetchContents(); // Refresh folder contents after all uploads
    notification.success({
      message: "Upload Complete",
      description: "All files uploaded successfully.",
    });
  };

  const handleDeleteDocument = async (document) => {
    if (!document || !document.key) {
      notification.error({
        message: "Error",
        description: "Invalid document data.",
      });
      return;
    }

    Modal.confirm({
      title: `Are you sure you want to delete "${document.name}"?`,
      onOk: async () => {
        try {
          await axios.delete(
            `/api/v1/resources/documents/${document.key}`, // Use SharePoint ID
            {
              headers: { Authorization: `Bearer ${auth?.token}` },
            }
          );
          notification.success({
            message: "Success",
            description: `Document "${document.name}" deleted successfully.`,
          });
          fetchContents(); // Refresh document list
        } catch (error) {
          console.error("Error deleting document:", error);
          notification.error({
            message: "Error",
            description: "Failed to delete document.",
          });
        }
      },
    });
  };

  const handleDownload = async () => {
    if (selectedDocuments.length === 0) {
      notification.warning({
        message: "No Documents Selected",
        description:
          "Please select at least one document to download and email.",
      });
      return;
    }


    try {
      // Request to download selected documents
      const { data } = await axios.post(
        `/api/v1/resources/documents/download`,
        { documentIds: selectedDocuments },
        {
          headers: { Authorization: `Bearer ${auth?.token}` },
        }
      );

      const files = data.files;

      // Save each file individually
      files.forEach((file) => {
        const blob = new Blob(
          [Uint8Array.from(atob(file.content), (c) => c.charCodeAt(0))],
          {
            type: file.contentType,
          }
        );
        saveAs(blob, file.name);
      });

      notification.success({
        message: "Download Complete",
        description: "The selected documents were downloaded successfully.",
      });
    } catch (error) {
      console.error("Error downloading documents:", error);
      notification.error({
        message: "Error",
        description:
          "Failed to download documents. Please try again or contact support.",
      });
    }
  };
  

  const handleCreateFolder = async () => {
    if (!newFolderName.trim()) {
      notification.error({
        message: "Error",
        description: "Folder name cannot be empty.",
      });
      return;
    }

    try {
      // API call to create a folder
      await axios.post(
        `/api/v1/resources/documents/folder`,
        { folderName: newFolderName.trim() },
        {
          headers: { Authorization: `Bearer ${auth?.token}` },
        }
      );

      notification.success({
        message: "Success",
        description: `Folder "${newFolderName}" created successfully.`,
      });

      setNewFolderName(""); // Reset input field
      handleCloseCreateFolderModal(); // Close the modal
      fetchContents(); // Refresh folder contents
    } catch (error) {
      console.error(
        "Error creating folder:",
        error.message || error.response?.data
      );
      notification.error({
        message: "Error",
        description: "Failed to create folder. Please try again.",
      });
    }
  };

  const handleEditDocument = async (document) => {
    if (!document || !document.url) {
      console.error("Invalid document data or missing URL.");
      notification.error({
        message: "Error",
        description: "Document data is missing or invalid.",
      });
      return;
    }
  
    try {
      let documentUrl = document.url;
  
      // Clean up the URL to ensure it is fully valid
      documentUrl = documentUrl
        .replace(/([^:])\/\//g, "$1/") // Remove double slashes
        .replace(/ /g, "%20"); // Encode spaces
  
      console.log("Final Cleaned Document URL:", documentUrl);
  
      const fileExtension = document.name.split(".").pop().toLowerCase();
      let protocol = "";
  
      if (document.url) {
        // Open the document using its URL as a fallback
        protocol = documentUrl;
      } else {
        // Unsupported file type
        notification.error({
          message: "Error",
          description: "Unsupported file type for editing.",
        });
        return;
      }
  
      console.log("Opening document with protocol:", protocol);
  
      // Open the document using the resolved protocol in a new tab for Explorer-like protocols
      if (protocol.startsWith("iexplore:") || protocol.startsWith("http") || protocol.startsWith("https")) {
        window.open(protocol, "_blank");
      } else {
        window.location.href = protocol;
      }
  
      // Refresh the document list
      fetchContents();
    } catch (error) {
      console.error("Error editing document:", error);
      notification.error({
        message: "Error",
        description: "Failed to edit document.",
      });
    }
  };
  

  const handleOpenCreateFolderModal = () => {
    setCreateFolderModalVisible(true);
  };

  const handleCloseCreateFolderModal = () => {
    setCreateFolderModalVisible(false);
    setNewFolderName(""); // Clear the folder name when modal is closed
  };
  const handleCloseModal = () => {
    setSelectedDocuments([]);
    setTargetFolder("");
    onCancel();
};

  const handleOpenRenameModal = (document) => {
    const nameWithoutExtension = document.name.split(".").slice(0, -1).join(".");
    const fileExtension = document.name.split(".").pop();
  
    setDocumentToRename({ ...document, extension: fileExtension });
    setNewDocumentName(nameWithoutExtension);
    setRenameModalVisible(true);
  };
  

  const handleCancelRename = () => {
    setRenameModalVisible(false);
    setDocumentToRename(null);
    setNewDocumentName("");
  };

  const handleNavigateFolder = (folderName) => {
    setBreadcrumb((prev) => [...prev, folderName]);
  };

  const handleNavigateBreadcrumb = (index) => {
    setBreadcrumb(breadcrumb.slice(0, index + 1));
  };

  const handleSelectDocument = (documentKey, isChecked) => {
    setSelectedDocuments((prevSelected) => {
      if (isChecked) {
        // Add document to the selected list if not already included
        return [...prevSelected, documentKey];
      } else {
        // Remove document from the selected list
        return prevSelected.filter((key) => key !== documentKey);
      }
    });
  };

  const handleSelectAll = (isChecked) => {
    if (isChecked) {
      // Select all document keys
      const allKeys = documents.map((doc) => doc.key); // Use `key` instead of `_id`
      setSelectedDocuments(allKeys);
    } else {
      // Deselect all
      setSelectedDocuments([]);
    }
  };
 
  const handleMove = async () => {
    if (!Array.isArray(selectedDocuments) || selectedDocuments.length === 0) {
      notification.warning({
        message: "No Documents Selected",
        description: "Please select at least one document to move.",
      });
      return;
    }
  
    if (typeof targetFolder !== "string") {
      notification.warning({
        message: "Target Folder Required",
        description: "Please select a valid target folder.",
      });
      return;
    }
  
  
    try {
      setLoading(true);
  
      // If moving to the home folder, ensure an empty string is passed as the folder path
      const destinationFolder = targetFolder === ".." ? "" : targetFolder;
  
      const payload = {
        documentIds: selectedDocuments,  // Ensure it's always an array
        targetFolder: destinationFolder.trim(),  // Trim to avoid whitespace issues
      };
  
      console.log("Moving documents with payload:", payload);
  
      const response = await axios.post(
        `/api/v1/resources/documents/move`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      notification.success({
        message: "Success",
        description: `Documents moved successfully to "${destinationFolder || "Home"}".`,
      });
  
      // Refresh contents and reset state
      fetchContents();
      setMoveModalVisible(false);
      setSelectedDocuments([]);
      setTargetFolder("");
    } catch (error) {
      console.error("Error moving documents:", error.response?.data || error.message);
      notification.error({
        message: "Error",
        description: error.response?.data?.message || "Failed to move documents. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };
  
  
  const columns = [
    hasAccessToModify && {
      title: (
        <input
          type="checkbox"
          checked={
            selectedDocuments.length === documents.length &&
            documents.length > 0
          }
          onChange={(e) => handleSelectAll(e.target.checked)}
        />
      ),
      dataIndex: "select",
      key: "select",
      render: (_, record) => (
        <input
          type="checkbox"
          checked={selectedDocuments.includes(record.key)} // Use `key` for comparison
          onChange={(e) => handleSelectDocument(record.key, e.target.checked)} // Pass `key`
        />
      ),
      onCell: () => ({ style: { padding: "5px 8px", textAlign: "center", width: "30px" } }), 
    },      
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => (
        <span 
          style={{ color: "blue", cursor: "pointer", textDecoration: "underline" }}
          onClick={() => handleEditDocument(record)}
        >
          {text}
        </span>
      ),
      onCell: () => ({ style: { padding: "5px 8px" } }),
    },    
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      render: (size) => (size ? `${(size / 1024).toFixed(2)} KB` : "-"),
      onCell: () => ({ style: { padding: "5px 8px", textAlign: "left", width: "120px" } }), // Align text to the right
    },
    {
      title: "Created",
      dataIndex: "createdDateTime",
      key: "createdDateTime",
      sorter: (a, b) => {
        const dateA = new Date(a.createdDateTime);
        const dateB = new Date(b.createdDateTime);
        return dateA - dateB; // Sort in descending order
      },
      defaultSortOrder: "descend", // Set default order to descending
      render: (createdDateTime) =>
        createdDateTime
          ? moment(createdDateTime).format("YYYY-MM-DD HH:mm:ss")
          : "-",
          onCell: () => ({ style: { padding: "5px 8px", textAlign: "left", width: "120px" } }), // Align text to the right
    },
    {
      title: "Modified",
      dataIndex: "lastModifiedDateTime",
      key: "modified",
      sorter: (a, b) => {
        const dateA = new Date(a.lastModifiedDateTime);
        const dateB = new Date(b.lastModifiedDateTime);
        return dateA - dateB; // Sort in descending order
      },
      defaultSortOrder: "descend", // Set default order to descending
      render: (lastModifiedDateTime) =>
        lastModifiedDateTime
          ? moment(lastModifiedDateTime).format("YYYY-MM-DD HH:mm:ss")
          : "-",
          onCell: () => ({ style: { padding: "5px 8px", textAlign: "left", width: "120px" } }), // Align text to the right
    },
    hasAccessToModify && {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Dropdown isOpen={openDropdownId === record.key} toggle={() => toggleDropdown(record.key)}>
          <DropdownToggle className="bg-transparent border">
            <FaEllipsisV color="black" />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => handleOpenRenameModal(record)}>Rename Document</DropdownItem>
            <DropdownItem onClick={() => handleConvertUpload(record)}>Save as PDF</DropdownItem>
            <DropdownItem onClick={() => handleDuplicateItem(record)}>Duplicate Document</DropdownItem>
            <DropdownItem danger onClick={() => handleDeleteDocument(record)}>Delete</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      ),
      onCell: () => ({ style: { padding: "5px 8px", textAlign: "center", width: "75px" } }),
    },
  ].filter(Boolean);

  return (
    <Layout title="Resources - User">
    <Container>
      <h1>Resources</h1>
      <Spin spinning={loading}>
        {/* Breadcrumb Navigation */}
        <Breadcrumb>
          <Breadcrumb.Item>
            <Button type="link" onClick={() => setBreadcrumb(["Home"])}>
              Home
            </Button>
          </Breadcrumb.Item>
          {breadcrumb.slice(1).map((crumb, index) => (
            <Breadcrumb.Item key={index}>
              <Button
                type="link"
                onClick={() => handleNavigateBreadcrumb(index + 1)}
              >
                {crumb}
              </Button>
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
        {hasAccessToModify && (
        <Upload.Dragger
          customRequest={(options) =>
            handleUpload({
              ...options,
              fileList: options.fileList || [options.file], // Ensure all files are passed
            })
          }
          showUploadList={false}
          multiple // Allow multiple files to be selected
          style={{
            width: "100%",
            height: "50px",
            margin: "4px auto",
            padding: "4px",
          }}
        >
          <p className="ant-upload-text">
            Drag & Drop to Upload or click to pick file(s)
          </p>
        </Upload.Dragger>)}
        <Folders>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          
            <Input
              placeholder="Filter by description"
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
              style={{ width: "200px" }}
            />
          </div>
          {folders.length > 0 ? (
            <div>
              
              <div><strong>Folders:</strong>
                {folders.map((folder) => (
                  <Button
                    key={folder._id}
                    onClick={() => handleNavigateFolder(folder.name)}
                  >
                    {folder.name}
                  </Button>
                ))}
              </div>
            </div>
          ) : (
            <br/>
          )} 
          {hasAccessToModify && ( <Dropdown isOpen={menuDropdownOpen} toggle={toggleMenuDropdown}>
              <DropdownToggle color="primary" caret>
                Actions
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  type="primary"
                  onClick={handleDownload}
                >
                  Download
                </DropdownItem>
                <DropdownItem onClick={handleOpenCreateFolderModal}>
                  Create Folder
                </DropdownItem>
                <DropdownItem onClick={handleOpenMoveModal}>Move</DropdownItem>

              </DropdownMenu>
            </Dropdown>)}
        </Folders>
        <Table
          columns={columns}
          dataSource={documents
            ?.filter((doc) =>
              doc?.name?.toLowerCase().includes(filterText?.toLowerCase() || "")
            )
            .map((doc) => ({
              ...doc,
              key: doc.key,
            }))}
          rowKey="key"
        />
        {isLightboxOpen && (
          <Lightbox
            mainSrc={imageDocuments[currentImageIndex]?.url}
            nextSrc={imageDocuments[(currentImageIndex + 1) % imageDocuments.length]?.url}
            prevSrc={
              imageDocuments[
                (currentImageIndex - 1 + imageDocuments.length) % imageDocuments.length
              ]?.url
            }
            onCloseRequest={() => setIsLightboxOpen(false)}
            onMoveNextRequest={handleNextImage}
            onMovePrevRequest={handlePreviousImage}
            imageTitle={imageDocuments[currentImageIndex]?.name}
          />
        )}
      </Spin>
      <Modal
  title="Rename Document"
  visible={renameModalVisible}
  onOk={handleRenameDocument}
  onCancel={handleCancelRename}
  okText="Rename"
  cancelText="Cancel"
>
  <Input
    value={newDocumentName}
    onChange={(e) => setNewDocumentName(e.target.value)}
    placeholder="Enter new document name"
  />
  <p style={{ marginTop: "10px" }}>
    File extension: <strong>.{documentToRename?.extension}</strong>
  </p>
</Modal>
      <Modal
        title="Create New Folder"
        visible={createFolderModalVisible}
        onOk={handleCreateFolder}
        onCancel={handleCloseCreateFolderModal}
        okText="Create"
        cancelText="Cancel"
      >
        <Input
          placeholder="Enter folder name"
          value={newFolderName}
          onChange={(e) => setNewFolderName(e.target.value)}
        />
      </Modal>
      <Modal
  title="Duplicate Document"
  visible={duplicateModalVisible}
  onOk={handleConfirmDuplicate}
  onCancel={() => setDuplicateModalVisible(false)}
  okText="Duplicate"
  cancelText="Cancel"
>
  <Input
    value={duplicateDocumentName}
    onChange={(e) => setDuplicateDocumentName(e.target.value)}
    placeholder="Enter new document name"
  />
  <p style={{ marginTop: "10px" }}>
    File extension: <strong>.{documentToDuplicate?.name.split('.').pop()}</strong>
  </p>
</Modal>
      <Modal
  title="Move Files"
  visible={moveModalVisible}
  onCancel={() => setMoveModalVisible(false)}
  onOk={handleMove}
  okText="Move"
  cancelText="Cancel"
  confirmLoading={loading}
>
  <p>Select the target folder to move the files:</p>
  <Select
    style={{ width: "100%" }}
    placeholder="Select Target Folder"
    value={targetFolder}
    onChange={(value) => setTargetFolder(value)}
  >
    {currentFolder && (
      <Option key="parent" value="..">
        Home
      </Option>
    )}
    {folders.map((folder) => (
      <Option key={folder.sharePointId} value={folder.name}>
        {folder.name}
      </Option>
    ))}
  </Select>
</Modal>
    </Container>
    </Layout>
  );
};

export default Documents;
