import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import deleteIcon from "../../assets/delete.png";
import RoleForm from "../../components/admin/forms/RoleFrom";
import toast from "react-hot-toast";
import Layout from "../../components/Layout/Layout";
import { Modal } from "antd";
import { useAuth } from "../../context/auth";
import { Col, Row } from "reactstrap";
import FillBtn from "../../shared/Buttons/FillBtn";
const Container = styled.div`
  display: flex;
  background: white;
  padding: 20px;
  border-radius: 8px;
  flex-direction: column;
  flex-grow: 1;
`;

const HeaderContainer = styled.div`
  height: 5vh;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Btn = styled.button`
  background-color: #6495ed;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 12px;
  cursor: pointer;
  margin-left: 20px;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  flex-grow: 1;
  @media only screen and (max-width: 768px) {
    overflow-x: auto; /* Enable horizontal scrolling */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  padding: 15px;
  text-align: left;
  background: #e0e4f3;
  color: #444444;
  font-family: arial;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  white-space: nowrap;
  letter-spacing: 0.20000000298023224px;
`;

const TableRow = styled.tr`
  border-bottom: 1px solid #ddd;
`;

const TableCell = styled.td`
  text-align: left;
  padding: 8px;
  padding: 15px;
  white-space: nowrap;
  color: #9fa2b4;
`;

const ActionIcon = styled.span`
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url(${deleteIcon}); // Use the delete icon image
  background-size: cover;
  cursor: pointer;
`;

const Roles = () => {
  const [auth] = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);

  const [formData, setFormData] = useState({
    role: "",
  });

  const handleCreateButtonClick = () => {
    setIsModalOpen(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("formData", formData);
    try {
      const { data } = await axios.post(
        "/api/v1/roles",
        { name: formData.role, role: formData.role },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      if (data.success) {
        const newRole = data.role;
        setRoles((prevRoles) => [...prevRoles, newRole]);
        toast.success("Role created successfully");
        setFormData({ role: "" });
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("something went wrong");
    }
  };

  const handleDelete = async (id) => {
    try {
      const { data } = await axios.delete(`/api/v1/roles/${id}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      if (data.success) {
        setRoles((prevRoles) => prevRoles.filter((role) => role._id !== id));
        toast.success("Role deleted successfully");
      }
    } catch (error) {
      console.log(error);
      toast.error("something went wrong");
    }
  };
  const fetchRoles = async () => {
    try {
      const { data } = await axios.get("/api/v1/roles", {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      if (data.success) {
        setRoles(data.roles);
        setLoading(false);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("something went wrong fetching roles");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);
  return (
    <Layout>
      <Container>
        <Row>
          <Col md={12}>
            <Row className="d-flex align-items-center justify-content-between">
              <Col md={9}>
                <HeaderContainer>
                  <div
                    style={{
                      fontSize: "25px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Roles
                  </div>
                </HeaderContainer>
              </Col>
              <Col md={3} className="text-end">
                <FillBtn
                  handleOnClick={handleCreateButtonClick}
                  className="w-100" // Change to auto to avoid taking full width
                  text="Create"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <TableContainer>
          {loading ? (
            <>Loading...</>
          ) : (
            <>
              <Table>
                <thead>
                  <TableRow>
                    <TableHeader style={{ width: "90%" }}>Role</TableHeader>
                    <TableHeader>Action</TableHeader>
                  </TableRow>
                </thead>
                <tbody>
                  {roles.map((role) => (
                    <TableRow key={role._id}>
                      <TableCell>{role.name}</TableCell>
                      <TableCell align="right">
                        <ActionIcon onClick={() => handleDelete(role._id)} />
                      </TableCell>
                    </TableRow>
                  ))}
                </tbody>
              </Table>
            </>
          )}
        </TableContainer>
        <div>
          <Modal
            title="Add new Role"
            open={isModalOpen}
            onCancel={() => setIsModalOpen(false)}
            footer={null}
          >
            <RoleForm
              handleSubmit={handleSubmit}
              formData={formData}
              setFormData={setFormData}
            />
          </Modal>
        </div>
      </Container>
    </Layout>
  );
};

export default Roles;
