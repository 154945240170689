import React from 'react';

const ClientsIcon = () => {
  return (
    <div>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="6" r="3" fill="white"/>
        <path d="M4 16C4 13 6 11 10 11C14 11 16 13 16 16" stroke="white" strokeWidth="1.5"/>
        <circle cx="4" cy="6.5" r="2" fill="white"/>
        <circle cx="16" cy="6.5" r="2" fill="white"/>
        <path d="M2 16C2 14 3 12 6 12" stroke="white" strokeWidth="1.5"/>
        <path d="M18 16C18 14 17 12 14 12" stroke="white" strokeWidth="1.5"/>
      </svg>
    </div>
  );
};

export default ClientsIcon;
