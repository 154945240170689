import axios from 'axios';
import React, { useState } from 'react';
import styled from "styled-components";
import { useAuth } from "../../context/auth";

const Container = styled.div`
 background: #eeeeee;
`;

const ChangePassword = () => {
    const [auth, setAuth] = useAuth();
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [requirements, setRequirements] = useState({
        length: false,
        uppercase: false,
        special: false,
    });

    const validatePassword = (password) => {
        const length = password.length >= 10;
        const uppercase = /[A-Z]/.test(password);
        const special = /[@$!%*?&]/.test(password);

        setRequirements({ length, uppercase, special });

        return length && uppercase && special;
    };

    const handlePasswordChange = (e) => {
        const password = e.target.value;
        setNewPassword(password);
        validatePassword(password);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('');
        setError('');

        if (newPassword !== confirmNewPassword) {
            setError('New password and confirm password do not match.');
            return;
        }

        if (!validatePassword(newPassword)) {
            setError('Password does not meet the requirements.');
            return;
        }

        try {
            const response = await axios.post('/api/v1/auth/change-password', {
                oldPassword,
                newPassword,
                confirmNewPassword,
            }, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                },
            });

            if (response.data.success) {
                setMessage(response.data.message);
                setOldPassword('');
                setNewPassword('');
                setConfirmNewPassword('');
            } else {
                setError(response.data.message);
            }
        } catch (err) {
            setError('An error occurred. Please try again later.');
            console.error(err);
        }
    };

    return (
        <Container>
        <div className="container">
            <style jsx>{`
                .password-requirements {
                    list-style: none;
                    padding: 0;
                }
                .password-requirements li {
                    font-size: 0.9rem;
                }
                .text-success {
                    color: green;
                }
                .text-danger {
                    color: red;
                }
            `}</style>
            <h2>Change Password</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="oldPassword">Old Password</label>
                    <input
                        type="password"
                        id="oldPassword"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        className="form-control"
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="newPassword">New Password</label>
                    <input
                        type="password"
                        id="newPassword"
                        value={newPassword}
                        onChange={handlePasswordChange}
                        className="form-control"
                        required
                    />
                    <ul className="password-requirements">
                        <li className={requirements.length ? "text-success" : "text-danger"}>
                            At least 10 characters long
                        </li>
                        <li className={requirements.uppercase ? "text-success" : "text-danger"}>
                            At least one uppercase letter
                        </li>
                        <li className={requirements.special ? "text-success" : "text-danger"}>
                            At least one special character (@, $, !, %, *, ?, &)
                        </li>
                    </ul>
                </div>
                <div className="form-group">
                    <label htmlFor="confirmNewPassword">Confirm New Password</label>
                    <input
                        type="password"
                        id="confirmNewPassword"
                        value={confirmNewPassword}
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                        className="form-control"
                        required
                    />
                </div>
                {message && <div className="alert alert-success">{message}</div>}
                {error && <div className="alert alert-danger">{error}</div>}
                <p></p>
                <button type="submit" className="btn btn-primary">Change Password</button>
            </form>
        </div>
        </Container>
    );
};

export default ChangePassword;
