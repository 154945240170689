// Email Client Component for Claim Management System

import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaPaperclip, FaPaperPlane } from "react-icons/fa"; // Import send icon
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the default snow theme
import styled from "styled-components";
import { useAuth } from "../../context/auth";
import SelectDocumentsModal from "./SelectDocumentsModal"; // Modal Component
import SelectPartiesModal from "./SelectPartiesModal";
import EmailSignature from "./Signature";

const SendBtn = styled.button`
  padding: 8px 12px;
  background-color: white;
  color: black;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 60px;
  justify-content: center;
  gap: 6px;
  white-space: nowrap;

  :hover {
    background-color: #f0f0f0;
  }

  :disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const Btn = styled.button`
  padding: 4px 4px;
  background-color: white;
  color: black;
  height: 60px;
  width: 85px;
  border: 1px solid #ccc;
  min-width: 50px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 4px;
  align-self: flex-end;
  :hover {
    background-color: white;
  }
    :disabled {
      background-color: #cccccc;
      cursor: not-allowed;
    }
`;
const RemoveBtn = styled.button`
  width: 24px; 
  height: 24px; 
  background-color: red;
  color: white;
  border: none;
  font-weight: bold;
  font-size: 18px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  line-height: 1;

  :hover {
    background-color: darkred;
  }

  :disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const Container = styled.div`
  width: 100%;
`;

const SendContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  flex-direction: row;
`;

const SendColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const FieldsColumn = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledInput = styled.input`
  flex-grow: 1;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 35px;
  padding: 5px;
  box-sizing: border-box;
  width: 100%;
`;

const ToBtn = styled.button`
  background-color: white;
  color: black;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  min-width: 50px;
  white-space: nowrap;
  height: 35px;
  :hover {
    background-color: #f0f0f0;
  }
`;

const EmailClient = ({ claimId, userEmail, userName, onClose, fileNumber, claimData }) => {
  const [auth] = useAuth();
  const [to, setTo] = useState("");
  const [cc, setCc] = useState("");
  const [bcc, setBcc] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [parties, setParties] = useState([]);
  const [appDocuments, setAppDocuments] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);
  const [isPartyModalVisible, setPartyModalVisible] = useState(false);
  const [selectedPartyEmails, setSelectedPartyEmails] = useState([]);
  const [users, setUsers] = useState([]);
  const handleClosePartyModal = () => setPartyModalVisible(false);

  useEffect(() => {
    if (auth?.user) {
      setBody(EmailSignature(auth.user));
    }
  }, [auth]);

  useEffect(() => {
    if (claimId) {
      setSubject(
        "Insured: " +
        claimData?.policyDetails?.name + " | " +
        " Claim Number: " +
        claimData?.clients[0]?.claimNumber + " | " +
        " Our File No: " +
        fileNumber
      );
    }
  }, [auth]);

  const [emailFieldType, setEmailFieldType] = useState(null);

  const handleOpenPartyModal = (fieldType) => {
    setEmailFieldType(fieldType);
    setSelectedPartyEmails([]); // Clear previously selected emails
    setPartyModalVisible(true);
  };

  const handleOpenModal = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleSelectDocuments = async (documents) => {
    try {
      const documentIds = documents.map((doc) => doc.sharePointId);
      console.log("Selected Document IDs:", documentIds);

      const response = await fetch(`/api/v1/download-email-documents`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth?.token}`,
        },
        body: JSON.stringify({ documentIds }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch documents from the server.");
      }

      const { success, attachments, message } = await response.json();

      if (!success) {
        throw new Error(message || "Error fetching attachments.");
      }

      setSelectedDocuments((prev) => [
        ...prev,
        ...documents.map((doc) => ({
          key: doc.key || doc.id,
          name: doc.name,
          type: doc.type,
        })),
      ]);

      // Ensure attachments contain base64 data
      setAttachments((prev) => [
        ...prev,
        ...attachments.map((attachment) => ({
          name: attachment.name,
          contentType: attachment.contentType || "application/octet-stream",
          contentBytes: attachment.contentBytes,  // Ensure base64 content is sent
        }))
      ]);

      console.log("Updated Attachments:", attachments);
      setModalVisible(false);
    } catch (error) {
      console.error("Error processing documents:", error.message);
      toast.error("Failed to process selected documents. Please try again.");
    }
  };


  const removeAttachment = (index) => {
    setSelectedDocuments((prev) => prev.filter((_, i) => i !== index));
    setAttachments((prev) => prev.filter((_, i) => i !== index));
  };
  const handlePartySelect = (email, isSelected) => {
    setSelectedPartyEmails((prev) =>
      isSelected ? [...prev, email] : prev.filter((e) => e !== email)
    );
  };
  const addSelectedPartiesToToField = () => {
    setTo((prev) =>
      [...new Set([...prev.split(", "), ...selectedPartyEmails])].join(", ")
    );
    handleClosePartyModal();
  };

  const handleSend = async () => {
    if (!to.trim() || !subject.trim() || !body.trim()) {
      toast.error("To, Subject, and Body are required.");
      return;
    }

    const recipientEmails = to.split(",").map(email => email.trim());
    const ccEmails = cc ? cc.split(",").map(email => email.trim()) : [];
    const bccEmails = bcc ? bcc.split(",").map(email => email.trim()) : [];

    const validateEmailFormat = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    if (
      recipientEmails.some(email => !validateEmailFormat(email)) ||
      ccEmails.some(email => !validateEmailFormat(email)) ||
      bccEmails.some(email => !validateEmailFormat(email))
    ) {
      toast.error("One or more email addresses are invalid.");
      return;
    }

    setLoading(true);

    try {
      const formattedAttachments = attachments.map((file) => ({
        "@odata.type": "#microsoft.graph.fileAttachment",
        name: file.name || "unknown",
        contentType: file.contentType || "application/octet-stream",
        contentBytes: file.contentBytes || btoa(file.content),
      }));

      const emailData = {
        userEmail: auth?.user?.work_email,
        subject,
        body, // ReactQuill already provides HTML content
        recipients: recipientEmails,
        cc: ccEmails.length ? ccEmails : [],
        attachments: formattedAttachments.length ? formattedAttachments : [],
      };

      console.log("Sending email with data:", JSON.stringify(emailData, null, 2));

      // Make API request to send email
      const response = await axios.post("/api/v1/send-email", emailData, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      });

      if (response.data.success) {
        toast.success("Email sent successfully!");
        resetEmailClient(); // Reset form
      } else {
        toast.error(response.data.message || "Failed to send email.");
      }
    } catch (error) {
      console.error("Error sending email:", error.response?.data || error.message);
      toast.error("An error occurred while sending the email.");
    } finally {
      setLoading(false);
    }
  };

  const resetEmailClient = () => {
    setTo("");
    setCc("");
    setSubject(
      "Insured: " +
      claimData?.policyDetails?.name + " | " +
      " Claim Number: " +
      claimData?.clients[0]?.claimNumber + " | " +
      " Our File No: " +
      fileNumber
    );
    setBody(EmailSignature(auth.user));
    setAttachments([]);
    setSelectedDocuments([]);
  };

  return (
    <Container>
      <div className="email-client">
        <h5>Send Email</h5>
        <SendContainer>
          <SendColumn>
            <SendBtn onClick={handleSend} disabled={loading}>
              {loading ? "Sending..." : <>Send <FaPaperPlane /></>}
            </SendBtn>
            <Btn onClick={handleOpenModal}>
              Attach
              <FaPaperclip />
            </Btn>
          </SendColumn>
          <FieldsColumn>
            <div style={{ display: "flex", alignItems: "center", gap: "12px", autoComplete: "off" }}>
              <ToBtn onClick={() => handleOpenPartyModal("to")}>To</ToBtn>
              <StyledInput
                type="text"
                value={to}
                onChange={(e) => setTo(e.target.value)}
                placeholder="Enter recipient email(s)"
              />
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "12px", autoComplete: "off" }}>
              <ToBtn onClick={() => handleOpenPartyModal("cc")}>Cc</ToBtn>
              <StyledInput
                type="text"
                value={cc}
                onChange={(e) => setCc(e.target.value)}
                placeholder="Enter CC email(s)"
              />
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
  <label>
    <span style={{ fontWeight: "bold" }}>Subject</span>
  </label>
  <StyledInput
    type="text"
    value={subject}
    onChange={(e) => setSubject(e.target.value)}
    placeholder="Enter subject"
    autoComplete="off"
  />
</div>
          </FieldsColumn>
        </SendContainer>
        <div>
          <ReactQuill
            value={body}
            onChange={setBody}
            theme="snow"
            placeholder="Type your email content here..."
            style={{
              minHeight: "300px",
              maxHeight: "300px",
              marginTop: "8px",
              overflowY: "auto",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
          />
        </div>
        <div>
          <SelectDocumentsModal
            visible={isModalVisible} // Ensure modal visibility is managed
            onClose={handleCloseModal} // Close handler
            claimId={claimId} // Pass claimId
            onSelect={handleSelectDocuments} // Handle selected documents
            claimData={{ fileNumber }} // Pass fileNumber if needed for folder paths
          />
          <div>
            <h6>Selected Documents:</h6>
            <ul>
              {selectedDocuments.length > 0 ? (
                selectedDocuments.map((doc, index) => (
                  <li key={doc.key} style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    <span>{doc.name}</span>
                    <RemoveBtn onClick={() => removeAttachment(index)}>−</RemoveBtn>
                  </li>
                ))
              ) : (
                <li>No documents selected.</li>
              )}
            </ul>

          </div>
          <SelectPartiesModal
            visible={isPartyModalVisible}
            onClose={() => {
              setPartyModalVisible(false);
              setSelectedPartyEmails([]); // Reset selected parties on close
              setEmailFieldType(null); // Clear the field type
            }}
            claimId={claimId}
            width={1000}
            onSelect={(selectedParties) => {
              const emails = selectedParties
                .flatMap((party) =>
                  Array.isArray(party.email) ? party.email.map((e) => e.address) : [party.email]
                )
                .filter(Boolean)  // Remove null/undefined emails
                .map(email => email.trim())  // Trim whitespace
                .filter((email, index, self) => self.indexOf(email) === index);  // Remove duplicates

              if (emailFieldType === "to") {
                setTo(emails.join(", "));  // Replace 'To' field with new values
              } else if (emailFieldType === "cc") {
                setCc(emails.join(", "));  // Replace 'CC' field with new values
              }

              setPartyModalVisible(false);
              setSelectedPartyEmails([]);  // Reset after selection
              setEmailFieldType(null);  // Clear the field type
            }}
          />

        </div>
      </div>
    </Container>
  );
};

export default EmailClient;
