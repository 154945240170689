import React, { useEffect, useState } from "react";
import styled from "styled-components";
import sorter from "../../assets/sorter.png";
import Layout from "../../components/Layout/Layout";
import axios from "axios";
import { useAuth } from "../../context/auth";
import moment from "moment";
import { Col, Row } from "reactstrap";

const MainContainer = styled.div`
  display: flex;
  background: white;
  flex-grow: 1;
  flex-direction: column;
  border-radius: 8px;
`;

const PageHeader = styled.div`
  display: flex;
  height: 30px;
  width: 90%;
  flex-direction: column;
  margin: 20px;
  font-family: Roboto;
  font-size: 30px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
`;

const TableContainer = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  margin: 10px 10px 10px 10px;
`;

const Table = styled.table`
  width: auto;
  border-collapse: collapse;
  margin: 10px;
`;

const TableHeader = styled.th`
  padding: 15px;
  position: relative;
  background: #e0e4f3;
  text-align: left;
  color: #444444;
  font-family: arial;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.20000000298023224px;
`;

const SorterIcon = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 15px;
  height: 36px;
  margin-left: 5px;
  background-image: url(${sorter}); // Use the sorter icon image
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer; // Add cursor pointer for hover effect
`;

const TableRow = styled.tr`
  border-bottom: 1px solid #ddd;
`;

const TableCell = styled.td`
  text-align: left;
  padding: 8px;
  padding: 15px;
  color: #9fa2b4;
`;

const FilterDropdown = styled.select`
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
`;

const Option = styled.option`
  font-size: 14px;
`;

function createData(date, time, user, description) {
  return { date, time, user, description };
}

const rows = [
  createData("1/2/2002", "2:30 PM", "Admin", "Changed Name"),
  createData("1/2/2002", "2:30 PM", "Admin", "Changed Name"),
  createData("1/2/2002", "2:30 PM", "Admin", "Changed Name"),
  createData("1/2/2002", "2:30 PM", "Admin", "Changed Name"),
];

const History = () => {
  const [auth] = useAuth();
  const [users, setUsers] = useState([]);
  const [claims, setClaims] = useState([]);
  const [claimId, setClaimId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [history, setHistory] = useState([]);

  useEffect(() => {
    fetchUsers();
    fetchClaims();
  }, []);

  useEffect(() => {
    getHistory();
  }, [claimId, userId]);

  const fetchUsers = async () => {
    try {
      const { data } = await axios.get("/api/v1/users", {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      if (data.success) {
        setUsers(data.users);
        // setLoading(false)
      }
    } catch (error) {
      // toast.error('Error fetching users');
      console.error(error);
    }
  };

  const fetchClaims = async () => {
    try {
      const { data } = await axios.get("/api/v1/getAllclaim", {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      if (data.success) {
        setClaims(data?.claims);
        // setLoading(false)
      }
    } catch (error) {
      // toast.error('Error fetching users');
      console.error(error);
    }
  };

  const getHistory = async () => {
    try {
      const { data } = await axios.get(
        `/api/v1/auth/getHistoryByUserIdAndClaimId?claimId=${claimId}&userId=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      if (data.success) {
        setHistory(data?.historyRecords);
        // setLoading(false)
      }
    } catch (error) {
      // toast.error('Error fetching users');
      console.error(error);
    }
  };

  const [selectedOption, setSelectedOption] = useState("");
  const handleFilterChange = (e) => {
    setSelectedOption(e.target.value);
  };
  return (
    <Layout>
      <MainContainer>
        <Row>
          <Col md={12}>
            <Row className="d-flex align-items-center justify-content-between">
              <Col md={6}>
                <PageHeader>History</PageHeader>
              </Col>
              <Col md={6} className="">
                <div className="d-flex align-items-center gap-3 w-100">

                  <FilterDropdown
                    value={selectedOption}
                    onChange={(e) => setClaimId(e.target.value)}
                  >
                    <Option value="">Claim</Option>
                    {claims?.map((claim, index) => {
                      return (
                        <Option value={claim?._id} key={index}>
                          {claim?.fileNumber}
                        </Option>
                      );
                    })}
                  </FilterDropdown>
                  <FilterDropdown
                    value={selectedOption}
                    onChange={(e) => setUserId(e.target.value)}
                  >
                    <Option value="">users</Option>
                    {users?.map((user, index) => {
                      return (
                        <Option value={user?._id} key={index}>
                          {user?.user_code}
                        </Option>
                      );
                    })}
                  </FilterDropdown>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <TableContainer>
          <Table>
            <thead>
              <TableRow>
                <TableHeader>
                  Date
                  <SorterIcon />
                </TableHeader>
                <TableHeader>
                  User
                  <SorterIcon />
                </TableHeader>
                <TableHeader>
                  Action Type
                  <SorterIcon />
                </TableHeader>
                <TableHeader>
                  Description
                  <SorterIcon />
                </TableHeader>
              </TableRow>
            </thead>
            <tbody>
              {history?.map((row) => (
                <TableRow key={row.createdAt}>
                  <TableCell component="th" scope="row">
                    {moment(row.createdAt).format("YYYY-MM-DD hh:mm:ss")}
                  </TableCell>
                  <TableCell align="right">{row.userName}</TableCell>
                  <TableCell align="right">{row.actionType}</TableCell>
                  <TableCell align="right">{row.description}</TableCell>
                </TableRow>
              ))}
            </tbody>
          </Table>
        </TableContainer>
      </MainContainer>
    </Layout>
  );
};

export default History;
