import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import styled from "styled-components";
import mapsIcon from "../../assets/mapsicon.png"; // Importing the icon
import { useAuth } from "../../context/auth";

const MainContainer = styled.div`
  padding: 20px;
  background-color: white;
  width: 100%;
`;

const Field = styled.div`
  margin: 10px 10px 10px 10px;
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-weight: bold;
  align-self: flex-start;
  display: flex;
  align-items: center;
`;

const Value = styled.div`
  margin-left: 20px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin: 10px 0;
`;

// Custom styled icon button
const IconButton = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 10px;
  cursor: pointer;
`;

// Component Definition
const Details = ({ claim }) => {
  const [auth] = useAuth();
  const [clients, setClients] = useState([]);
  const [claimTypes, setClaimTypes] = useState([]);
  const [lossTypes, setLossTypes] = useState([]);
  const [adjusters, setAdjusters] = useState([]);
  const [examiners, setExaminers] = useState([]);

  useEffect(() => {
    console.log("Clients from API:", clients);
    console.log("Claim clients:", claim.clients);
  }, [clients, claim.clients]);

  
  // Function to open Google Maps with the loss address
  const openMap = () => {
    const address = `${claim.lossAddress || ""} ${claim.lossCity || ""}, ${
      claim.lossProvince || ""
    } ${claim.lossPostalCode || ""}`;
    const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      address
    )}`;
    window.open(url, "_blank");
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const [year, month, day] = dateString.split("T")[0].split("-");
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    return `${monthNames[parseInt(month, 10) - 1]} ${parseInt(day, 10)}, ${year}`;
  };
  

  return (
    <MainContainer>
      <Row>
      <h5>Claim Details</h5>
      <Col>
        <Row className="d-flex align-items-center">
          <Col>
            <Field>
              <Label>Our File #</Label>
              <Value>{claim.fileNumber || "N/A"}</Value>
            </Field>
          </Col>
          <Col>
            <Field>
              <Label>
                Loss Address
                <IconButton
                  src={mapsIcon}
                  alt="Open in Maps"
                  onClick={openMap}
                />
              </Label>
              <Value>{claim.lossAddress || "N/A"}</Value>
              <Value>{`${claim.lossCity || "N/A"}, ${
                claim.lossProvince || "N/A"
              } ${claim.lossPostalCode || "N/A"}`}</Value>
            </Field>
          </Col>
        </Row>
        <Divider />
        <Row>
          {claim.clients?.length > 0 ? (
            claim.clients.map((clientItem, index) => (
              <Col key={clientItem._id || index}>
                <Field>
                  <Label>Client {index + 1}</Label>
                  <Value>{clientItem.client?.name || "N/A"}</Value>
                  <Value style={{ marginLeft: "50px" }}>
                    Claim #: {clientItem.claimNumber || "N/A"}
                  </Value>
                  <Value style={{ marginLeft: "50px" }}>
                    Share % {clientItem.sharePercentage || "N/A"}
                  </Value>
                </Field>
              </Col>
            ))
          ) : (
            <Col>
              <Field>
                <Label>Clients</Label>
                <Value>N/A</Value>
              </Field>
            </Col>
          )}
        </Row>

        <Divider />
        <Row>
          <Col>
            <Field>
              <Label>Lead Examiner</Label>
              <Value>
              {claim.examinerId.name || "N/A"}
              </Value>
            </Field>
          </Col>
          <Col>
            <Field>
              <Label>Adjuster</Label>
              <Value>
              {claim.adjusterId.name || "N/A"}
              </Value>
            </Field>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col>
            <Field>
              <Label>Date of Loss</Label>
              <Value>{formatDate(claim?.dateOfLoss)}
              </Value>
            </Field>
          </Col>
          <Col>
            <Field>
              <Label>Proscription Date</Label>
              <Value>{formatDate(claim?.proscriptionDate)}
              </Value>
            </Field>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col>
            <Field>
              <Label>Claim Type</Label>
              <Value>
                {claim.typeOfClaimId.description || "N/A"}
              </Value>
            </Field>
          </Col>
          <Col>
            <Field>
              <Label>Loss Type</Label>
              <Value>
              {claim.typeOfLossId.description || "N/A"}
              </Value>
            </Field>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col>
            <Field>
              <Label>Loss Detail</Label>
              <Value>{claim.lossDetail || "N/A"}</Value>
            </Field>
          </Col>
          <Divider />
        </Row>
      </Col>
    </Row>
    </MainContainer>
  );
};

export default Details;
