import { Route, Routes } from "react-router-dom";
import AddClaimForm from "./components/claims/addClaim/AddClaimFrom";
import PrivateRoute from "./components/Routes/Private";
import Admin from "./pages/admin/admin";
import History from "./pages/admin/history";
import Locations from "./pages/admin/locations";
import Permissions from "./pages/admin/permissions";
import Roles from "./pages/admin/roles";
import SystemFeilds from "./pages/admin/systemFeilds";
import Templates from "./pages/admin/templates";
import Users from "./pages/admin/users";
import ForgotPasssword from "./pages/auth/ForgotPassword";
import Login from "./pages/auth/Login";
import Brokers from "./pages/user/brokers";
import Claims from "./pages/user/claims";
import Clients from "./pages/user/clients";
import Dashboard from "./pages/user/dashboard";
import Reports from "./pages/user/reports";
import Resources from "./pages/user/resources";
import UserFilesList from "./pages/user/UserFolderFilesList";
import Vendors from "./pages/user/vendors";
 
import NoPage from "./pages/NoPage";
import Test from "./pages/user/test";
 
import AllAutomations from "./pages/user/automationComponents/allAutomations";

export default function Router() {
  return (
     <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<PrivateRoute />}>
          <Route path="user" element={<Dashboard/>} />
        </Route>
        <Route path="/claims/:fileNumber?" element={<PrivateRoute />}>
          <Route path="" element={<Claims/>} />
        </Route>
        <Route path="/add-new-claim" element={<PrivateRoute />}>
          <Route path="" element={<AddClaimForm/>} />
        </Route>
        <Route path="/clients" element={<PrivateRoute />}>
          <Route path="" element={<Clients/>} />
        </Route>
        <Route path="/vendors" element={<PrivateRoute />}>
          <Route path="" element={<Vendors/>} />
        </Route>
        <Route path="/brokers" element={<PrivateRoute />}>
          <Route path="" element={<Brokers/>} />
        </Route>
        <Route path="/reports" element={<PrivateRoute />}>
          <Route path="" element={<Reports/>} />
        </Route>
        <Route path="/automation" element={<PrivateRoute />}>
          <Route path="" element={<AllAutomations/>} />
        </Route>
        <Route path="/resources" element={<PrivateRoute />}>
          <Route path="" element={<Resources/>} />
        </Route>
        <Route path="/userFiles" element={<PrivateRoute />}>
          <Route path="" element={<UserFilesList/>} />
        </Route>
        <Route path="/admin" element={<PrivateRoute />}>
          <Route path="" element={<Admin/>} />
        </Route>




        {/* admin routess */}
        <Route path="/admin" element={<PrivateRoute />}>
          <Route path="locations" element={<Locations/>} />
        </Route>
        <Route path="/admin" element={<PrivateRoute />}>
          <Route path="roles" element={<Roles/>} />
        </Route>
        <Route path="/admin" element={<PrivateRoute />}>
          <Route path="permissions" element={<Permissions/>} />
        </Route>
        <Route path="/admin" element={<PrivateRoute />}>
          <Route path="templates" element={<Templates/>} />
        </Route>
        <Route path="/admin" element={<PrivateRoute />}>
          <Route path="users" element={<Users/>} />
        </Route>
        <Route path="/admin" element={<PrivateRoute />}>
          <Route path="history" element={<History/>} />
        </Route>
        <Route path="/admin" element={<PrivateRoute />}>
          <Route path="system-fields" element={<SystemFeilds/>} />
        </Route>
         <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPasssword />} />
        <Route path="*" element={<NoPage />} />
        <Route path="/test" element={<PrivateRoute />}>
          <Route path="" element={<Test />} />
        </Route>
      </Routes>
  );
}
