import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import toast from 'react-hot-toast';
import axios from 'axios';
import VendorContactTable from './ContactTable';
import VendorContactForm from './ContactForm';
import Button from '../../common/Button'
import Modal from '../../common/Modal';
import { useAuth } from '../../../context/auth';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const HeaderContainer = styled.div`
  height: 5vh;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const VendorContacts = ({ vendor }) => {
  const [auth] = useAuth();
  const [loading, setLoading] = useState(true);
  const [vendorContacts, setVendorContacts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedVendorContact, setSelectedVendorContact] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    role: '',
    phone: '',
  });

  useEffect(() => {
    const fetchVendorContacts = async () => {
      try {
        const { data } = await axios.get(`/api/v1/vendor/vendorContacts/${vendor._id}`,{
          headers: {
            'Authorization': `Bearer ${auth?.token}`,
          }
        });
        if (data.success) {
          setVendorContacts(data.vendorContacts);
        } else {
          toast.error('Failed to fetch vendorContacts');
        }
      } catch (error) {
        console.error('Error fetching vendorContacts:', error);
        toast.error('Something went wrong while fetching vendorContacts');
      } finally {
        setLoading(false);
      }
    };
    fetchVendorContacts();
  }, [vendor._id]);

  const handleAddVendorContact = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(`/api/v1/vendorContacts/${vendor._id}`, {formData}, {
        headers: {
          'Authorization': `Bearer ${auth?.token}`,
        }
      });

      if (data.success) {
        setVendorContacts([data.vendorContact, ...vendorContacts]);
        setFormData({ name: '', email: '', role: '', phone: '' });
        toast.success('VendorContact added successfully');
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error('Error adding vendorContact:', error);
      toast.error('Something went wrong while adding the vendorContact');
    }
  };

  const handleUpdateVendorContact = async (e) => {
    e.preventDefault();
    try {
      const {data} = await axios.put(`/api/v1/vendorContacts/${selectedVendorContact._id}`, {formData}, {
        headers: {
          'Authorization': `Bearer ${auth?.token}`,
        }
      });
      if (data.success) {
        const updatedVendorContacts = vendorContacts.map(vendorContact =>
          vendorContact._id === selectedVendorContact._id ? data.vendorContact : vendorContact
        );
        setVendorContacts(updatedVendorContacts);
        setSelectedVendorContact(null);
        setIsModalOpen(false);
        setFormData({ name: '', email: '', role: '', phone: '' });
        toast.success('VendorContact updated successfully');
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error('Error updating vendorContact:', error);
      toast.error('Something went wrong while updating the vendorContact');
    }
  };

  const handleDeleteVendorContact = async (id) => {
    try {
      const { data } = await axios.delete(`/api/v1/vendorContacts/${id}`, {
        headers: {
          'Authorization': `Bearer ${auth?.token}`,
        }
      });
      if (data.success) {
        setVendorContacts(vendorContacts.filter(vendorContact => vendorContact._id !== id));
        toast.success('VendorContact deleted successfully');
      } else {
        toast.error('Failed to delete vendorContact');
      }
    } catch (error) {
      console.error('Error deleting vendorContact:', error);
      toast.error('Something went wrong while deleting the vendorContact');
    }
  };

  const handleEditVendorContact = (vendorContact) => {
    setSelectedVendorContact(vendorContact);
    setFormData({ name: vendorContact.name, email: vendorContact.email, role: vendorContact.role, phone: vendorContact.phone });
    setIsModalOpen(true);
  };

  return (
    <Container>
      <HeaderContainer>
        <div style={{ marginLeft: 'auto' }}>
          <Button onClick={() => setIsModalOpen(true)}>Add</Button>
        </div>
      </HeaderContainer>
      <VendorContactTable
        loading={loading}
        vendorContacts={vendorContacts}
        onEdit={handleEditVendorContact}
        onDelete={handleDeleteVendorContact}
      />
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <VendorContactForm
          formData={formData}
          setFormData={setFormData}
          handleSubmit={selectedVendorContact ? handleUpdateVendorContact : handleAddVendorContact}
          title={selectedVendorContact ? 'Update VendorContact' : 'Add VendorContact'}
        />
      </Modal>
    </Container>
  );
};

export default VendorContacts;
