import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import toast from "react-hot-toast";
import Modal from "../../components/common/Modal";
import { useAuth } from "../../context/auth";
import FillBtn from "../../shared/Buttons/FillBtn";
import AddAutomationForm from "./addAutomationForm";
import ConfirmationModal from "../../shared/Confirmation";
import ConfirmationModalTime from "../../shared/Confirmation";
import { Row, Col } from "reactstrap";

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  flex-grow: 1;
`;

const Table = styled.table`
  width: 100%;
`;

const TableHeader = styled.th`
  padding: 15px;
  background: #e0e4f3;
  color: #444;
  font-family: Poppins, sans-serif;
  font-size: 15px;
  font-weight: 400;
`;

const TableRow = styled.tr`
  border-bottom: 1px solid #ddd;
`;

const TableCell = styled.td`
  padding: 15px;
  color: black;
`;

const NoDataMessage = styled.div`
  text-align: center;
  color: #9fa2b4;
  font-size: 24px;
  margin-top: 30px;
`;

const DropdownContainer = styled.div`
  position: relative;
  display: block;
  margin-left: 10px;
  color: black;
  background: none;
  cursor: pointer;
  font-weight: bold;
`;

const DropdownContent = styled.div`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  position: absolute;
  background-color: #f9f9f9;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
`;

const DropdownItem = styled.button`
  color: black;
  padding: 8px 10px;
  display: block;
  width: 100%;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;

  &:hover {
    background-color: #f1f1f1;
  }
`;

const Automation = ({ client }) => {
  const [tasks, setTasks] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isConfirmationModalTimeOpen, setIsConfirmationModalTimeOpen] = useState(false);
  const [codes, setCodes] = useState([]);
  const [automationId, setAutomationId] = useState(null);
  const [auth] = useAuth();
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [errors, setErrors] = useState({});
  const [timeTasks, setTimeTasks] = useState([]);
  const [isTimeModalOpen, setIsTimeModalOpen] = useState(false);
  const [formData, setFormData] = useState({ dueDate: "", description: "", action: "",
    activity_Code: "",
    timeDescription: "",
    time: "",
    expense: "",
    frequency: "",
    repeats: "",
  });

  // Fetch activity codes on component mount
  useEffect(() => {
    fetchActivityCodes();
  }, []);

  // Fetch automations and time automations when client changes
  useEffect(() => {
    if (client && client._id) {
      fetchAutomations(client._id);
      fetchTimeAutomations(client._id);
    }
  }, [client]);

  const fetchActivityCodes = async () => {
    try {
      const { data } = await axios.get("/api/v1/activity-codes", {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      setCodes(data);
    } catch (error) {
      toast.error("Error fetching activity codes. Please try again later.");
      console.error("Fetch activity codes error:", error);
    }
  };
  const filteredCodes = codes.filter(
    (code) =>
      code.code.toLowerCase().includes(searchQuery.toLowerCase()) ||
      code.description.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const fetchAutomations = async (clientId) => {
    try {
      const response = await axios.get(`/api/v1/getAllAutomationTask?clientId=${clientId}`, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      });
      if (response.data.success) {
        setTasks(response.data.automation);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Error fetching automations.");
    }
  };
  
  const fetchTimeAutomations = async (clientId) => {
    try {
      const response = await axios.get(`/api/v1/getAllAutomationTime?clientId=${clientId}`, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      });
      if (response.data.success) {
        setTimeTasks(response.data.automation);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Error fetching time automations.");
    }
  };

  const handleActivityCodeChange = (value) => {
    const activityCode = value.toUpperCase(); // Ensure uppercase
    setFormData((prev) => ({ ...prev, activity_Code: activityCode }));

    const matchedCode = codes.find((code) => code.code.toUpperCase() === activityCode);

    if (matchedCode) {
      setFormData((prev) => ({
        ...prev,
        populated_description: matchedCode.description,
        additional_description: "",
      }));
      setSearchQuery(""); // Clear the search query
      setErrors((prev) => {
        const { activity_Code, ...rest } = prev;
        return rest; // Remove activity_Code error
      });
    } else {
      setSearchQuery(value);
      setErrors((prev) => ({
        ...prev,
        activity_Code: "Invalid activity code. Please select a valid code.",
      }));
    }
  };

  const handleTimeSubmit = async (e) => {
    e.preventDefault();

    if (!formData.activity_Code || !formData.timeDescription || !formData.time || !formData.expense) {
      toast.error("All fields are required.");
      return;
    }

    try {
      const response = await axios.post(
        "/api/v1/createAutomationTime",
        { ...formData, clientId: client._id },
        { headers: { Authorization: `Bearer ${auth?.token}` } }
      );

      setTimeTasks((prev) => [...prev, response.data.newTime]);
      setFormData({ activity_Code: "", timeDescription: "", time: "", expense: "" });
      setIsTimeModalOpen(false);
      fetchTimeAutomations(client._id);
      toast.success("Time automation added successfully!");
    } catch (error) {
      toast.error("Failed to add time automation. Please try again.");
      console.error("Error adding time automation:", error);
    }
  };



  

  const handleAddSubmit = async (event, resetForm) => {
    event.preventDefault();
    if (!formData.dueDate || !formData.description) {
      toast.error("Please fill in all required fields.");
      return;
    }
    try {
      const response = await axios.post(
        `/api/v1/createAutomationTask`,
        { ...formData, clientId: client._id },
        { headers: { Authorization: `Bearer ${auth?.token}` } }
      );
      if (response.data.success) {
        resetForm();
        fetchAutomations(client._id);
        closeModal();
        toast.success("Automation added successfully!");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Error adding automation.");
    }
  };

  const handleEditSubmit = async (event, resetForm) => {
    event.preventDefault();
    if (!formData.dueDate || !formData.description) {
      toast.error("Please fill in all required fields.");
      return;
    }
    try {
      const response = await axios.put(`/api/v1/updateAutomationTask/${automationId}`, formData, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      });
      if (response.data.success) {
        resetForm();
        fetchAutomations(client._id);
        closeModal();
        toast.success("Automation updated successfully!");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Error updating automation.");
    }
  };

  const handleEditTimeSubmit = async (event, resetForm) => {
    event.preventDefault();
    if (!formData.dueDate || !formData.description) {
      toast.error("Please fill in all required fields.");
      return;
    }
    try {
      const response = await axios.put(`/api/v1/updateAutomationTime/${automationId}`, formData, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      });
      if (response.data.success) {
        resetForm();
        fetchAutomations(client._id);
        closeModal();
        toast.success("Automation updated successfully!");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Error updating automation.");
    }
  };

  const handleDelete = async () => {
    try {
      const response = await axios.delete(`/api/v1/deleteAutomationTask/${automationId}`, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      });
      if (response.data.success) {
        fetchAutomations(client._id);
        toast.success("Automation deleted!");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Error deleting automation.");
    } finally {
      setIsConfirmationModalOpen(false);
      setAutomationId(null);
    }
  };
  const handleDeleteTime = async () => {
    try {
      const response = await axios.delete(`/api/v1/deleteAutomationTime/${automationId}`, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      });
      if (response.data.success) {
        fetchAutomations(client._id);
        toast.success("Automation deleted!");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Error deleting automation.");
    } finally {
      setIsConfirmationModalTimeOpen(false);
      setAutomationId(null);
      fetchTimeAutomations(client._id);
    }
  };
  const handleOpenConfirmationModal = (id) => {
    setAutomationId(id);
    setIsConfirmationModalOpen(true);
  };
  const handleOpenConfirmationModalTime = (id) => {
    setAutomationId(id);
    setIsConfirmationModalTimeOpen(true);
  };
  
  const handleSelectCode = (code) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      activity_Code: code.code, // Update the selected activity code
      populated_description: code.description, // Populate the description
    }));
  
    setSearchQuery(""); // Clear the search query
  
    setErrors((prevErrors) => {
      const { activity_Code, ...rest } = prevErrors;
      return rest; // Remove the activity_Code error
    });
  };
  

  const handleEditClick = (row) => {
    setFormData({
      dueDate: row.dueDate || "",
      description: row.description || "",
      repeats: row.repeats || false, // Ensure it's a boolean
      action: row.action || "",
      frequency: row.frequency || "",
    });    
    setAutomationId(row._id);
    setIsModalOpen(true);
  };

  const handleEditTimeClick = (row) => {
    setFormData({
      activity_Code: row.activity_code || "",
      timeDescription: row.timeDescription || "",
      time: row.time || "",
      expense: row.expense || "",
    });
    setAutomationId(row._id);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setFormData({ dueDate: "", description: "", repeats: "", action: "", frequency: "" });
    setAutomationId(null);
  };

  const toggleDropdown = (id) => {
    setOpenDropdownId(openDropdownId === id ? null : id);
  };


  return (
      <Container>
      <Row className="align-items-center my-2">
        <Col md={9}>
          <h4>Automations for {client?.name || "No Client Selected"}</h4>
        </Col>
        <Col md={3}>
          <FillBtn
            className="w-100"
            text="Add Automation"
            handleOnClick={() => setIsModalOpen(true)}
          />
        </Col>
      </Row>
      <TableContainer>
        <Table>
          <thead>
            <TableRow>
              <TableHeader>Due Date</TableHeader>
              <TableHeader>Description</TableHeader>
              <TableHeader>Repeat Task</TableHeader>
              <TableHeader>Frequency</TableHeader>
              <TableHeader>Actions</TableHeader>

            </TableRow>
          </thead>
          <tbody>
            {tasks.length > 0 ? (
              tasks.map((row) => (
                <TableRow key={row?._id}>
                  <TableCell>{row?.dueDate ?? "No Due Date"}</TableCell>
                  <TableCell>{row?.description ?? "No Description"}</TableCell>
                  <TableCell>{row?.repeats === true ? "True" : "False"}</TableCell>
                  <TableCell>{row?.frequency ?? "No"}</TableCell>
                  <TableCell>
    <DropdownContainer>
      <span className="dropdown-btn" onClick={() => toggleDropdown(row._id)}>...</span>
      <DropdownContent isOpen={openDropdownId === row._id}>
      <DropdownItem 
  onClick={() => {
    handleEditClick(row);
    toggleDropdown(row._id);
  }}
>
  Edit
</DropdownItem>
        <DropdownItem onClick={() => handleOpenConfirmationModal(row._id)}>
          Delete
        </DropdownItem>
      </DropdownContent>
    </DropdownContainer>
  </TableCell>

                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6}>
                  <NoDataMessage>No data found for this client.</NoDataMessage>
                </TableCell>
              </TableRow>
            )}
          </tbody>
        </Table>
      </TableContainer>
      <Modal open={isModalOpen} onClose={closeModal}>
      <AddAutomationForm
  formData={formData}
  setFormData={setFormData}
  handleSubmit={automationId ? handleEditSubmit : handleAddSubmit}
  title={automationId ? "Edit Automation" : "Add Automation"}
  client={client} // Ensure this is passed
/>
      </Modal>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        toggle={() => setIsConfirmationModalOpen(!isConfirmationModalOpen)}
        title="Confirm Action"
        body="Are you sure you want to delete this automation?"
        onConfirm={handleDelete}
      />
      <ConfirmationModalTime
        isOpen={isConfirmationModalTimeOpen}
        toggle={() => setIsConfirmationModalTimeOpen(!isConfirmationModalTimeOpen)}
        title="Confirm Action"
        body="Are you sure you want to delete this automation?"
        onConfirm={handleDeleteTime}
      />

      <Row className="align-items-center my-2">
        <Col md={9}>
          <h4>Time & Expense Automations</h4>
        </Col>
        <Col md={3}>
          <FillBtn text="Add Time Automation" handleOnClick={() => setIsTimeModalOpen(true)} />
        </Col>
      </Row>
      <TableContainer>
        <Table>
          <thead>
            <TableRow>
              <TableHeader>Activity Code</TableHeader>
              <TableHeader>Description</TableHeader>
              <TableHeader>Time</TableHeader>
              <TableHeader>Expense</TableHeader>
              <TableHeader>Actions</TableHeader>
            </TableRow>
          </thead>
          <tbody>
            {timeTasks.length > 0 ? (
              timeTasks
              .filter((task) => task) // Filter out undefined/null entries
              .map((task, index) => (
                <TableRow key={task._id || index}>
                  <TableCell>{task?.activity_Code || "N/A"}</TableCell>
                  <TableCell>{task?.timeDescription || "N/A"}</TableCell>
                  <TableCell>{task?.time || "0.00"}</TableCell>
                  <TableCell>{task?.expense || "0.00"}</TableCell>
                  <TableCell>
    <DropdownContainer>
      <span className="dropdown-btn" onClick={() => toggleDropdown(task._id)}>...</span>
      <DropdownContent isOpen={openDropdownId === task._id}>
        <DropdownItem onClick={() => handleEditTimeClick(task)}>Edit</DropdownItem>
        <DropdownItem onClick={() => handleOpenConfirmationModalTime(task._id)}>
          Delete
        </DropdownItem>
      </DropdownContent>
    </DropdownContainer>
  </TableCell>
                </TableRow>
              ))
            
            ) : (
              <TableRow>
                <TableCell colSpan={4}>
                  <NoDataMessage>No time automation entries yet.</NoDataMessage>
                </TableCell>
              </TableRow>
            )}
          </tbody>
        </Table>
      </TableContainer>

      {/* Modals */}
      <Modal open={isTimeModalOpen} onClose={() => setIsTimeModalOpen(false)}>
  <h3>Add Time Automation</h3>
  
  <form onSubmit={handleTimeSubmit}>
    <Col>
    {/* Activity Code */}
    <label htmlFor="activity_Code">Activity Code</label><br/>
    <input
      type="text"
      id="activity_Code"
      name="activity_Code"
      value={formData.activity_Code}
      onChange={(e) => handleActivityCodeChange(e.target.value)}
      required
      aria-required="true"
      placeholder="Enter activity code"
    />
    {errors?.activity_Code && (
      <small style={{ color: "red" }}>{errors.activity_Code}</small>
    )}

    {/* Search Suggestions */}
   {/* Search Suggestions */}
{searchQuery && (
  <div
    className="search-suggestions"
    style={{
      width: "100%",
      display: "flex",
      flexDirection: "column",
      margin: "0 auto",
      border: "1px solid #ddd",
      borderRadius: "4px",
      boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
    }}
  >
    {filteredCodes.map((code) => (
      <div
        key={code.code}
        style={{
          padding: "8px",
          cursor: "pointer",
          borderBottom: "1px solid #f0f0f0",
          fontSize: "14px",
        }}
        onClick={() => handleSelectCode(code)} // Call the handler
      >
        <strong>{code.code}</strong>: {code.description}
      </div>
    ))}
  </div>
)}


    {/* Description */}
    <br/><label htmlFor="timeDescription">Description</label><br/>
    <input
      type="text"
      id="timeDescription"
      value={formData.timeDescription}
      onChange={(e) =>
        setFormData({ ...formData, timeDescription: e.target.value })
      }
      required
    />
</Col><Col>
    {/* Time */}
    <br/><label htmlFor="time">Time</label><br/>
    <input
      type="number"
      id="time"
      value={formData.time}
      onChange={(e) =>
        setFormData({ ...formData, time: e.target.value })
      }
      required
    />

    {/* Expense */}
    <br/><label htmlFor="expense">Expense</label><br/>
    <input
      type="number"
      id="expense"
      value={formData.expense}
      onChange={(e) =>
        setFormData({ ...formData, expense: e.target.value })
      }
      required
    />
</Col>
    {/* Submit Button */}
    <button type="submit" style={{ marginTop: "10px" }}>
      Add Entry
    </button>
  </form>
  
</Modal>

    </Container>
  );
};

export default Automation;