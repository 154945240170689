import { Table } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaEllipsisV } from "react-icons/fa";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import styled from "styled-components";
import { useAuth } from "../../context/auth";
import AddReserveForm from "./AddReserveForm";
import Modal from "./Modal";
import ReceivePaymentForm from "./ReceivePaymentForm";
import RequestPaymentForm from "./RequestPaymentForm";

const AddButton = styled.button`
  background-color: #6495ed;
  color: white;
  border: none;
  margin-left: 20px;
  border-radius: 4px;
  padding: 10px 12px;
  cursor: pointer;
  &:hover {
    background-color: #0069d9;
  }
`;

const CopyButton = styled.button`
  background-color: #28a745;
  color: white;
  border: none;
  margin-right: 100px;
  border-radius: 4px;
  padding: 10px 12px;
  cursor: pointer;
  &:hover {
    background-color: #218838;
  }
  &:disabled {
    background-color: #c3e6cb;
    cursor: not-allowed;
  }
`;


const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
`;

const ExpandedTableContainer = styled.div`
  color: red; /* Corrected without quotes */
  font-weight: 400; /* Optional: Adjust font weight if needed */
  width: 90%;
  margin-left: 5%; /* Centers the container itself */
`;

const ClaimReservesPage = ({ claimId }) => {
  const { auth } = useAuth();
  const [reserves, setReserves] = useState([]);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isRequestPaymentFormOpen, setIsRequestPaymentFormOpen] =
    useState(false);
  const [isReceivePaymentFormOpen, setIsReceivePaymentFormOpen] =
    useState(false);
  const [selectedReserve, setSelectedReserve] = useState(null);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [expandedReserves, setExpandedReserves] = useState({});
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState({});
  const [formData, setFormData] = useState({
    reserveId: "",
    userCode: "",
    lineOfReserve: "",
    limits: "",
    recommendedReserve: "",
    paymentsRequested: "",
    paymentId: Date.now(),
    dateRequested: new Date().toISOString(),
    party: "",
    amountRequested: "",
    amountPaid: "",
    notes: "",
    status: "",
    dateReceived: "",
    lossPaidToDate: "",
    outstandingReserve: "",
    payment_id: "",
  });


  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });

  const formatCurrency = (value) =>
    currencyFormatter.format(isNaN(value) || value === null ? 0 : value);

  useEffect(() => {
    console.log("isAddFormOpen changed:", isAddFormOpen);
  }, [isAddFormOpen]);

  useEffect(() => {
    if (claimId) fetchReserves();
  }, [claimId]);

  const fetchReserves = async () => {
    try {
      const response = await axios.get(`/api/v1/reserves/${claimId}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });

      const updatedReserves = response.data.map((reserve) => {
        // Calculate Loss Paid
        const lossPaid = reserve.payments.reduce(
          (sum, payment) => sum + (payment.amountPaid || 0),
          0
        );

        // Calculate Payments Requested
        const paymentsRequested = reserve.payments.reduce(
          (sum, payment) =>
            sum + Math.max((payment.amountRequested || 0) - (payment.amountPaid || 0), 0),
          0
        );

        // Calculate Outstanding Reserve
        const outstandingReserve =
          (reserve.recommendedReserve || 0) - lossPaid - paymentsRequested;

        // Return the updated reserve with calculated values
        return {
          ...reserve,
          lossPaid,
          paymentsRequested,
          outstandingReserve,
        };
      });

      console.log("Updated Reserves with Calculations:", updatedReserves);
      setReserves(updatedReserves);
    } catch (error) {
      console.error("Error fetching reserves:", error);
      setError("Failed to fetch reserves.");
    }
  };


  const calculateTotals = () => {
    const totals = {
      recommendedReserve: 0,
      lossPaid: 0,
      paymentsRequested: 0,
      outstandingReserve: 0,
    };

    reserves.forEach((reserve) => {
      totals.recommendedReserve += reserve.recommendedReserve || 0;
      totals.lossPaid += reserve.lossPaid || 0;
      totals.paymentsRequested += reserve.paymentsRequested || 0;
      totals.outstandingReserve += reserve.outstandingReserve || 0;
    });

    return totals;
  };

  const addReserve = async (newReserve) => {
    try {
      const response = await axios.post(`/api/v1/reserves/${claimId}`, newReserve, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });

      setReserves((prev) => [...prev, response.data]); // Update state with new reserve
    } catch (err) {
      console.error("Error adding reserve:", err.response?.data || err.message);
      setError("Failed to add reserve. Please ensure you are authorized.");
    }
  };
  const deleteReserve = async (reserveId) => {
    try {
      await axios.delete(`/api/v1/reserves/${claimId}/${reserveId}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`, // Add the token here
        },
      });
      setReserves((prev) => prev.filter((reserve) => reserve.id !== reserveId));
    } catch (error) {
      console.error("Failed to delete reserve:", error.response?.data || error.message);
    }
  };


  const deletePayment = async (reserveId, paymentId) => {
    try {
      await axios.delete(
        `/api/v1/reserves/${claimId}/${reserveId}/payments/${paymentId}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`, // Add the token here
          },
        }
      );
      setReserves((prev) =>
        prev.map((reserve) =>
          reserve.id === reserveId
            ? {
              ...reserve,
              payments: reserve.payments.filter(
                (payment) => payment._id !== paymentId
              ),
              lossPaid: reserve.payments
                .filter((payment) => payment._id !== paymentId)
                .reduce((sum, payment) => sum + (payment.amountPaid || 0), 0),
            }
            : reserve
        )
      );
    } catch (error) {
      console.error("Failed to delete payment:", error.response?.data || error.message);
    }
  };


  const toggleExpandReserve = (reserveId) => {
    setExpandedReserves((prev) => ({
      ...prev,
      [reserveId]: !prev[reserveId],
    }));
  };

  const toggleDropdown = (id) => {
    setDropdownOpen((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const handleSubmit = async (e, action, shouldResetForm = false) => {
    e.preventDefault();

    try {
      if (action === "addReserve") {
        await axios.post(`/api/v1/reserves/${claimId}`, formData, {
          headers: {
            Authorization: `Bearer ${auth?.token}`, // Add the token here
          },
        });
      } else if (action === "requestPayment") {
        await axios.post(
          `/api/v1/reserves/${claimId}/${formData.reserveId}/payments`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`, // Add the token here
            },
          }
        );
      } else if (action === "receivePayment") {
        await axios.put(
          `/api/v1/reserves/${claimId}/${formData.reserveId}/payments/${formData.paymentId}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`, // Add the token here
            },
          }
        );
      }
      fetchReserves();
      if (shouldResetForm) setFormData({});
      setIsAddFormOpen(false);
      setIsRequestPaymentFormOpen(false);
      setIsReceivePaymentFormOpen(false);
    } catch (err) {
      console.error("Error submitting form:", err.response?.data || err.message);
      setError("Failed to submit the form. Please ensure you are authorized.");
    }
  };


  const totals = calculateTotals();

  const copyTableContent = () => {
    try {
      if (reserves.length === 0) {
        console.error("No reserves to copy");
        return;
      }

      // Function to format currency to 2 decimal places with commas
      const formatCurrency = (value) => `$${(value || 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;


      // Extract rows from reserves data
      const rows = reserves.map((reserve) => [
        reserve.lineOfReserve,
        formatCurrency(reserve.limits),
        formatCurrency(reserve.recommendedReserve),
        formatCurrency(reserve.lossPaid),
        formatCurrency(reserve.paymentsRequested),
        formatCurrency(reserve.outstandingReserve),
      ].join("\t"));

      // Add totals row with bold formatting
      const totalsRow = [
        "TOTALS",
        "",
        `${formatCurrency(totals.recommendedReserve)}`,
        `${formatCurrency(totals.lossPaid)}`,
        `${formatCurrency(totals.paymentsRequested)}`,
        `${formatCurrency(totals.outstandingReserve)}`,
      ].join("\t");

      // Combine headers, rows, and totals
      const tableContent = [...rows, totalsRow].join("\n");

      // Create a temporary textarea for copying
      const textarea = document.createElement("textarea");
      textarea.value = tableContent;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);

      console.log("Table content copied successfully!");
      setShowCopiedMessage(true);
      setTimeout(() => setShowCopiedMessage(false), 2000);
    } catch (error) {
      console.error("Failed to copy table content:", error);
    }
  };


  const clearReserves = async () => {
    const isConfirmed = window.confirm(
      "Are you sure you want to clear reserves? This will update all recommended reserves and outstanding reserves."
    );

    if (!isConfirmed) {
      console.log("Clear Reserves action cancelled.");
      return;
    }

    try {
      // Update reserves locally in state
      const updatedReserves = reserves.map((reserve) => {
        const newRecommendedReserve =
          reserve.lossPaid + reserve.paymentsRequested;
        const newOutstandingReserve = newRecommendedReserve - reserve.lossPaid;

        return {
          ...reserve,
          recommendedReserve: newRecommendedReserve,
          outstandingReserve: newOutstandingReserve,
        };
      });

      setReserves(updatedReserves);

      // Optionally, update reserves on the backend
      for (const reserve of updatedReserves) {
        await axios.put(
          `/api/v1/reserves/${reserve.claimId}/${reserve.id}`,
          {
            recommendedReserve: reserve.recommendedReserve,
            outstandingReserve: reserve.outstandingReserve,
          },
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`, // Include token directly here
            },
          }
        );
      }

      console.log("Reserves cleared and updated.");
    } catch (error) {
      console.error("Failed to clear reserves:", error.response?.data || error.message);
      setError("Failed to clear reserves.");
    }
  };

  const columns = [
    {
      title: "Line of Reserve",
      dataIndex: "lineOfReserve",
      key: "lineOfReserve",
    },
    {
      title: "Limits",
      dataIndex: "limits",
      key: "limits",
      render: (value) => `$${value?.toLocaleString(undefined, { minimumFractionDigits: 2 })}`,
    },
    {
      title: "Recommended Reserve",
      dataIndex: "recommendedReserve",
      key: "recommendedReserve",
      render: (value) => `$${value?.toLocaleString(undefined, { minimumFractionDigits: 2 })}`,
    },
    {
      title: "Loss Paid",
      dataIndex: "lossPaid",
      key: "lossPaid",
      render: (value) => `$${value?.toLocaleString(undefined, { minimumFractionDigits: 2 })}`,
    },
    {
      title: "Payments Requested",
      dataIndex: "paymentsRequested",
      key: "paymentsRequested",
      render: (value) => `$${value?.toLocaleString(undefined, { minimumFractionDigits: 2 })}`,
    },
    {
      title: "Outstanding Reserve",
      dataIndex: "outstandingReserve",
      key: "outstandingReserve",
      render: (value) => `$${value?.toLocaleString(undefined, { minimumFractionDigits: 2 })}`,
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, reserve) => (
        <Dropdown
          isOpen={!!dropdownOpen[reserve.id]}
          toggle={() => toggleDropdown(reserve.id)}
          direction="down"
        >
          <DropdownToggle className="bg-transparent border">
            <FaEllipsisV color="black" />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              onClick={() => {
                setSelectedReserve(reserve); // Set the selected reserve
                setIsRequestPaymentFormOpen(true); // Open the modal
              }}
            >
              Request Payment
            </DropdownItem>

            <DropdownItem
              onClick={() => {
                setSelectedReserve(reserve); // Pass the reserve to be edited
                setIsAddFormOpen(true); // Open the modal
              }}
            >
              Edit
            </DropdownItem>

            <DropdownItem onClick={() => deleteReserve(reserve.id)}>
              Delete
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      ),
    },
  ];

  return (
    <div
      className="reserve-page"
      style={{
        width: "100%" /* Ensure full width */,
        display: "flex",
        flexDirection: "column",
        margin: "0 auto" /* Optional: Center content horizontally */,
      }}
    >
      <h5>Reserves</h5>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <AddButton
          onClick={() => {
            console.log("Add Reserve button clicked");
            setSelectedReserve(null);
            setIsAddFormOpen(true); // Trigger modal opening
            console.log("isAddFormOpen:", isAddFormOpen); // Log current state
          }}
        >
          Add Reserve
        </AddButton>

        <CopyButton onClick={copyTableContent}>Copy Data</CopyButton>
      </div>
      {showCopiedMessage && (
        <div style={{ marginTop: "10px", color: "green" }}>
          Table content copied!
        </div>
      )}
      <TableContainer>
        <Table
          dataSource={reserves}
          columns={columns}
          rowKey="id"
          bordered
          expandable={{
            expandedRowRender: (reserve) => (
              <ExpandedTableContainer>
                <Table
                  dataSource={reserve.payments}
                  columns={[
                    {
                      title: "Date Requested",
                      dataIndex: "dateRequested",
                      key: "dateRequested",
                      render: (value) => (
                        <span style={{ color: 'red' }}>
                          {value ? new Date(value).toLocaleDateString() : "N/A"}
                        </span>
                      ),
                    },
                    {
                      title: "Party",
                      dataIndex: "party",
                      key: "party",
                      render: (value) => <span style={{ color: 'red' }}>{value}</span>,
                    },
                    {
                      title: "Amount Requested",
                      dataIndex: "amountRequested",
                      key: "amountRequested",
                      render: (value) => (
                        <span style={{ color: 'red' }}>
                          {`$${value?.toLocaleString(undefined, { minimumFractionDigits: 2 })}`}
                        </span>
                      ),
                    },
                    {
                      title: "Amount Paid",
                      dataIndex: "amountPaid",
                      key: "amountPaid",
                      render: (value) => (
                        <span style={{ color: 'red' }}>
                          {`$${value?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                        </span>
                      ),
                    },
                    {
                      title: "Date Received",
                      dataIndex: "dateReceived",
                      key: "dateReceived",
                      render: (value) => (
                        <span style={{ color: 'red' }}>
                          {value ? new Date(value).toLocaleDateString() : "N/A"}
                        </span>
                      ),
                    },
                    {
                      title: "Notes",
                      dataIndex: "notes",
                      key: "notes",
                      render: (value) => (
                        <span style={{ color: 'red' }}>
                          {value || "N/A"}
                        </span>
                      ),
                    },
                    {
                      title: "Actions",
                      key: "actions",
                      render: (_, payment) => (
                        <Dropdown
                          isOpen={!!dropdownOpen[payment._id]}
                          toggle={() => toggleDropdown(payment._id)}
                          direction="down"
                        >
                          <DropdownToggle className="bg-transparent border">
                            <FaEllipsisV color="black" />
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              onClick={() => {
                                setSelectedReserve(reserves.find(res => res.payments.some(p => p._id === payment._id)));
                                setSelectedPayment(payment);
                                setIsReceivePaymentFormOpen(true);
                              }}
                            >
                              Receive Payment
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => deletePayment(payment.reserveId, payment._id)}
                            >
                              Delete
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      ),
                    },
                  ]}
                  rowKey="_id"
                  pagination={false}
                />
              </ExpandedTableContainer>
            ),
          }}
          summary={() => (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} />
              <Table.Summary.Cell index={1}><strong>TOTALS</strong></Table.Summary.Cell>
              <Table.Summary.Cell index={2} />
              <Table.Summary.Cell index={3}>
                <strong>
                  {`$${totals.recommendedReserve.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                </strong>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4}>
                <strong>
                  {`$${totals.lossPaid.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                </strong>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5}>
                <strong>
                  {`$${totals.paymentsRequested.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                </strong>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={6}>
                <strong>
                  {`$${totals.outstandingReserve.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                </strong>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={7} />
            </Table.Summary.Row>
          )}          
        />
      </TableContainer>

      {isAddFormOpen && (
        <Modal onClose={() => setIsAddFormOpen(false)}>
          <AddReserveForm
            reserve={selectedReserve}
            onClose={() => {
              setIsAddFormOpen(false);
              fetchReserves(); // Refresh reserves from the backend
            }}
            onSave={async (formData) => {
              try {
                if (selectedReserve && selectedReserve.id) {
                  // Editing an existing reserve
                  await axios.put(
                    `/api/v1/reserves/${claimId}/${selectedReserve.id}`,
                    formData,
                    {
                      headers: {
                        Authorization: `Bearer ${auth?.token}`,
                      },
                    }
                  );

                  // Optimistic update
                  setReserves((prevReserves) =>
                    prevReserves.map((reserve) =>
                      reserve.id === selectedReserve.id ? { ...reserve, ...formData } : reserve
                    )
                  );
                } else {
                  // Adding a new reserve
                  await addReserve(formData);
                }

                setSelectedReserve(null);
                setIsAddFormOpen(false);
              } catch (error) {
                console.error("Failed to save reserve:", error.response?.data || error.message);
                setError("Failed to save reserve. Please try again.");
              }
            }}
          />

        </Modal>

      )}
      {isRequestPaymentFormOpen && selectedReserve && (
        <Modal
          onClose={() => {
            setIsRequestPaymentFormOpen(false);
            fetchReserves(); // Fetch reserves when the modal is closed
          }}
        >
          <RequestPaymentForm
            reserve={selectedReserve}
            onClose={() => {
              setIsRequestPaymentFormOpen(false);
              fetchReserves(); // Fetch reserves when the form's onClose is triggered
            }}
            onSave={async (newPayment) => {
              try {
                // Optimistic update: add payment to state immediately for responsiveness
                setReserves((prevReserves) =>
                  prevReserves.map((reserve) =>
                    reserve.id === selectedReserve.id
                      ? {
                        ...reserve,
                        payments: [...reserve.payments, newPayment],
                        paymentsRequested:
                          reserve.paymentsRequested + newPayment.amountRequested,
                      }
                      : reserve
                  )
                );

                // Re-fetch reserves to ensure data is accurate
                await fetchReserves();
              } catch (error) {
                console.error(
                  "Failed to update reserves after payment:",
                  error
                );
              }
              setIsRequestPaymentFormOpen(false);
            }}
          />
        </Modal>

      )}
      {isReceivePaymentFormOpen && selectedReserve && selectedPayment && (
        <Modal
          onClose={() => {
            setIsReceivePaymentFormOpen(false);
            fetchReserves(); // Fetch reserves when the modal is closed
          }}
        >
          <ReceivePaymentForm
            reserve={selectedReserve}
            payment={selectedPayment}
            onClose={() => {
              setIsReceivePaymentFormOpen(false);
              fetchReserves(); // Fetch reserves when the form's onClose is triggered
            }}
            onSave={(updatedPayment) => {
              setReserves((prevReserves) =>
                prevReserves.map((reserve) =>
                  reserve.id === selectedReserve.id
                    ? {
                      ...reserve,
                      payments: reserve.payments.map((payment) =>
                        payment._id === updatedPayment._id
                          ? updatedPayment
                          : payment
                      ),
                      lossPaid: reserve.payments.reduce(
                        (sum, payment) =>
                          payment._id === updatedPayment._id
                            ? sum + updatedPayment.amountPaid
                            : sum + (payment.amountPaid || 0),
                        0
                      ),
                    }
                    : reserve
                )
              );
              setIsReceivePaymentFormOpen(false);
              fetchReserves(); // Ensure reserves are re-fetched after save
            }}
          />
        </Modal>
      )}

      <button
        style={{
          marginTop: "10px",
          backgroundColor: "#FF6347",
          color: "white",
          width: "150px",
          border: "none",
          padding: "10px 20px",
          borderRadius: "5px",
          cursor: "pointer",
        }}
        onClick={clearReserves}
      >
        Clear Reserves
      </button>
    </div>
  );
};

export default ClaimReservesPage;
