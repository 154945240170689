import React, { useState } from "react";
import { useAuth } from "../../context/auth";
import History from "../../pages/admin/history";
import Locations from "../../pages/admin/locations";
import Permissions from "../../pages/admin/permissions";
import Roles from "../../pages/admin/roles";
import SystemFields from "../../pages/admin/systemFeilds";
import Templates from "../../pages/admin/templates";
import Users from "../../pages/admin/users";

// Mapping admin sections to their respective components
const adminComponents = {
    locations: <Locations />,
    roles: <Roles />,
    permissions: <Permissions />,
    templates: <Templates />,
    users: <Users />,
    history: <History />,
    systemFields: <SystemFields />,
};

// Sidebar component for navigation
const Sidebar = ({ selectedAdmin, onSelect }) => {
    return (
        <div style={sidebarStyle}>
            <h3>Admin</h3>
            <ul style={listStyle}>
                {Object.keys(adminComponents).map((section) => (
                    <li
                        key={section}
                        style={{
                            ...itemStyle,
                            background: selectedAdmin === section ? "#ddd" : "transparent",
                        }}
                        onClick={() => onSelect(section)}
                    >
                        {section.charAt(0).toUpperCase() + section.slice(1).replace(/([A-Z])/g, " $1")}
                    </li>
                ))}
            </ul>
        </div>
    );
};

// Main admin management component
const ManageAdmins = () => {
    const [auth] = useAuth();
    const [selectedAdmin, setSelectedAdmin] = useState("locations");

    return (
        <div style={{ display: "flex", height: "100vh" }}>
            <Sidebar selectedAdmin={selectedAdmin} onSelect={setSelectedAdmin} />
            <div style={contentStyle}>
                {adminComponents[selectedAdmin] || <div>Select an admin section to display</div>}
            </div>
        </div>
    );
};

// Inline styles for sidebar and layout
const sidebarStyle = {
    marginLeft: "13%",
    background: "#f4f4f4",
    padding: "20px",
    position: "fixed",
    height: "100%",
    width: "250px",
};

const listStyle = {
    listStyleType: "none",
    padding: 0,
};

const itemStyle = {
    padding: "10px",
    cursor: "pointer",
};

const contentStyle = {
    flex: 1,
    padding: "20px",
    marginLeft: "13%",
};

export default ManageAdmins;
