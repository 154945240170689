// Frontend Modal Component
import React, { useState } from "react";
import axios from "axios";
import styled from "styled-components";
import toast from "react-hot-toast";
import { useAuth } from "../../context/auth";

const ModalOverlay = styled.div`
  display: ${({ show }) => (show ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const Modal = styled.div`
  display: ${({ show }) => (show ? "block" : "none")};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1000;
  width: 50%;
  max-width: 600px;
`;

const Button = styled.button`
  background-color: #6495ed;
  color: white;
  border: none;
  height: auto;
  border-radius: 4px;
  padding: 10px 12px;
  cursor: pointer;
  &:hover {
    background-color: #6570ed;
  }
`;

const CSVImportModal = ({ show, onClose, claimId }) => {
  const [file, setFile] = useState(null);
  const [importSummary, setImportSummary] = useState(null);
  const [auth] = useAuth();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleImport = async () => {
    if (!file) {
      toast.error("Please select a file before importing.");
      return;
    }
  
    const formData = new FormData();
    formData.append("file", file);
  
    try {
      const response = await axios.post(`/api/v1/importTimeExpenses?claimId=${claimId}`, formData, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
  
      if (response.data.success) {
        setImportSummary(response.data.summary);
        toast.success("Import completed successfully.");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message || "An error occurred during the import process.");
      } else {
        toast.error("An error occurred during the import process.");
      }
      console.error(error);
    }
  };
  
  

  return (
    <>
      <ModalOverlay show={show} onClick={onClose} />
      <Modal show={show}>
        <h2>Import Time and Expenses</h2>
        <input type="file" accept=".csv" onChange={handleFileChange} />
        <div style={{ marginTop: "20px" }}>
          <Button onClick={handleImport}>Import</Button>
          <Button style={{ marginLeft: "10px" }} onClick={onClose}>
            Close
          </Button>
        </div>

        {importSummary && (
          <div style={{ marginTop: "20px" }}>
            <h3>Import Summary</h3>
            <p>Successfully Imported: {importSummary.successCount}</p>
            <p>Rejected Rows:</p>
            <ul>
              {importSummary.rejectedRows.map((row, index) => (
                <li key={index}>{row.error}</li>
              ))}
            </ul>
          </div>
        )}
      </Modal>
    </>
  );
};

export default CSVImportModal;
