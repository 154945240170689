import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import styled from "styled-components";
import { useAuth } from "../../../context/auth";

const BtnContainer = styled.div`
  margin: 30px;
  display: flex;
  justify-content: space-between;
`;

const BrokerSelector = ({ formData, setFormData, setStep }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [brokers, setBrokers] = useState([]); // Updated to an array
  const [selectedBroker, setSelectedBroker] = useState(null);
  const [auth] = useAuth();

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (searchQuery) {
      axios
        .get(`/api/v1/brokers/search/${searchQuery}?includeContacts=false`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        })
        .then((response) => {
          setBrokers(response.data.brokers || []); // Store all matching brokers
        })
        .catch((error) =>
          toast.error(error?.response?.data?.message || "Error during search")
        );
    } else {
      setBrokers([]); // Clear results if query is empty
    }
  }, [searchQuery, formData.brokers]);

  const handleAddBroker = () => {
    if (selectedBroker) {
      setFormData({
        ...formData,
        brokerId: selectedBroker,
      });
      setSelectedBroker(null);
    }
  };

  const handleRemoveBroker = () => {
    setFormData({
      ...formData,
      brokerId: "",
    });
  };

  return (
    <div>
      <h2>Add Broker</h2>
      <TextField
        label="Search Broker"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        fullWidth
      />
      {brokers.length > 0 && (
        <List>
          {brokers.map((broker) => (
            <ListItem
              key={broker.id}
              button
              onClick={() => setSelectedBroker(broker)}
            >
              <ListItemText primary={broker.name} />
            </ListItem>
          ))}
        </List>
      )}
      <Dialog open={!!selectedBroker} onClose={() => setSelectedBroker(null)}>
        <DialogTitle>Add Broker</DialogTitle>
        <DialogContent>
          {selectedBroker && <Typography>{selectedBroker.name}</Typography>}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSelectedBroker(null)}>Cancel</Button>
          <Button onClick={handleAddBroker} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <List>
        {formData.brokerId ? (
          <ListItem>
            <ListItemText primary={`Broker: ${formData.brokerId.name}`} />
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={handleRemoveBroker}
            >
              <DeleteIcon />
            </IconButton>
          </ListItem>
        ) : (
          <>No broker selected</>
        )}
      </List>
      {errors.brokers && <div>{errors.brokers}</div>}
    </div>
  );
};

export default BrokerSelector;
