
import React from 'react';

const ClientsIcon = () => {
  return (
    <div>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="https:www.w3.org/2000/svg">
            <circle cx="10" cy="5.00008" r="3.33333" fill="white"/>
            <ellipse cx="10" cy="14.1666" rx="5.83333" ry="3.33333" fill="white"/>
        </svg>
    </div>
  );
};

export default ClientsIcon;