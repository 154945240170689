import 'bootstrap/dist/css/bootstrap.css';
import { Toaster } from "react-hot-toast";
import { AuthProvider } from "./context/auth";
import Router from "./routes";

export default function App() {
  return (
    <div>
      <AuthProvider>
        <Toaster position="top-center" reverseOrder={false} />
        <Router />
      </AuthProvider>
    </div>
  );
}
