import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import toast from "react-hot-toast";
import { useAuth } from "../../../context/auth";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

const Btn = styled.button`
  background-color: #6495ed;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 12px;
  cursor: pointer;
  margin-left: 20px;
  height: 40px;
`;

const Field = styled.div`
  margin: 10px;
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  padding: 10px;
  align-self: flex-start;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  outline: none;
`;

const CreateLossTypeForm = ({ isOpen, onClose, setLossTypes }) => {
  const [auth] = useAuth();
  const [formData, setFormData] = useState({
    description: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "/api/v1/loss-types",
        { formData },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );

      const newLossType = response.data;
      setLossTypes((prevLossTypes) => [...prevLossTypes, newLossType]);
      // Clear input field and show success message
      setFormData({ description: "" });
      toast.success("Loss type created successfully");
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        const status = error.response.status;
        if (status === 400) {
          toast.error(error.response.data.message);
        } else if (status === 409) {
          toast.error("Loss type already exists");
        } else {
          toast.error("An error occurred");
        }
      } else if (error.request) {
        // The request was made but no response was received
        toast.error("No response received from the server");
      } else {
        // Something happened in setting up the request that triggered an error
        toast.error("Error setting up the request");
      }
    }
  };

  if (!isOpen) {
    return null;
  }
  return (
    <ModalOverlay>
      <ModalContent>
        <h2>Add New Loss Type</h2>
        <form onSubmit={handleSubmit}>
          <Field>
            <Label>Enter description</Label>
            <Input
              type="text"
              name="description"
              value={formData.description}
              onChange={handleChange}
            />
          </Field>
          <div>
            <Btn type="submit">Submit</Btn>
            <Btn onClick={onClose}>Cancel</Btn>
          </div>
        </form>
      </ModalContent>
    </ModalOverlay>
  );
};

export default CreateLossTypeForm;
