import React from "react";
import styled from "styled-components";
import Button from "../../../components/common/Button";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Input = styled.input`
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Label = styled.label`
  margin: 5px 0;
`;

const AddAutomationForm = ({ formData, setFormData, handleSubmit, title }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,  
    }));
  };

  const resetForm = () => {
    setFormData({
      dueDate: "",
      description: "",
      status: "",
      action: "",
    });
  };
  console.log("formmmmmm", formData);
  return (
    <Form onSubmit={(e) => handleSubmit(e, resetForm)}>
      <h2>{title}</h2>

      <Label>Action</Label>
      <Input
        type="text"
        name="action"
        value={formData.action || ""}
        onChange={handleChange}
        required
      />

      <Label>Description</Label>
      <Input
        type="text"
        name="description"
        value={formData.description || ""}
        onChange={handleChange}
        required
      />

      <Label>Due Date</Label>
      <Input
        type="date"
        name="dueDate"
        value={formData.dueDate || ""}
        onChange={handleChange}
        required
      />

      <Label>Status</Label>
      <select
        className="py-2 my-2"
        name="status"
        value={
          formData.status === "true"
            ? "true"
            : formData.status === "false"
            ? "false"
            : ""
        }
        onChange={handleChange}
        required
      >
        <option value="">Select Status</option>
        <option value="true">True</option>
        <option value="false">False</option>
      </select>

      <Button type="submit">Submit</Button>
    </Form>
  );
};

export default AddAutomationForm;
