import { Dropdown, Menu, Table } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import jsPDF from "jspdf";
import "jspdf-autotable";
import React, { memo, useCallback, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import toast from "react-hot-toast";
import { FaEllipsisV } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useAuth } from "../../context/auth";
import CSVImportModal from "./ImportTimeExpenses.js";

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  height: auto;
  padding: 5px;
  display: flex;
  width: 100%;
`;

const HeaderContainerItem = styled.div`
  height: auto;
  display: flex;
  width: 100%;
`;

const HeaderContainerItem2 = styled.div`
  display: flex;
  justify-content: flex-end; /* Push items to the right */
  align-items: center; /* Align vertically */
  width: 100%;
`;

const Btn = styled.button`
  background-color: #6495ed;
  color: white;
  border: none;
  height: auto;
  border-radius: 4px;
  padding: 10px 12px;
  cursor: pointer;
  margin-left: 20px;
  &:hover { background-color: #6570ed;
}
`;

const InvoiceButton = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 10px 12px;
  border: none;
  height: auto;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px; /* Add space between Invoice and Reverse Invoice buttons */
  &:hover {
    background-color: #218838;
  }
`;

const ReverseInvoiceButton = styled.button`
  background-color: #f44336;
  color: white;
  padding: 10px 12px;
  border: none;
  height: auto;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
  background-color: #d32f2f;
}
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px 4px 0 0;
  width: 100%;
  scrollbar-width: thin; /* Adjust scrollbar width for supported browsers */
  -ms-overflow-style: none; /* Hide scrollbar for IE/Edge */
  @media only screen and (max-width: 768px) {
    scrollbar-width: none; /* Hide scrollbar on smaller screens */
  }
`;

const Modal = styled.div`
  display: ${({ show }) => (show ? "block" : "none")};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px; /* Slightly larger radius for aesthetics */
  z-index: 1000;
  width: 50%; /* Increased width */
  max-width: 600px; /* Limit maximum width */
  max-height: 90%; /* Limit height to fit the screen */
  overflow-y: auto; /* Allow scrolling for content that exceeds modal height */

  @media (max-width: 768px) {
    width: 90%; /* Full width for smaller screens */
    padding: 15px; /* Adjust padding for smaller screens */
  }
`;

const ModalOverlay = styled.div`
  display: ${({ show }) => (show ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;



const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px; /* Add space between elements */
`;

const FormButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end; /* Align buttons to the right */
  gap: 10px; /* Add space between buttons */
`;


const FormField = styled.div`
  margin-bottom: 10px;
`;

const FormLabel = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
`;

const FormInput = styled.input`
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 4px;

  @media (max-width: 768px) {
    padding: 6px; /* Reduce padding for smaller screens */
  }
`;

const FormButton = styled.button`
  background-color: #6495ed;
  color: white;
  border: none;
  height: auto;
  border-radius: 4px;
  padding: 10px 12px;
  cursor: pointer;
`;

const CancelButton = styled.button`
  background-color: #ccc;
  color: black;
  border: none;
  height: auto;
  border-radius: 4px;
  padding: 10px 12px;
  cursor: pointer;
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 4px;

  @media (max-width: 768px) {
    padding: 6px; /* Reduce padding */
    height: 80px; /* Adjust height */
  }
`;

const Value = styled.div`
  margin-left: 20px;
`;

const TimeExpenses = memo(
  ({ mileageRate, rate, claimId, userCode, cityAuto, claimData }) => {
    const [auth] = useAuth();
    const [users, setUsers] = useState([]);
    const [timeExpenses, setTimeExpenses] = useState([]);
    const [codes, setCodes] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const [searchQuery, setSearchQuery] = useState(""); // For filtering activity codes
    const [taskData, setTaskData] = useState(null);
    const [isTaskDateModalVisible, setIsTaskDateModalVisible] = useState(false); // State to control the modal visibility
    const [taskFollowUpDate, setTaskFollowUpDate] = useState(null);
    const [taskFollowUpDescription, setTaskFollowUpDescription] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);


    const openModal = () =>
      setIsModalOpen(true);
    const closeModal1 = () => setIsModalOpen(false);
    const [formData, setFormData] = useState({
      activity_date: new Date(),
      activity_code: "",
      populated_description: "", // For the auto-filled part
      additional_description: "", // For user-added content
      user: auth?.user?.user_code,
      rate: rate,
      time: "",
      expense: "0.00",
    });

    const [openDropdown, setOpenDropdown] = useState(null);
    const [invoices, setInvoices] = useState([]);
    const hasAccessToAutomation =
      auth?.user?.role_id?.name?.toLowerCase() === "manager" ||
      auth?.user?.role_id?.name?.toLowerCase() === "office admin" ||
      auth?.user?.role_id?.name?.toLowerCase() === "supervisor";
    const totalPages = Math.ceil(timeExpenses.length / rowsPerPage);

    const handlePageChange = (newPage) => {
      if (newPage >= 1 && newPage <= totalPages) {
        setCurrentPage(newPage);
      }
    };

    const handleRowsPerPageChange = (event) => {
      setRowsPerPage(Number(event.target.value));
      setCurrentPage(1); // Reset to the first page when rows per page changes
    };

    const displayedExpenses = timeExpenses.slice(
      (currentPage - 1) * rowsPerPage,
      currentPage * rowsPerPage
    );

    const toggleDropdown = useCallback(
      (index) => {
        setOpenDropdown(openDropdown === index ? null : index);
      },
      [openDropdown]
    );

    const defaultMileageRate = mileageRate;
    const defaultCityAuto = cityAuto;
    const claimRate = rate;

    useEffect(() => {
      fetchUsers();
      fetchTimeExpenses();
      fetchActivityCodes();
      fetchInvoices();
    }, [currentPage]);

    const handleDeleteClick = async (id) => {
      try {
        setIsLoading(true);
        const { data } = await axios.delete(`/api/v1/deleteTimeExpense/${id}`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        });
        if (data.success) {
          fetchTimeExpenses();
          toast.success("Time and expense deleted successfully");
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        toast.error(
          "Error deleting the time and expense. Please try again later."
        );
        console.error("Deletion error:", error);
      } finally {
        setIsLoading(false);
      }
    };
    const closeModal = (resetForm = true) => {
      setShowModal(false); // This closes the modal
      setEditIndex(null);

      if (resetForm) {
        setFormData({
          activity_date: new Date(),
          activity_code: "",
          populated_description: "",
          additional_description: "",
          user: auth?.user?.user_code,
          rate: rate,
          time: "",
          expense: "0.00",
        });
      }
    };

    // Update the activity code or show search suggestions
    const handleActivityCodeChange = (value) => {
      const activityCode = value.toUpperCase(); // Ensure uppercase
      setFormData((prevFormData) => ({
        ...prevFormData,
        activity_code: activityCode,
      }));

      const matchedCode = codes.find(
        (code) => code.code.toUpperCase() === activityCode
      );

      if (matchedCode) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          populated_description: matchedCode.description,
          additional_description: "",
        }));
        setSearchQuery(""); // Clear the search query
        setErrors((prevErrors) => {
          const { activity_code, ...rest } = prevErrors;
          return rest; // Remove activity_code error
        });

        // Handle specific logic for "FE"
        if (activityCode === "FE") {
          setFormData((prevFormData) => ({
            ...prevFormData,
            user: "SYS", // Automatically set user code to "SYS"
            time: "0.00", // Reset time to zero
            expense: "0.00", // Reset expense to zero, // Optional default description
          }));
        } else if (activityCode === "AU") {
          setFormData((prevFormData) => ({
            ...prevFormData,
            expense: defaultCityAuto, // Fixed expense for AU
          }));
        } else if (activityCode === "AR") {
          const kilometers = prompt("How many Kilometers Traveled?");
          if (kilometers && !isNaN(kilometers)) {
            let expense = (kilometers * defaultMileageRate).toFixed(2);
            if (parseFloat(expense) < defaultCityAuto) {
              expense = defaultCityAuto; // Minimum expense for AR
            }
            setFormData((prevFormData) => ({
              ...prevFormData,
              expense: expense,
              populated_description: `Travel ${kilometers} KM: ${prevFormData.additional_description}`,
            }));
          } else {
            toast.error("Invalid input for kilometers.");
          }
        }
      } else {
        setSearchQuery(value);
        setFormData((prevFormData) => ({
          ...prevFormData,
          populated_description: "",
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          activity_code: "Invalid activity code. Please select a valid code.",
        }));
      }
    };

    // Filtered activity codes based on search query
    const filteredCodes = codes.filter(
      (code) =>
        code.code.toLowerCase().includes(searchQuery.toLowerCase()) ||
        code.description.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Handle selection of an activity code
    const handleSelectCode = (code) => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        activity_code: code.code,
        populated_description: code.description,
      }));
      setSearchQuery(""); // Clear search query

      setErrors((prevErrors) => {
        const { activity_code, ...rest } = prevErrors;
        return rest; // Clear activity_code error
      });

      // Handle specific activity code logic
      if (code.code === "AU") {
        setFormData((prevFormData) => ({
          ...prevFormData,
          expense: defaultCityAuto, // Fixed expense for AU
        }));
      } else if (code.code === "AR") {
        const kilometers = prompt("How many Kilometers Traveled?");
        if (kilometers && !isNaN(kilometers)) {
          let expense = (kilometers * defaultMileageRate).toFixed(2);
          if (parseFloat(expense) < 50.0) {
            expense = "50.00"; // Minimum expense for AR
          }
          setFormData((prevFormData) => ({
            ...prevFormData,
            expense: expense,
            additional_description: `Travel ${kilometers} KM - ${prevFormData.additional_description}`,
          }));
        } else {
          toast.error("Invalid input for kilometers.");
        }
      }
    };

    const handleEditClick = useCallback((row) => {
      setFormData({
        activity_date: row.activity_Date
          ? new Date(row.activity_Date)
          : new Date(), // Ensure it's converted to Date object
        activity_code: row.activity_Code || "",
        populated_description: row.description || "",
        additional_description: "", // Keep additional description blank for edits
        user: row.userId?.user_code || "",
        rate: parseFloat(row.rate) || 0,
        time: parseFloat(row.time) || 0,
        expense: parseFloat(row.expense) || "0.00",
      });
      setEditIndex(row?._id); // Save the ID for updating
      setShowModal(true); // Open the modal
    }, []);

    const handleInputChange = (e) => {
      const { name, value } = e.target;

      // Prevent invalid values for numeric fields
      if (
        (name === "rate" || name === "time" || name === "expense") &&
        (isNaN(value) || value < 0)
      ) {
        toast.error("Please enter a valid positive numeric value.");
        return;
      }

      setFormData((prevFormData) => {
        const updatedData = {
          ...prevFormData,
          [name]: name === "activity_code" ? value.toUpperCase() : value, // Convert activity_code to uppercase
        };

        if (name === "activity_code") {
          const activityCode = value.toUpperCase();

          // Restrict activity_code to a maximum of 2 characters
          if (activityCode.length > 2) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              activity_code: "Activity code cannot exceed 2 characters.",
            }));
            return prevFormData; // Prevent updating the state
          }

          // Validate activity code
          const validCode = codes.some(
            (code) => code.code.toLowerCase() === activityCode.toLowerCase()
          );

          if (!validCode) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              activity_code:
                "Invalid activity code. Please enter a valid code.",
            }));
          } else {
            setErrors((prevErrors) => {
              const { activity_code, ...rest } = prevErrors;
              return rest; // Remove activity_code error
            });

            // Handle specific activity code logic
            if (activityCode === "AU") {
              updatedData.expense = defaultCityAuto;
            } else if (activityCode === "AR") {
              const kilometers = prompt("How many Kilometers Traveled?");
              if (kilometers && !isNaN(kilometers)) {
                let expense = (kilometers * defaultMileageRate).toFixed(2);
                if (parseFloat(expense) < 50.0) {
                  expense = "50.00";
                }
                updatedData.expense = expense;
                updatedData.description = `Travel ${kilometers} KM - ${prevFormData.description}`;
              } else {
                toast.error("Invalid input for kilometers.");
              }
            } else if (activityCode === "FE") {
              // Automatically set user to SYS
              const sysUser = users.find((user) => user.user_code === "SYS");
              if (sysUser) {
                updatedData.user = sysUser._id; // Assign SYS user ID
              } else {
                toast.error("SYS user not found.");
              }
              updatedData.expense = "0.00"; // Reset expense
              updatedData.time = "0.00"; // Lock time to 0
            } else if (activityCode === "LP") {
              // Calculate expense as time * 3
              const time = parseFloat(prevFormData.time || 0);
              updatedData.expense = (time * 30).toFixed(2);
            } else {
              updatedData.expense = "0.00";
            }

            // Populate description based on valid activity code
            const selectedCode = codes.find(
              (item) => item.code.toLowerCase() === activityCode.toLowerCase()
            );
            if (selectedCode) {
              updatedData.description = selectedCode.description;
            }
          }
        }

        // Automatically calculate expense for LP when time changes
        if (
          name === "time" &&
          prevFormData.activity_code?.toUpperCase() === "LP"
        ) {
          updatedData.expense = (parseFloat(value) * 30).toFixed(2);
        }

        return updatedData;
      });
    };

    const fetchUsers = async () => {
      try {
        setIsLoading(true);
        const { data } = await axios.get("/api/v1/users", {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        });
        if (data.success) {
          setUsers(data.users);
        } else {
          toast.error("Failed to load users.");
        }
      } catch (error) {
        toast.error(
          "Error fetching users. Please check your network connection."
        );
        console.error("Fetch users error:", error);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchActivityCodes = async () => {
      try {
        const { data } = await axios.get("/api/v1/activity-codes", {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        });
        setCodes(data);
      } catch (error) {
        toast.error("Error fetching activity codes. Please try again later.");
        console.error("Fetch activity codes error:", error);
      }
    };

    const fetchTimeExpenses = async () => {
      try {
        setIsLoading(true);
        const { data } = await axios.get(
          `/api/v1/getAllTimeExpenses?claim_id=${claimId}&page=${currentPage}&limit=${rowsPerPage}`,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          }
        );

        if (data.success) {
          // Filter out entries marked as invoiced
          const nonInvoicedExpenses = data.expenses.filter(
            (expense) => !expense.invoiceId // Ensure this field matches your API response
          );

          // Sort by activity date (including time) in descending order
          const sortedExpenses = nonInvoicedExpenses.sort((a, b) => {
            const dateA = new Date(a.activity_Date);
            const dateB = new Date(b.activity_Date);

            // Use getTime() to ensure both date and time are considered
            return dateB.getTime() - dateA.getTime();
          });

          setTimeExpenses(sortedExpenses); // Update the state with sorted, non-invoiced entries
        } else {
          toast.error("Failed to load time and expense data.");
        }
      } catch (error) {
        toast.error(
          "Error fetching time expenses. Please check your network connection."
        );
        console.error("Fetch time expenses error:", error);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchInvoices = async () => {
      try {
        const { data } = await axios.get(`/api/v1/getInvoices?claim_id=${claimId}`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        });
        if (data.success) {
          setInvoices(data.invoices); // Invoices include userBreakdown but are not rendered
        } else {
          toast.error("Failed to load invoices.");
        }
      } catch (error) {
        toast.error("Error fetching invoices. Please try again later.");
      }
    };


    const handleInvoiceEntries = async () => {
      const confirmed = window.confirm(
        "Are you sure you want to invoice these entries?"
      );
      if (!confirmed) return;

      try {
        const { data } = await axios.get("/api/v1/getNextInvoiceNumber", {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        });

        if (data.success) {
          const invoiceDate = new Date().toISOString();
          const invoiceNum = data.nextInvoiceNumber;

          // Filter invoicable entries
          const invoicableEntries = timeExpenses.filter(
            (expense) => expense.timeExpensestatus
          );
          if (invoicableEntries.length === 0) {
            toast.error("No entries available for invoicing.");
            return;
          }

          // Send invoicing request
          const response = await axios.post(
            "/api/v1/createInvoice",
            {
              claimId,
              invoiceDate,
              invoiceNumber: invoiceNum,
              entries: invoicableEntries,
            },
            {
              headers: {
                Authorization: `Bearer ${auth?.token}`,
              },
            }
          );

          if (response.data.success) {
            const refNumber = invoiceNum;
            const pdfBlob = generatePDF(refNumber, invoicableEntries);
            const fileNumber = claimData.fileNumber;
            // Create form data for upload
            const formData = new FormData();
            formData.append("files", pdfBlob, `${fileNumber}-Time & Expenses.pdf`);
            formData.append("folder", "Invoices");

            // Upload the PDF to the server
            await axios.post(
              `/api/v1/claims/${fileNumber}/documents/uploadInvoice`,
              formData,
              {
                headers: {
                  Authorization: `Bearer ${auth?.token}`,
                  "Content-Type": "multipart/form-data",
                },
              }
            );

            toast.success("Invoiced successfully. PDF uploaded to documents.");
            fetchInvoices();
            fetchTimeExpenses();
          } else {
            toast.error(response.data.message);
          }
        } else {
          toast.error("Failed to fetch the next invoice number.");
        }
      } catch (error) {
        toast.error("Error invoicing entries. Please try again.");
        console.error("Invoice error:", error);
      }
    };
    // Function to generate PDF
    const generatePDF = (refNumber, invoicableEntries) => {
      const doc = new jsPDF();

      // Header Section
      doc.setFontSize(16);
      doc.setTextColor(40);
      doc.text("Time & Expenses", 14, 15);
      doc.setFontSize(10);
      doc.text("Invoice Date:", 14, 25);
      doc.text(new Date().toLocaleDateString("en-CA"), 45, 25);
      doc.text("Ref Number:", 14, 30);
      doc.text(refNumber, 45, 30);

      const clients = claimData.clients || []; // Ensure clients is an array

      doc.setFontSize(10);


      // Table Header and Data Section
      const MAX_CHARACTERS = 250; // Define the maximum number of characters per cell

      doc.autoTable({
        startY: 35,
        head: [
          [
            "Activity Date",
            "Activity Code",
            "Description",
            "User",
            "Rate",
            "Time",
            "Expense",
            "Line Total",
          ],
        ],
        body: invoicableEntries.map((entry) => [
          truncateText(
            entry.activity_Date
              ? new Date(entry.activity_Date).toLocaleDateString("en-CA")
              : "N/A",
            MAX_CHARACTERS
          ),
          truncateText(entry.activity_Code || "N/A", MAX_CHARACTERS),
          truncateText(entry.description || "", MAX_CHARACTERS),
          truncateText(entry.userId?.user_code || "N/A", MAX_CHARACTERS),
          truncateText(parseFloat(entry.rate).toFixed(2) || "0.00", MAX_CHARACTERS),
          truncateText(parseFloat(entry.time).toFixed(1) || "0.0", MAX_CHARACTERS),
          truncateText(
            `$${parseFloat(entry.expense).toFixed(2) || "0.00"}`,
            MAX_CHARACTERS
          ),
          truncateText(
            `$${parseFloat(entry.line_total).toFixed(2) || "0.00"}`,
            MAX_CHARACTERS
          ),
        ]),
        theme: "striped",
        styles: { fontSize: 8 },
        headStyles: { fillColor: [224, 228, 243], textColor: [68, 68, 68] },
        columnStyles: {
          0: { cellWidth: 22 },
          1: { cellWidth: 18 },
          2: { cellWidth: 50 },
          3: { cellWidth: 18 },
          4: { cellWidth: 15 },
          5: { cellWidth: 15 },
          6: { cellWidth: 18 },
          7: { cellWidth: 20 },
        },
      });

      /**
       * Truncates text to the specified maximum length and adds ellipses if truncated.
       * @param {string} text - The text to truncate.
       * @param {number} maxLength - The maximum number of characters to display.
       * @returns {string} - The truncated text.
       */
      function truncateText(text, maxLength) {
        if (text.length > maxLength) {
          return text.substring(0, maxLength - 3) + "..."; // Add ellipses for truncated text
        }
        return text;
      }


      // Totals Section
      const finalY = doc.lastAutoTable.finalY + 5;
      const totals = invoicableEntries.reduce(
        (acc, entry) => {
          acc.timeTotal += parseFloat(entry.time);
          acc.expenseTotal += parseFloat(entry.expense);
          acc.lineTotal += parseFloat(entry.line_total);
          return acc;
        },
        { timeTotal: 0, expenseTotal: 0, lineTotal: 0 }
      );

      doc.text("Summary of Totals", 14, finalY + 5);
      doc.autoTable({
        startY: finalY + 10,
        head: [["Total Time", "Total Expense", "Total Amount"]],
        body: [
          [
            totals.timeTotal.toFixed(1),
            `$${totals.expenseTotal.toFixed(2)}`,
            `$${totals.lineTotal.toFixed(2)}`,
          ],
        ],
        theme: "plain",
        styles: { fontSize: 8 },
        headStyles: { textColor: [68, 68, 68], fontStyle: "bold" },
        bodyStyles: { textColor: [159, 162, 180] },
      });

      // Breakdown by User Section
      const userTotals = invoicableEntries.reduce((acc, entry) => {
        const userCode = entry.userId?.user_code || "N/A";
        if (!acc[userCode]) {
          acc[userCode] = { time: 0, expense: 0, lineTotal: 0 };
        }
        acc[userCode].time += parseFloat(entry.time || 0);
        acc[userCode].expense += parseFloat(entry.expense || 0);
        acc[userCode].lineTotal += parseFloat(entry.line_total || 0);
        return acc;
      }, {});

      const breakdownStartY = doc.lastAutoTable.finalY + 10;
      doc.text("Breakdown by User", 14, breakdownStartY);
      const breakdownData = Object.entries(userTotals).map(([userCode, totals]) => [
        userCode,
        totals.time.toFixed(2),
        `$${totals.expense.toFixed(2)}`,
        `$${totals.lineTotal.toFixed(2)}`,
      ]);

      doc.autoTable({
        startY: breakdownStartY + 5,
        head: [["User", "Total Time", "Expense Total", "Total Line Amount"]],
        body: breakdownData,
        theme: "plain",
        styles: { fontSize: 8 },
        headStyles: { textColor: [68, 68, 68], fontStyle: "bold" },
        bodyStyles: { textColor: [159, 162, 180] },
      });

      // Client Breakdown Section
      const clientBreakdownStartY = doc.lastAutoTable.finalY + 10;
      doc.text("Client Breakdown", 14, clientBreakdownStartY);
      const clientBreakdownData = clients.map((clientWrapper) => {
        const clientName = clientWrapper.clientResolved?.name || "";
        const clientClaimNumber = clientWrapper.claimNumber || "N/A";
        const clientShare = (clientWrapper.sharePercentage || 0).toFixed(2);
        const clientTotal = (
          (parseFloat(clientWrapper.sharePercentage || 0) / 100) *
          totals.lineTotal
        ).toFixed(2);

        return [clientName, clientClaimNumber, `${clientShare}%`, `$${clientTotal}`];
      });

      doc.autoTable({
        startY: clientBreakdownStartY + 5,
        head: [["Client", "Claim Number", "Share Percentage", "Amount Owed"]],
        body: clientBreakdownData,
        theme: "plain",
        styles: { fontSize: 8 },
      });

      // Footer Section
      doc.setFontSize(8);
      doc.text(
        "Thank you for your business.",
        14,
        doc.lastAutoTable.finalY + 10
      );
      const fileNumber = claimData.fileNumber;
      // Save PDF
      doc.output(`${fileNumber}-Time&Expenes.pdf`);
      return doc.output("blob");
    };



    const handleReverseInvoice = async () => {
      if (invoices.length === 0) {
        toast.error("No invoices available to reverse.");
        return;
      }

      const selectedInvoiceNumber = prompt(
        `Enter the invoice number to reverse:\n${invoices
          .map(
            (inv) =>
              `Number: ${inv.invoiceNumber} - Date: ${new Date(
                inv.date
              ).toLocaleDateString()}`
          )
          .join("\n")}`
      );

      if (!selectedInvoiceNumber) {
        toast.error("Invalid selection. Please enter a valid invoice number.");
        return;
      }

      const selectedInvoice = invoices.find(
        (inv) => inv.invoiceNumber === selectedInvoiceNumber
      );
      if (!selectedInvoice) {
        toast.error("No invoice found with that number.");
        return;
      }

      try {
        const response = await axios.post(
          `/api/v1/reverseInvoice`,
          { invoiceNumber: selectedInvoice.invoiceNumber },
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          }
        );

        if (response.data.success) {
          // Mark the invoice as reversed
          selectedInvoice.isReversed = true;

          // Update the list of invoices to exclude reversed ones
          setInvoices((prevInvoices) =>
            prevInvoices.filter((inv) => !inv.isReversed)
          );
          toast.success("Invoice reversed successfully.");
          fetchTimeExpenses(); // Update time and expense list
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("Error reversing the invoice. Please try again.");
      }
    };
    const filteredUsers = users.filter(
      (user) =>
        user.user_code.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const [navigateTriggered, setNavigateTriggered] = useState(false);
    // Existing states and code...

    const handleSaveAndCreateTask = async () => {
      if (!taskFollowUpDate) {
        toast.error("Please select a follow-up date.");
        return;
      }

      try {
        await handleFormSubmit(null, "save");

        await axios.post(
          `/api/v1/createClaimTask`,
          {
            description: taskFollowUpDescription,
            claim_id: claimId,
            userCode,
            dueDate: dayjs(taskFollowUpDate).format("YYYY-MM-DD"),
          },
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          }
        );

        // Clear form data and reset follow-up date
        setFormData({
          description: "",
          dueDate: "",
          userCode: "auth.user.user_code",
          expense: "0.00",
          activity_code: "",
        });
        setTaskFollowUpDate(null);
        setIsTaskDateModalVisible(false); // Close the modal

        toast.success("Time and expense saved. Task created.");
        navigate("/claims/tasks", { replace: true, state: { taskData } });
      } catch (error) {
        toast.error("Failed to save time and expense or create the task.");
        console.error("Error in Save and Create Task:", error);
      }
    };

    // Function to handle "Save & Create Task" button click
    const showTaskDatePicker = () => {
      setIsTaskDateModalVisible(true);
    };

    useEffect(() => {
      if (navigateTriggered) {
        // Clear the form data after navigation
        setFormData({});
        setNavigateTriggered(false); // Reset the flag
      }
    }, [navigateTriggered]);

    const handleFormSubmit = async (e, action) => {
      if (e) e.preventDefault(); // Prevent default form action

      // Check for validation errors
      if (Object.keys(errors).length > 0) {
        toast.error("Please fix the errors before submitting.");
        return;
      }

      // Combine the description fields
      const combinedDescription =
        `${formData.populated_description} ${formData.additional_description}`.trim();

      try {
        // Create a note
        const noteData = {
          description: combinedDescription,
          claim_id: claimId,
          userCode: auth?.user?.user_code,
        };

        const noteResponse = await axios.post("/api/v1/createNotes", noteData, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        });

        if (!noteResponse.data.success) {
          toast.error(noteResponse.data.message);
          return; // Stop execution if note creation fails
        }
        // Find the userId from the user_code
        const selectedUser = users.find(
          (user) => user.user_code === formData.user
        );
        if (!selectedUser) {
          toast.error("Invalid user selected. Please try again.");
          return;
        }

        // Create or update time and expense entry
        const timeExpenseData = {
          activity_code: formData.activity_code,
          activity_date: formData.activity_date, // Retain the current activity_date
          userId: selectedUser._id, // Use userId from the selected user
          description: combinedDescription,
          expense: formData.expense,
          rate: formData.rate,
          time: formData.time,
          claim_id: claimId,
        };

        const headers = {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        };

        let response;
        if (editIndex === null) {
          // Create new entry
          response = await axios.post(
            "/api/v1/createTimeExpense",
            timeExpenseData,
            headers
          );
        } else {
          // Update existing entry
          response = await axios.put(
            "/api/v1/updateTimeExpense",
            { ...timeExpenseData, id: editIndex },
            headers
          );
        }

        if (response.data.success) {
          toast.success(
            `Time and expense ${editIndex === null ? "added" : "updated"
            } successfully.`
          );
          fetchTimeExpenses(); // Refresh data

          if (action === "save") {
            closeModal(); // Close modal for "Save"
          } else if (action === "saveNew") {
            // Reset other fields but retain activity_date for "Save & New"
            setFormData((prevFormData) => ({
              ...prevFormData,
              activity_code: "",
              populated_description: "",
              additional_description: "",
              rate: rate,
              time: "",
              expense: "0.00",
            }));
            setShowModal(true); // Keep modal open
          }
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.error("Error saving time and expense:", error);
        toast.error("Error saving time and expense. Please try again.");
      }
    };

    const total = timeExpenses.reduce(
      (acc, row) => {
        if (row.timeExpensestatus) {
          acc.time += Number(row.time);
          acc.expense += parseFloat(row.expense);
          acc.line_total += parseFloat(row.line_total);
        }
        return acc;
      },
      { time: 0, expense: 0, line_total: 0 }
    );

    const formatDate = (dateString) => {
      if (!dateString) return "";
      const [year, month, day] = dateString.split("T")[0].split("-");
      return `${year}-${month}-${day}`;
    };
    const columns = [
      {
        title: "Date Entered",
        dataIndex: "createdAt",
        key: "createdAt",
        onCell: () => ({ style: { padding: "5px 8px", textAlign: "left", width: "120px" } }),
        sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
        render: (text) =>
          new Intl.DateTimeFormat("en-CA", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          }).format(new Date(text)),
      },
      {
        title: "Activity Date",
        dataIndex: "activity_Date",
        key: "activity_Date",
        onCell: () => ({ style: { padding: "5px 8px", textAlign: "left", width: "120px" } }),
        sorter: (a, b) => {
          const dateA = new Date(a.activity_Date || 0);
          const dateB = new Date(b.activity_Date || 0);
          return dateA - dateB;
        },
        render: (text) => formatDate(text),
      },
      {
        title: "Activity",
        dataIndex: "activity_Code",
        key: "activity_Code",
        onCell: () => ({ style: { padding: "5px 8px", textAlign: "center", width: "100px" } }),
        sorter: (a, b) => (a.activity_Code || "").localeCompare(b.activity_Code || ""),
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        sorter: (a, b) => (a.description || "").localeCompare(b.description || ""),
      },
      {
        title: "User",
        dataIndex: ["userId", "user_code"],
        key: "user_code",
        onCell: () => ({ style: { padding: "5px 8px", textAlign: "center", width: "75px" } }),
        sorter: (a, b) =>
          ((a.userId?.user_code || "").localeCompare(b.userId?.user_code || "")),
      },
      {
        title: "Rate",
        dataIndex: "rate",
        key: "rate",
        onCell: () => ({ style: { padding: "5px 8px", textAlign: "center", width: "75px" } }),
        sorter: (a, b) => (a.rate || 0) - (b.rate || 0),
        render: (text) => `$${parseFloat(text).toFixed(2)}`,
      },
      {
        title: "Time",
        dataIndex: "time",
        key: "time",
        onCell: () => ({ style: { padding: "5px 8px", textAlign: "center", width: "75px" } }),
        sorter: (a, b) => (a.time || 0) - (b.time || 0),
        render: (text) => parseFloat(text).toFixed(1),
      },
      {
        title: "Expense",
        dataIndex: "expense",
        key: "expense",
        onCell: () => ({ style: { padding: "5px 8px", textAlign: "center", width: "100px" } }),
        sorter: (a, b) => (a.expense || 0) - (b.expense || 0),
        render: (text) => `$${parseFloat(text).toFixed(2)}`,
      },
      {
        title: "Line Total",
        dataIndex: "line_total",
        key: "line_total",
        onCell: () => ({ style: { padding: "5px 8px", textAlign: "center", width: "100px" } }),
        sorter: (a, b) => (a.line_total || 0) - (b.line_total || 0),
        render: (text) => `$${parseFloat(text).toFixed(2)}`,
      },
      {
        title: "Actions",
        key: "actions",
        onCell: () => ({ style: { padding: "5px 8px", textAlign: "center", width: "75px" } }),
        render: (_, record) => {
          const menu = (
            <Menu>
              <Menu.Item key="edit" onClick={() => handleEditClick(record)}>
                Edit
              </Menu.Item>
              <Menu.Item key="delete" onClick={() => handleDeleteClick(record._id)}>
                Delete
              </Menu.Item>
            </Menu>
          );

          return (
            <Dropdown overlay={menu} trigger={["click"]}>
              <FaEllipsisV style={{ cursor: "pointer" }} />
            </Dropdown>
          );
        },
      },
    ];

    return (
      <Container>
        <HeaderContainer>
          <HeaderContainerItem aria-label="Displayed hourly rate">
            Rate: ${claimRate}.00 (Blended)
          </HeaderContainerItem>
        </HeaderContainer>
        <HeaderContainer>
          <HeaderContainerItem>
            <Btn
              onClick={() => {
                setShowModal(true);
                setEditIndex(null);
              }}
              aria-label="Add new time and expense entry"
            >
              Add
            </Btn>
            {hasAccessToAutomation && (
              <Btn onClick={() => openModal(claimId)}>Import CSV</Btn>
            )}
            <CSVImportModal show={isModalOpen} onClose={closeModal1} claimId={claimData._id} />
          </HeaderContainerItem>
          <HeaderContainerItem2>
            {hasAccessToAutomation && (
              <InvoiceButton
                onClick={handleInvoiceEntries}
                aria-label="Invoice entries"
              >
                Invoice
              </InvoiceButton>)}
            {hasAccessToAutomation && (
              <ReverseInvoiceButton
                onClick={handleReverseInvoice}
                aria-label="Reverse invoice"
              >
                Reverse Invoice
              </ReverseInvoiceButton>)}
          </HeaderContainerItem2>
        </HeaderContainer>
        <ModalOverlay show={showModal} onClick={() => setShowModal(false)} />
        <Modal show={showModal} aria-labelledby="form-modal-title">
          <FormContainer>
            <form
              onSubmit={(e) => {
                if (editIndex === null) {
                  handleFormSubmit(e, "save");
                } else {
                  handleFormSubmit(e, "edit");
                }
              }}
            >
              <h2 id="form-modal-title">Time and Expense Entry Form</h2>
              <FormField>
                <FormLabel htmlFor="userCodeSearch">Search Users</FormLabel>
                <FormInput
                  type="text"
                  id="userCodeSearch"
                  placeholder="Search for a user..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  aria-label="Search user by code or name"
                  disabled={formData.activity_code?.toUpperCase() === "FE"} // Disable when activity_code is "FE"
                />
                {searchQuery &&
                  formData.activity_code?.toUpperCase() !== "FE" && (
                    <div
                      style={{
                        maxHeight: "150px",
                        overflowY: "auto",
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                        marginTop: "8px",
                        backgroundColor: "#fff",
                        padding: "8px",
                      }}
                    >
                      {filteredUsers.map((user) => (
                        <div
                          key={user._id}
                          style={{
                            padding: "8px",
                            cursor: "pointer",
                            borderBottom: "1px solid #f0f0f0",
                            fontSize: "14px",
                          }}
                          onClick={() => {
                            setFormData((prevFormData) => ({
                              ...prevFormData,
                              user: user.user_code, // Set user code instead of ID
                            }));
                            setSearchQuery("");
                            setErrors((prevErrors) => {
                              const { user, ...rest } = prevErrors;
                              return rest;
                            });
                          }}
                        >
                          {user.user_code} - {user.name}
                        </div>
                      ))}
                    </div>
                  )}
              </FormField>

              <div style={{ display: "flex", gap: "20px", alignItems: "flex-start" }}>
                <FormField style={{ flex: "1" }}>
                  <FormLabel htmlFor="userCode">User Code</FormLabel>
                  <FormInput
                    type="text"
                    id="userCode"
                    name="user"
                    placeholder="Enter user code"
                    value={formData.user}
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      const input = e.target.value.toUpperCase();
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        user: input,
                      }));

                      const userExists = users.some(
                        (user) => user.user_code === input
                      );

                      if (!userExists) {
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          user: "Invalid user code. Please select a valid user.",
                        }));
                      } else {
                        setErrors((prevErrors) => {
                          const { user, ...rest } = prevErrors;
                          return rest;
                        });
                      }
                    }}
                    disabled={formData.activity_code?.toUpperCase() === "FE"}
                    required
                    aria-required="true"
                  />
                  {formData.activity_code?.toUpperCase() === "FE" && (
                    <small style={{ color: "red" }}></small>
                  )}
                  {errors.user && (
                    <small style={{ color: "red" }}>{errors.user}</small>
                  )}
                </FormField>

                <FormField style={{ flex: "1" }}>
                  <FormLabel htmlFor="rate">Rate</FormLabel>
                  <FormInput
                    type="number"
                    id="rate"
                    name="rate"
                    value={formData.rate}
                    onChange={handleInputChange}
                    disabled={formData.activity_code?.toUpperCase() === "FE"}
                    required
                    aria-required="true"
                    style={{ width: "100%" }}
                  />
                </FormField>
              </div>

              <FormField>
                <FormLabel htmlFor="activity_date">Activity Date</FormLabel>
                <DatePicker
                  selected={formData.activity_date}
                  onChange={(date) =>
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      activity_date: date,
                    }))
                  }
                  maxDate={new Date()} // Prevents selecting a future date
                  id="activity_date"
                  aria-label="Select activity date"
                />
              </FormField>
              <FormField>
                <FormLabel htmlFor="activity_code">Activity Code</FormLabel>
                <FormInput
                  type="text"
                  id="activity_code"
                  name="activity_code"
                  value={formData.activity_code}
                  onChange={(e) => handleActivityCodeChange(e.target.value)}
                  required
                  aria-required="true"
                  placeholder="Enter activity code"
                />
                {errors.activity_code && (
                  <small style={{ color: "red" }}>{errors.activity_code}</small>
                )}
                {/* Search Suggestions */}
                {searchQuery && (
                  <div
                    className="reserve-page"
                    style={{
                      width: "100%" /* Ensure full width */,
                      display: "flex",
                      flexDirection: "column",
                      margin:
                        "0 auto" /* Optional: Center content horizontally */,
                    }}
                  >
                    {filteredCodes.map((code) => (
                      <div
                        key={code.code}
                        style={{
                          padding: "8px",
                          cursor: "pointer",
                          borderBottom: "1px solid #f0f0f0",
                          fontSize: "14px",
                        }}
                        onClick={() => handleSelectCode(code)}
                      >
                        <strong>{code.code}</strong>: {code.description}
                      </div>
                    ))}
                  </div>
                )}
              </FormField>

              <FormField>
                <FormLabel htmlFor="time">Time</FormLabel>
                <FormInput
                  type="number"
                  id="time"
                  name="time"
                  value={formData.time}
                  onChange={handleInputChange}
                  disabled={formData.activity_code?.toUpperCase() === "FE"}
                  // Disable for FE
                  required
                  aria-required="true"
                />
                {formData.activity_code?.toUpperCase() === "FE" && (
                  <small style={{ color: "red" }}></small>
                )}
              </FormField>

              <FormField>
                <Value>{formData.populated_description}</Value>
              </FormField>
              <FormField>
                <FormLabel htmlFor="additional_description">
                  Additional Description
                </FormLabel>
                <Textarea
                  id="additional_description"
                  name="additional_description"
                  value={formData.additional_description}
                  onChange={(e) =>
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      additional_description: e.target.value,
                    }))
                  }
                  placeholder="Add more details here"
                />
              </FormField>

              {["AU", "AR", "FE", "LP"].includes(
                formData.activity_code?.toUpperCase()
              ) && (
                  <FormField>
                    <FormLabel htmlFor="expense">Expense</FormLabel>
                    <FormInput
                      type="number"
                      id="expense"
                      name="expense"
                      value={formData.expense}
                      onChange={handleInputChange}
                      disabled={["LP", "AR", "AU"].includes(
                        formData.activity_code?.toUpperCase()
                      )}
                      required
                      aria-required="true"
                    />
                  </FormField>
                )}
              <FormButtonContainer>
                <FormButton
                  type="submit"
                  onClick={(e) => handleFormSubmit(e, "save")}
                  style={{ backgroundColor: "#6495ed" }}
                  aria-label="Save entry"
                >
                  Save
                </FormButton>

                <FormButton
                  type="button"
                  onClick={(e) => handleFormSubmit(e, "saveNew")}
                  style={{ backgroundColor: "#4caf50" }}
                  aria-label="Save and create new entry"
                >
                  Save & New
                </FormButton>

                <FormButton
                  type="button"
                  onClick={showTaskDatePicker}
                  style={{ backgroundColor: "#ffa500" }}
                  aria-label="Save and select follow-up date"
                >
                  Save & Create Task
                </FormButton>
                <CancelButton
                  type="button"
                  onClick={closeModal}
                  aria-label="Cancel and close form"
                >
                  Cancel
                </CancelButton>
              </FormButtonContainer>
            </form>
          </FormContainer>
        </Modal>

        <div
          className="reserve-page"
          style={{
            width: "100%" /* Ensure full width */,
            display: "flex",
            flexDirection: "column",
            margin: "0 auto" /* Optional: Center content horizontally */,
          }}
        >
          {isTaskDateModalVisible && (
            <>
              <ModalOverlay show={true} onClick={() => setIsTaskDateModalVisible(false)} />
              <Modal show={true}>
                <FormContainer>
                  <h2>Select Task Follow-Up Date</h2>
                  <FormField>
                    <FormLabel htmlFor="taskFollowUpDate">Task Follow-Up</FormLabel>
                    <div style={{ position: "relative", zIndex: 1000 }}>
                      <DatePicker
                        selected={taskFollowUpDate}
                        onChange={(date) => setTaskFollowUpDate(date)}
                        minDate={new Date()} // Prevent selecting past dates
                        id="taskFollowUpDate"
                        placeholderText="Select a follow-up date"
                        aria-label="Select task follow-up date"
                        inline // Ensures the picker is fully visible and always displayed within the modal
                      />
                    </div>
                    <div>
                      <Textarea
                        selected={taskFollowUpDescription}
                        onChange={(e) => setTaskFollowUpDescription(e.target.value)}
                        placeholder="Add a description for the task follow-up"
                        inline
                      />
                    </div>
                  </FormField>

                  <FormButtonContainer>
                    <FormButton
                      type="button"
                      onClick={handleSaveAndCreateTask}
                      aria-label="Confirm follow-up date"
                    >
                      Confirm
                    </FormButton>
                    <CancelButton
                      type="button"
                      onClick={() => setIsTaskDateModalVisible(false)}
                      aria-label="Cancel follow-up date selection"
                    >
                      Cancel
                    </CancelButton>
                  </FormButtonContainer>
                </FormContainer>
              </Modal>
            </>
          )}
          <TableContainer>
            <Table
              columns={columns}
              dataSource={timeExpenses}
              rowKey={(record) => record._id}
              pagination={{
                current: currentPage,
                pageSize: rowsPerPage,
                total: timeExpenses.length,
                onChange: (page, pageSize) => {
                  setCurrentPage(page);
                  setRowsPerPage(pageSize);
                },
              }}
              summary={() => {
                const totals = timeExpenses.reduce(
                  (acc, row) => {
                    acc.timeTotal += parseFloat(row.time || 0);
                    acc.expenseTotal += parseFloat(row.expense || 0);
                    acc.lineTotal += parseFloat(row.line_total || 0);
                    return acc;
                  },
                  { timeTotal: 0, expenseTotal: 0, lineTotal: 0 }
                );

                return (
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={5}/>
                    <Table.Summary.Cell index={1}>
                      <strong>TOTALS</strong>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2}>
                      <strong>{totals.timeTotal.toFixed(1)} hrs</strong>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3}>
                      <strong>${totals.expenseTotal.toFixed(2)}</strong>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4}>
                      <strong>${totals.lineTotal.toFixed(2)}</strong>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                );
              }}
            />
          </TableContainer>
        </div>

      </Container>
    );
  }

);

export default TimeExpenses;
