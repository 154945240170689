import {
  Breadcrumb,
  Button,
  Checkbox,
  Input,
  Modal,
  notification,
  Spin,
  Table,
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css"; // Lightbox styles
import styled from "styled-components";
import { useAuth } from "../../context/auth";

const Container = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Folders = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

const SelectDocumentsModal = ({ claimId, claimData, visible, onClose, onSelect }) => {
  const [filterText, setFilterText] = useState("");
  const [auth] = useAuth();
  const [documents, setDocuments] = useState([]);
  const [folders, setFolders] = useState([]);
  const [breadcrumb, setBreadcrumb] = useState(["Home"]);
  const [loading, setLoading] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);


  const currentFolder =
    breadcrumb.length > 1 ? breadcrumb.slice(1).join("/") : null; // Use null for root

  useEffect(() => {
    if (breadcrumb.length > 0 || currentFolder) {
      console.log("Breadcrumb updated:", breadcrumb);
      console.log("Current folder:", currentFolder || "Root");
    }
  }, [breadcrumb, currentFolder]);

  useEffect(() => {
    if (!claimId) {
      console.error("claimId is missing. Skipping fetchContents.");
      return;
    }

    console.log(
      "Fetching contents for claimId:",
      claimId,
      "Folder:",
      currentFolder || "Root"
    );
    fetchDocuments();
  }, [claimId, currentFolder]);
  useEffect(() => {
    if (visible) {
      fetchDocuments();
    } else {
      // Reset only necessary fields without clearing fetched data
      setFilterText("");
      setSelectedDocuments([]);
      setSelectedRowKeys([]);
      setBreadcrumb(["Home"]);
      setIsLightboxOpen(false);
      setCurrentImageIndex(0);
    }
  }, [visible]);
  
   
  const fetchDocuments = async () => {
    setLoading(true);
    try {
      const folderPath =
        breadcrumb.length > 1
          ? breadcrumb.slice(1).map(encodeURIComponent).join("/")
          : "";
      const fileNumber = claimData.fileNumber;

      console.log(
        "Fetching contents with claimId:",
        claimId,
        "Folder:",
        folderPath
      );

      const { data } = await axios.get(
        `/api/v1/claims/${fileNumber}/documents`,
        {
          params: { folder: folderPath },
          headers: { Authorization: `Bearer ${auth?.token}` },
        }
      );
      const normalizedDocuments = data.documents.map((doc) => {
        let cleanUrl = `https://hgmadjusters.sharepoint.com/sites/HGMDocuments/Shared%20Documents/claims/${fileNumber}/${folderPath}/${doc.name}`;
        if (cleanUrl) {
          cleanUrl = cleanUrl.replace(/([^:])\/\//g, "$1/"); // Normalize slashes
        }
        const isFolder = !doc.file; // Assuming `file` is undefined for folders
        const mimeType = isFolder ? "folder" : inferMimeType(doc.name);
        return {
          key: doc.id,
          sharePointId: doc.id,
          name: doc.name,
          url: cleanUrl,
          size: doc.file ? doc.size : null,
          type: mimeType, // Distinguish between files and folders
          createdDateTime: doc.fileSystemInfo?.createdDateTime,
          lastModifiedDateTime: doc.fileSystemInfo?.lastModifiedDateTime,
        };
      });

      // Separate folders and files
      const folders = normalizedDocuments.filter(
        (doc) => doc.type === "folder"
      );
      const files = normalizedDocuments.filter((doc) => doc.type !== "folder");

      console.log("Folders:", folders, "Files:", files);

      setFolders(folders);
      setDocuments(files);
    } catch (error) {
      console.error("Error fetching folder contents:", error.message);
      notification.error({ description: `Folder Empty or Unable to Fetch` });
    } finally {
      setLoading(false);
    }
  };
  const inferMimeType = (fileName) => {
    const extension = fileName.split(".").pop().toLowerCase();
    switch (extension) {
      case "jpg":
      case "jpeg":
        return "image/jpeg";
      case "png":
        return "image/png";
      case "gif":
        return "image/gif";
      default:
        return "application/octet-stream"; // Default MIME type
    }
  };
  
  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setIsLightboxOpen(true);
  };

  const handleNextImage = () => {
    setCurrentImageIndex((currentImageIndex + 1) % imageDocuments.length);
  };

  const handlePreviousImage = () => {
    setCurrentImageIndex(
      (currentImageIndex - 1 + imageDocuments.length) % imageDocuments.length
    );
  };

  // Filter only image files
  const imageDocuments = documents.filter((doc) =>
    ["image/jpeg", "image/png", "image/gif"].includes(doc.type)
  );

 
  const handleOk = () => {
    const selectedDocuments = documents.filter((doc) =>
      selectedRowKeys.includes(doc.key)
    );
    onSelect(selectedDocuments); // Pass selected documents to parent
  };
  const handleNavigateFolder = (folderName) => {
    setBreadcrumb((prev) => [...prev, folderName]);
  };

  const handleNavigateBreadcrumb = (index) => {
    setBreadcrumb(breadcrumb.slice(0, index + 1));
  };
  const handleSelectDocument = (documentKey, isChecked) => {
    setSelectedDocuments((prevSelected) => {
      if (isChecked) {
        // Add document to the selected list if not already included
        return [...prevSelected, documentKey];
      } else {
        // Remove document from the selected list
        return prevSelected.filter((key) => key !== documentKey);
      }
    });
  };

  const handleSelectAll = (isChecked) => {
    if (isChecked) {
      // Select all document keys
      const allKeys = documents.map((doc) => doc.key); // Use `key` instead of `_id`
      setSelectedDocuments(allKeys);
    } else {
      // Deselect all
      setSelectedDocuments([]);
    }
  };

  const columns = [
    {
      title: (
        <Checkbox
          checked={selectedRowKeys.length === documents.length}
          onChange={(e) =>
            setSelectedRowKeys(
              e.target.checked ? documents.map((doc) => doc.key) : []
            )
          }
        />
      ),
      dataIndex: "select",
      render: (_, record) => (
        <Checkbox
          checked={selectedRowKeys.includes(record.key)}
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedRowKeys((prev) => [...prev, record.key]);
            } else {
              setSelectedRowKeys((prev) =>
                prev.filter((key) => key !== record.key)
              );
            }
          }}
        />
      ),
    },
    {
      title: "Preview",
      dataIndex: "url",
      key: "preview",
      render: (url, record, index) =>
        ["image/jpeg", "image/png", "image/gif"].includes(record.type) ? (
          <img
            src={url}
            alt={record.name}
            style={{
              width: "50px",
              height: "50px",
              cursor: "pointer",
              objectFit: "cover",
            }}
            onClick={() => handleImageClick(index)}
            onError={(e) => {
              e.target.src = "https://via.placeholder.com/50"; // Fallback thumbnail
            }}
          />
        ) : (
          "-"
        ),
    },        
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => (
        <a href={record.url} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      ),
      onCell: () => ({ style: { padding: "5px 8px" } }),
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      render: (size) => (size ? `${(size / 1024).toFixed(2)} KB` : "-"),
      onCell: () => ({ style: { padding: "5px 8px" } }),
    },
    {
      title: "Modified",
      dataIndex: "lastModifiedDateTime",
      key: "modified",
      sorter: (a, b) => {
        const dateA = new Date(a.lastModifiedDateTime);
        const dateB = new Date(b.lastModifiedDateTime);
        return dateA - dateB; // Sort in descending order
      },
      defaultSortOrder: "descend", // Set default order to descending
      render: (lastModifiedDateTime) =>
        lastModifiedDateTime
          ? moment(lastModifiedDateTime).format("YYYY-MM-DD HH:mm:ss")
          : "-",
      onCell: () => ({ style: { padding: "5px 8px" } }),
    },
  ];

  return (
    <Modal
      title="Select Documents"
      visible={visible}
      onOk={handleOk}
      onCancel={onClose}
      width={800}
    >
    <Container>
      <Spin spinning={loading}>
        {/* Breadcrumb Navigation */}
        <Breadcrumb>
          <Breadcrumb.Item>
            <Button type="link" onClick={() => setBreadcrumb(["Home"])}>
              Home
            </Button>
          </Breadcrumb.Item>
          {breadcrumb.slice(1).map((crumb, index) => (
            <Breadcrumb.Item key={index}>
              <Button
                type="link"
                onClick={() => handleNavigateBreadcrumb(index + 1)}
              >
                {crumb}
              </Button>
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
               <Folders>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Input
              placeholder="Filter by description"
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
              style={{ width: "200px" }}
            />
          </div>
          {folders.length > 0 ? (
            <div style={{ marginBottom: 5 }}>
              <strong>Folders:</strong>
              <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                {folders.map((folder) => (
                  <Button
                    key={folder._id}
                    onClick={() => handleNavigateFolder(folder.name)}
                  >
                    {folder.name}
                  </Button>
                ))}
              </div>
            </div>
          ) : (
            <p></p>
          )}
        </Folders>
        <Table
          columns={columns}
          dataSource={documents
            ?.filter((doc) =>
              doc?.name?.toLowerCase().includes(filterText?.toLowerCase() || "")
            )
            .map((doc) => ({
              ...doc,
              key: doc.key,
            }))}
          rowKey="key"
        />
            
        {isLightboxOpen && (
          <Lightbox
            mainSrc={imageDocuments[currentImageIndex]?.url}
            nextSrc={imageDocuments[(currentImageIndex + 1) % imageDocuments.length]?.url}
            prevSrc={
              imageDocuments[
                (currentImageIndex - 1 + imageDocuments.length) % imageDocuments.length
              ]?.url
            }
            onCloseRequest={() => setIsLightboxOpen(false)}
            onMoveNextRequest={handleNextImage}
            onMovePrevRequest={handlePreviousImage}
            imageTitle={imageDocuments[currentImageIndex]?.name}
          />
        )}
      </Spin>
    </Container></Modal>
  );
};

export default SelectDocumentsModal;