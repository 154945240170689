import { Table } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Col, Row } from "reactstrap";
import styled from "styled-components";
import claim from "../../assets/claims.png";
import day_hour from "../../assets/day_hours.png";
import month_hour from "../../assets/month_hours.png";
import Layout from "../../components/Layout/Layout";
import { useAuth } from "../../context/auth";

const Container = styled.div`
  background: #eeeeee;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;


const TrackContainer = styled.div`
  width: 100%;
  height: 15vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    height: auto;
    gap: 20px;
    margin-bottom: 30px;
    align-items: center;
  }
`;

const OverTaskContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background: #ffffff;
  margin-bottom: 20px;
`;

const OverTaskTitleContainer = styled.div`
  width: 100%;
  height: 5vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ComingTaskContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background: #ffffff;
`;


const Input = styled.input`
  width: 270px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  outline: none;
  margin-top: 30px;
`;

const TrackComponent = styled.div`
  width: 368px;
  height: 96px;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  border-radius: 6px;
  align-items: center;
  padding-left: 20px;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

const TrackDetailComponent = styled.div`
  width: 91px;
  height: 64px;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
`;

const TrackTitleComponent = styled.div`
  height: 24px;
  width: 248px;
  font-size: 16px;
  color: #a5a5a5;
`;

const TrackCountComponent = styled.div`
  height: 32px;
  font-size: 32px;
  color: #000000;
`;

const SubText = styled.div`
  color: #000000;
  font-size: 20px;
  margin: 20px 0px 0px 20px;
`;

const SubNumber = styled.div`
  color: rgba(0, 0, 0, 45%);
  font-size: 14px;
  margin: 20px 0px 0px 20px;
`;

const TableContainer = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  margin: 20px 10px 20px 10px;
  @media only screen and (max-width: 768px) {
    overflow-x: auto; /* Enable horizontal scrolling */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;
  }
`;

const Dashboard = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [filter, setFilter] = useState("");
  const [monthHours, setMonthHours] = useState(0);
  const [data, setData] = useState([]);
  const [todayHours, setTodayHours] = useState(0);
  const [overdueTasks, setOverdueTasks] = useState([]);
  const [upcomingTasks, setUpcomingTasks] = useState([]);
  const [claimCount, setClaimCount] = useState(0);
  const [auth, setAuth] = useAuth();
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const rowsPerPage = 10; // Number of rows per page
  const [selectedClaim, setSelectedClaim] = useState();
  const [loading, setLoading] = useState(true);
  const [selectedLink, setSelectedLink] = useState("details");
  const { fileNumber } = useParams();
  const [claims, setClaims] = useState([]);
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  useEffect(() => {
    fetchClaimCount();
    getThisMonthHoursBilled();
    getTodayHoursBilled();
    getOverdueTasks();
    getUpcomingTasks();
  }, []);

  const handleFilterChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const fetchClaimCount = async () => {
    try {
      const { data } = await axios.get(
        `/api/v1/getClaimsStatusCountForUser?userId=${auth?.user?._id}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      if (data.success) {
        setClaimCount(data.openCount);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchHoursBilled = async (url, setHours) => {
    try {
      const { data } = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });

      if (data.success) {
        // Set the rounded total hours using parseFloat to avoid string assignment
        setHours(parseFloat(data.totalHours.toFixed(1)));
      } else {
        console.warn("Failed to fetch hours:", data.message || "Unknown error");
      }
    } catch (error) {
      console.error("Error fetching hours billed:", error);
      // Optionally, add user feedback (e.g., a toast notification)
    }
  };

  const getThisMonthHoursBilled = () => {
    const url = `/api/v1/getHoursBilledThisMonth?userId=${auth?.user?._id}`;
    fetchHoursBilled(url, setMonthHours);
  };

  const getTodayHoursBilled = () => {
    const url = `/api/v1/getHoursBilledToday?userId=${auth?.user?._id}`;
    fetchHoursBilled(url, setTodayHours);
  };


  const getOverdueTasks = async () => {
    try {
      const { data } = await axios.get(`/api/v1/getOverdueTasks`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
  
      console.log("API Response:", data);
  
      // Check if data is an array
      if (Array.isArray(data)) {
        const sortedTasks = data.sort((a, b) => {
          // Prioritize "First Contact" description
          const isFirstContactA = a.description?.toLowerCase() === "first contact";
          const isFirstContactB = b.description?.toLowerCase() === "first contact";
          const isProscribingA = a.description?.toLowerCase() === "reminder: claim proscription is approaching.";
          const isProscribingB = b.description?.toLowerCase() === "reminder: claim proscription is approaching.";
  
          if (isFirstContactA && !isFirstContactB) return -1;
          if (!isFirstContactA && isFirstContactB) return 1;
          if (isProscribingA &&!isProscribingB) return -1;
          if (!isProscribingA && isProscribingB) return 1;
  
          // If neither are "First Contact", sort by dueDate
          return new Date(a.dueDate) - new Date(b.dueDate);
        });
  
        setOverdueTasks(sortedTasks);
  
        const fileNames = sortedTasks.map((item) => {
          return item?.claim_id?.policyDetails?.name || "N/A";
        });
  
        console.log("Overdue File Names:", fileNames);
      } else {
        console.warn("Unexpected API response format:", data);
      }
    } catch (error) {
      console.error("Error fetching overdue tasks:", error);
    }
  };
  const getUpcomingTasks = async () => {
    try {
      const { data } = await axios.get(`/api/v1/getUpcomingTasks`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });

      console.log("API Response:", data);

      // Check if data is an array
      if (Array.isArray(data)) {
        const sortedTasks = data.sort(
          (a, b) => new Date(a.dueDate) - new Date(b.dueDate)
        );
        setUpcomingTasks(sortedTasks);

        const fileNames = sortedTasks.map((item) => {
          return item?.claim_id?.policyDetails?.name || "N/A";
        });

        console.log("File Names:", fileNames);
      } else {
        console.warn("Unexpected API response format:", data);
      }
    } catch (error) {
      console.error("Error fetching upcoming tasks:", error);
    }
  };

  const getClaims = async () => {
    try {
      const { data } = await axios.get("/api/v1/claims", {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      const claim = data?.claims?.filter(claim => (
        claim?.fileNumber === fileNumber
      ));
      if (claim?.length > 0) {
        setSelectedClaim(claim[0]);
        setSelectedLink("details");
      }
      if (data.success) {
        setClaims(data.claims);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };
  const handleSearch = async () => {
    if (query === "") {
      // getClaims();
    } else {
      try {
        const { data } = await axios.get(`/api/v1/claims/search/${query}`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        });

        if (data.success) {
          console.log("awdaw", data);
          // const foundClaim = data.claim;
          // setClaims([
          //   foundClaim,
          //   ...claims.filter((claim) => claim._id !== foundClaim._id),
          // ]);
          setClaims(data?.claim);
          toast.success(data.message);
        } else {
          console.log(data, "Awdwa");
          toast.error(data.message);
        }
      } catch (error) {
        console.error("Error searching claim", error);
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const overdueColumns = [
    {
      title: "File Number",
      dataIndex: "fileNumber",
      key: "fileNumber",
      sorter: (a, b) => a.fileNumber.localeCompare(b.fileNumber),
      render: (text, record) => (
        <Link to={`/claims/${record?.claim_id?.fileNumber}`}>
          <span style={{ color: "blue", cursor: "pointer", fontWeight: "bold" }}>{record?.claim_id?.fileNumber || "N/A"}</span>
        </Link>
      ),
      onCell: () => ({ style: { padding: "5px 8px", textAlign: "left", width: "120px" } }),
    },
    {
      title: "Name",
      key: "name",
      sorter: (a, b) =>
        (a.claim_id?.policyDetails?.name || "").localeCompare(b.claim_id?.policyDetails?.name || ""),
      render: (record) => (
        <span style={{ color: "red", cursor: "pointer", fontWeight: "bold" }}>
          {record.claim_id?.policyDetails?.name || "N/A"}
        </span>
      ),
      onCell: () => ({ style: { padding: "5px 8px", textAlign: "left", width: "350px" } }),
    },
    {
      title: "User",
      key: "userCode",
      sorter: (a, b) => (a.userCode || "").localeCompare(b.userCode || ""),
      render: (record) => (
        <span style={{ color: "red", cursor: "pointer", fontWeight: "bold" }}>
          {record.userCode || "N/A"}
        </span>
      ),
      onCell: () => ({ style: { padding: "5px 8px", textAlign: "left", width: "90px" } }),
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "dueDate",
      sorter: (a, b) => new Date(a.dueDate) - new Date(b.dueDate),
      render: (text) => (
        <span style={{ color: "red", cursor: "pointer", fontWeight: "bold" }}>
          {text ? moment(text).format("YYYY-MM-DD") : "-"}
        </span>
      ),
      onCell: () => ({ style: { padding: "5px 8px", textAlign: "left", width: "120px" } }),
    },
    {
      title: "Description",
      key: "description",
      sorter: (a, b) => (a.description || "").localeCompare(b.description || ""),
      render: (record) => <span style={{ color: "red", cursor: "pointer", fontWeight: "bold" }}>{record.description || "N/A"}</span>,
    },
  ];

  const upcomingColumns = [
    {
      title: "File Number",
      dataIndex: "fileNumber",
      key: "fileNumber",
      sorter: (a, b) => a.fileNumber.localeCompare(b.fileNumber),
      render: (text, record) => (
        <Link to={`/claims/${record?.claim_id?.fileNumber}`}>
          <span style={{ cursor: "pointer" }}>{record?.claim_id?.fileNumber || "N/A"}</span>
        </Link>
      ),
      onCell: () => ({ style: { padding: "5px 8px", textAlign: "left", width: "120px" } }),
    },
    {
      title: "Name",
      key: "name",
      sorter: (a, b) =>
        (a.claim_id?.policyDetails?.name || "").localeCompare(b.claim_id?.policyDetails?.name || ""),
      render: (record) => (
        <span style={{ color: "grey", cursor: "pointer", fontWeight: "bold" }}>
          {record.claim_id?.policyDetails?.name || "N/A"}
        </span>
      ),
      onCell: () => ({ style: { padding: "5px 8px", textAlign: "left", width: "350px" } }),
    },
    {
      title: "User",
      key: "userCode",
      sorter: (a, b) => (a.userCode || "").localeCompare(b.userCode || ""),
      render: (record) => record.userCode || "N/A",
      onCell: () => ({ style: { padding: "5px 8px", textAlign: "left", width: "90px" } }),
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "dueDate",
      sorter: (a, b) => new Date(a.dueDate) - new Date(b.dueDate),
      render: (text) => (text ? moment(text).format("YYYY-MM-DD") : "-"),
      onCell: () => ({ style: { padding: "5px 8px", textAlign: "left", width: "120px" } }),
    },
    {
      title: "Description",
      key: "description",
      sorter: (a, b) => (a.description || "").localeCompare(b.description || ""),
      render: (record) => <span>{record.description || "N/A"}</span>,
    },
  ];


  return (
    <Layout title="Dashboard - User">
      <Container>
        <Row className="align-items-center my-3 py-3">
          <Col md={12}>
            <h3 className="mt-3">Dashboard</h3>
          </Col>
          {/* <Col md={9}>
          
          <SearchBox
                  query={query}
                  setQuery={setQuery}
                  onSearch={handleSearch}
                />
          </Col> */}

        </Row>
        <TrackContainer>
          <TrackComponent>
            <img
              src={claim}
              style={{ width: "64px", height: "64px" }}
              alt="claim"
            />
            <TrackDetailComponent>
              <TrackTitleComponent>Open Claims</TrackTitleComponent>
              <TrackCountComponent>{claimCount}</TrackCountComponent>
            </TrackDetailComponent>
          </TrackComponent>
          <TrackComponent>
            <img
              src={month_hour}
              style={{ width: "64px", height: "64px" }}
              alt="month_hour"
            />
            <TrackDetailComponent>
              <TrackTitleComponent>Hours Billed this Month</TrackTitleComponent>
              <TrackCountComponent>{monthHours}</TrackCountComponent>
            </TrackDetailComponent>
          </TrackComponent>
          <TrackComponent>
            <img
              src={day_hour}
              style={{ width: "64px", height: "64px" }}
              alt="day_hour"
            />
            <TrackDetailComponent>
              <TrackTitleComponent>Hours Billed Today</TrackTitleComponent>
              <TrackCountComponent>{todayHours}</TrackCountComponent>
            </TrackDetailComponent>
          </TrackComponent>
        </TrackContainer>
        <OverTaskContainer>
          <OverTaskTitleContainer>
            <div
              style={{
                width: "250px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <SubText>Overdue Tasks</SubText>
              <SubNumber className="mt-4"><strong>{overdueTasks?.length}</strong> tasks</SubNumber>
            </div>
            <div>
              <Input
                type="text"
                placeholder="Filter by description"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                style={{ width: '200px', marginRight: '10px' }}
              />
            </div>
          </OverTaskTitleContainer>
          <TableContainer>
            <Table
              columns={overdueColumns}
              dataSource={overdueTasks.filter(task =>
                task.description?.toLowerCase()?.includes(filter.toLowerCase())
              )}
              rowKey={(record) => record._id || record.key} // Ensure fallback for unique key
              onRow={(record) => ({
                onClick: () => {
                  if (record?.claim_id?.fileNumber) {
                    navigate(`/claims/${record.claim_id.fileNumber}`);
                  }
                },
                style: { cursor: "pointer" }, // Add a pointer cursor for better UX
              })}
              pagination={{
                current: currentPage,
                pageSize: rowsPerPage,
                total: overdueTasks.length,
                onChange: (page) => setCurrentPage(page),
              }}
            />
          </TableContainer>
        </OverTaskContainer>

        <ComingTaskContainer>
          <OverTaskTitleContainer>
            <div
              style={{
                width: "250px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <SubText>Upcoming Tasks</SubText>
              <SubNumber className="mt-4"> <strong>{upcomingTasks?.length}</strong> tasks</SubNumber>
            </div>
            <div
              style={{
                width: "150px",
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
              }}
            >

            </div>
          </OverTaskTitleContainer>

          <TableContainer>
            <Table
              columns={upcomingColumns}
              dataSource={upcomingTasks.filter(task =>
                task.description?.toLowerCase().includes(filter?.toLowerCase() || "")
              )}
              rowKey={(record) => record._id} // Ensure each row has a unique key
              onRow={(record) => ({
                onClick: () => {
                  if (record?.claim_id?.fileNumber) {
                    navigate(`/claims/${record.claim_id.fileNumber}`);
                  }
                },
                style: { cursor: "pointer" }, // Add a pointer cursor for better UX
              })}
              pagination={{
                current: currentPage,
                pageSize: rowsPerPage,
                total: upcomingTasks.length,
                onChange: (page) => setCurrentPage(page),
              }}
            />
          </TableContainer>
        </ComingTaskContainer>
      </Container>
    </Layout>
  );
};

export default Dashboard;
