import React from "react";
import styled from "styled-components";
import Button from "../../components/common/Button";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Input = styled.input`
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Label = styled.label`
  margin: 5px 0;
`;

const AddAutomationForm = ({ formData, setFormData, handleSubmit, title, client, clientId }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: name === "repeats" ? value === "true" : value, // Convert "true"/"false" to boolean for repeats
    }));
  };
  

  const resetForm = () => {
    setFormData({
      dueDate: "",
      description: "",
      frequency: "",
      repeats: "",
      clientId: client?._id || "",
    });
  };

  return (
    <Form onSubmit={(e) => handleSubmit(e, resetForm)}>
      <h2>{title}</h2>

      {/* Display Parent Client Information */}
      <Label>Client</Label>
      <p>
        ({client?.name || "N/A"})
      </p>

      {/* Description Field */}
      <Label htmlFor="description">Description</Label>
      <Input
        id="description"
        type="text"
        name="description"
        value={formData.description || ""}
        onChange={handleChange}
        required
        placeholder="Enter task description"
      />

      {/* Number of Days After Opening */}
      <Label htmlFor="dueDate">Number of Days After Opening</Label>
      <Input
        id="dueDate"
        type="number"
        name="dueDate"
        value={formData.dueDate || ""}
        onChange={handleChange}
        required
        min="1"
        placeholder="Enter the number of days"
      />

      {/* Repeat Task Dropdown */}
      <Label htmlFor="repeats">Does this task repeat?</Label>
      <select
  id="repeats"
  name="repeats"
  value={formData.repeats ? "true" : "false"}
  onChange={(e) =>
    setFormData((prev) => ({
      ...prev,
      repeats: e.target.value === "true",
    }))
  }
  required
>
  <option value="">Select Yes/No</option>
  <option value="true">Yes</option>
  <option value="false">No</option>
</select>

{formData.repeats && (
  <>
    <label htmlFor="frequency">Frequency (in days)</label>
    <input
      type="number"
      id="frequency"
      name="frequency"
      value={formData.frequency || ""}
      onChange={(e) =>
        setFormData((prev) => ({
          ...prev,
          frequency: e.target.value,
        }))
      }
      required
    />
  </>
)}
      <Button type="submit">Submit</Button>
    </Form>
  );
};

export default AddAutomationForm;