import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { useAuth } from "../../context/auth";

const UserInvoiceReport = () => {
  const [auth] = useAuth();
  const [userCode, setUserCode] = useState("");
  const [userQuery, setUserQuery] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const isManager = auth?.user?.role_id?.name === "manager";

  // Set default dates and authenticated user
  useEffect(() => {
    const now = new Date();
    const firstDayPrevMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
    const lastDayPrevMonth = new Date(now.getFullYear(), now.getMonth(), 0);

    setStartDate(firstDayPrevMonth.toISOString().split("T")[0]);
    setEndDate(lastDayPrevMonth.toISOString().split("T")[0]);

    // Automatically set authenticated user as default
    if (auth?.user?.user_code && auth?.user?.name) {
      setUserCode(auth.user.user_code);
      setUserQuery(`${auth.user.user_code} - ${auth.user.name}`);
      setSelectedUser({
        user_code: auth.user.user_code,
        name: auth.user.name,
      });
    }
  }, [auth]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const { data } = await axios.get("/api/v1/users", {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      setUsers(data.users || []);
    } catch (error) {
      toast.error("Error fetching users.");
    }
  };

  const handleGenerateReport = async () => {
    if (!userCode || !startDate || !endDate) {
      alert("Please fill in all fields.");
      return;
    }
  
    setIsLoading(true);
    try {
      const response = await axios.get("/api/v1/reports/generateInvoiceReport", {
        params: { userCode, startDate, endDate },
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
        responseType: "blob", // Handle the response as binary data (PDF)
      });
  
      // Trigger file download
      const blob = new Blob([response.data], { type: "application/pdf" });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = `invoice_report_${userCode}_${startDate}_${endDate}.pdf`; // Adjusted filename to indicate date range
      document.body.appendChild(link);
      link.click();
      link.remove();
  
      toast.success("Report generated successfully!");
    } catch (error) {
      console.error("Error generating report:", error);
      toast.error("An error occurred while generating the report.");
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    <Container>
      <h3>Commisson Report</h3>
      <div style={{ marginBottom: "15px" }}>
        <label>Search User:</label>
        <InputField
          type="text"
          placeholder="Enter user code or name"
          value={userQuery}
          onChange={(e) => setUserQuery(e.target.value)}
          disabled={!isManager} // Disable the field if the user is not a manager
        />
        {userQuery && isManager && (
          <div
            style={{
              border: "1px solid #ddd",
              maxHeight: "150px",
              overflowY: "auto",
              position: "absolute",
              zIndex: 1000,
              backgroundColor: "#fff",
              width: "100%",
            }}
          >
            {users
              .filter(
                (user) =>
                  user.name.toLowerCase().includes(userQuery.toLowerCase()) ||
                  user.user_code.toLowerCase().includes(userQuery.toLowerCase())
              )
              .map((user) => (
                <div
                  key={user._id}
                  style={{
                    padding: "10px",
                    cursor: "pointer",
                    borderBottom: "1px solid #f0f0f0",
                    fontSize: "14px",
                  }}
                  onClick={() => {
                    setSelectedUser(user);
                    setUserCode(user.user_code); // Set userCode for the report
                    setUserQuery(`${user.user_code} - ${user.name}`); // Keep the selected user visible in the input field
                    toast.success(`Selected User: ${user.name}`);
                  }}
                >
                  {user.user_code} - {user.name}
                </div>
              ))}
          </div>
        )}
      </div>

      <div style={{ marginBottom: "15px" }}>
        <label htmlFor="startDate">Start Date:</label>
        <input
          type="date"
          id="startDate"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          style={{ width: "100%", padding: "8px", marginTop: "5px" }}
        />
      </div>

      <div style={{ marginBottom: "15px" }}>
        <label htmlFor="endDate">End Date:</label>
        <input
          type="date"
          id="endDate"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          style={{ width: "100%", padding: "8px", marginTop: "5px" }}
        />
      </div>

      <button
        onClick={handleGenerateReport}
        style={{
          width: "100%",
          padding: "10px",
          backgroundColor: "#007bff",
          color: "white",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
        }}
        disabled={isLoading}
      >
        {isLoading ? "Generating Report..." : "Generate Report"}
      </button>
    </Container>
  );
};

export default UserInvoiceReport;

const Container = styled.div`
  padding: 20px;
  width: 25%;
`;

const InputField = styled.input`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;
