import {
  Table
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaEllipsisV } from "react-icons/fa";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import styled from "styled-components";
import { useAuth } from "../../context/auth";
import Modal from "../common/Modal";
import AddTaskForm from "./AddTaskForm";

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Btn = styled.button`
  padding: 5px;
  background: #6495ed;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 4px;
  color: #ffffff;
  width: 50px;
  align-items: center;
  border: none;
  cursor: pointer;
  &:hover {
    background: #e0e4f3;
  }
`;

const Tasks = ({ claimId, userCode }) => {
  useEffect(() => {
    fetchTasks();
  }, []);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [taskId, setTaskId] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [formData, setFormData] = useState({
    dueDate: "",
    description: "",
    userCode: "",
  });
  const [auth] = useAuth();

  const [openDropdownOpenTasks, setOpenDropdownOpenTasks] = useState(null);
  const [openDropdownClosedTasks, setOpenDropdownClosedTasks] = useState(null);
  const [error, setError] = useState(null); // State to handle errors

  const toggleDropdownOpenTasks = (index) => {
    setOpenDropdownOpenTasks(openDropdownOpenTasks === index ? null : index);
  };

  const toggleDropdownClosedTasks = (index) => {
    setOpenDropdownClosedTasks(openDropdownClosedTasks === index ? null : index);
  };

  
  const fetchTasks = async () => {
    try {
      const { data } = await axios.get(
        `/api/v1/getopenCloseClaimTask?claimId=${claimId}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
  
      if (data.success) {
        // Sort tasks by dueDate, defaulting to createdAt if dueDate is unavailable
        const sortedTasks = data.claimTasks.sort((a, b) => {
          const dateA = new Date(a.dueDate || a.createdAt).getTime();
          const dateB = new Date(b.dueDate || b.createdAt).getTime();
          return dateA - dateB; // Ascending order
        });
  
        setTasks(sortedTasks);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("Something went wrong while getting the tasks");
    }
  };
  
  const handleErrorUI = () => (
    <div style={{ padding: "20px", textAlign: "center", color: "red" }}>
      <p>{error}</p>
      <Btn text="Retry" handleOnClick={fetchTasks} />
    </div>
  );
  const handleAddSubmit = async (event, resetForm) => {
    event.preventDefault();
    try {
      const { data } = await axios.post(
        `/api/v1/createClaimTask`,
        {
          ...formData, // Use formData.userCode
          claim_id: claimId,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      if (data.success) {
        resetForm();
        fetchTasks();
        setIsModalOpen(false);
        toast.success("Task added successfully!");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("Something went wrong while adding the task");
    }
  };
  
  const handleEditSubmit = async (event, resetForm) => {
    event.preventDefault();
    try {
      const { data } = await axios.put(
        `/api/v1/updateClaimTask/${taskId}`,
        {
          ...formData, // Use formData.userCode
          claim_id: claimId,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
  
      if (data.success) {
        resetForm();
        fetchTasks();
        setIsModalOpen(false);
        setTaskId(null);
        toast.success("Task updated successfully!");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("Something went wrong while updating the task");
    }
  };
  

  const handleMarkAsClose = async (taskId) => {
    try {
      const { data } = await axios.put(
        `/api/v1/updateTaskStatus/${taskId}`,
        {
          claimStatus: false,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );

      if (data.success) {
        fetchTasks();
        toast.success("Status changed");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("Something went wrong while getting the notes");
    }
  };

  const handleMarkAsOpen = async (taskId) => {
    try {
      const { data } = await axios.put(
        `/api/v1/updateTaskStatus/${taskId}`,
        {
          claimStatus: true,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );

      if (data.success) {
        fetchTasks();
        toast.success("Status changed");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("Something went wrong while getting the notes");
    }
  };

  const handleEditClick = (row) => {
    setFormData({
      ...row,
    });
    setTaskId(row?._id);
    setIsModalOpen(true);
  };

  const columnsOpenTasks = [
    {
      title: "Due Date",
      dataIndex: "dueDate",
      render: (text, row) => {
        const dateStr = row.dueDate || row.createdAt;
        
        if (!dateStr || isNaN(new Date(dateStr).getTime())) {
          return "N/A"; // Return fallback text if date is invalid
        }
      
        // Convert to UTC and format the date
        const utcDate = new Date(dateStr);
        const formattedDate = new Intl.DateTimeFormat("en-CA", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          timeZone: "UTC",  // Force UTC to avoid local timezone offsets
        }).format(utcDate);
      
        return formattedDate;
      },
      sorter: (a, b) => {
        const dateA = a.dueDate && !isNaN(new Date(a.dueDate).getTime()) ? new Date(a.dueDate).getTime() : new Date(a.createdAt).getTime();
        const dateB = b.dueDate && !isNaN(new Date(b.dueDate).getTime()) ? new Date(b.dueDate).getTime() : new Date(b.createdAt).getTime();
        return dateA - dateB;
      },
      onCell: () => ({ style: { padding: "5px 8px", textAlign: "left", width: "200px" } }),
    },
     
    {
      title: "User",
      dataIndex: "userCode",
      onCell: () => ({ style: { padding: "5px 8px", textAlign: "left", width: "120px" } }), // Align text to the right
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Action",
      render: (_, row, index) => (
        <Dropdown isOpen={openDropdownOpenTasks === index} toggle={() => toggleDropdownOpenTasks(index)}>
          <DropdownToggle className="bg-transparent border-0">
            <FaEllipsisV color="black" />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => handleMarkAsClose(row._id)}>Mark As Closed</DropdownItem>
            <DropdownItem onClick={() => handleEditClick(row)}>Edit</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      ),
      onCell: () => ({ style: { padding: "5px 8px", textAlign: "left", width: "75px" } }), // Align text to the right
    },
  ];

  const columnsClosedTasks = [
    {
      title: "Date Completed",
      dataIndex: "updatedAt",
      render: (text, row) => {
        const dateStr = row.updatedAt || row.createdAt;
    
        if (!dateStr || isNaN(new Date(dateStr).getTime())) {
          return "N/A"; // Return fallback text if date is invalid
        }
    
        return new Date(dateStr).toLocaleDateString("en-CA", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }); // Format to YYYY-MM-DD
      },
      sorter: (a, b) => {
        const dateA = a.updatedAt && !isNaN(new Date(a.updatedAt).getTime()) ? new Date(a.updatedAt).getTime() : new Date(a.createdAt).getTime();
        const dateB = b.updatedAt && !isNaN(new Date(b.updatedAt).getTime()) ? new Date(b.updatedAt).getTime() : new Date(b.createdAt).getTime();
        return dateA - dateB;
      },
      onCell: () => ({ style: { padding: "5px 8px", textAlign: "left", width: "200px" } }),
    },
       
    {
      title: "User",
      dataIndex: "userCode",
      onCell: () => ({ style: { padding: "5px 8px", textAlign: "left", width: "120px" } }), // Align text to the right
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Action",
      render: (_, row, index) => (
        <Dropdown isOpen={openDropdownClosedTasks === index} toggle={() => toggleDropdownClosedTasks(index)}>
          <DropdownToggle className="bg-transparent border-0">
            <FaEllipsisV color="black" />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => handleMarkAsOpen(row._id)}>Re-Open</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      ),
      onCell: () => ({ style: { padding: "5px 8px", textAlign: "left", width: "75px" } }), // Align text to the right
    },
  ];

  return (
    <Container>
     {error ? (
        handleErrorUI()
      ) : (
       <div>
        <h6>Open Tasks</h6>
      <Btn text="Add" handleOnClick={() => setIsModalOpen(true)}>Add</Btn>
      <Table
        dataSource={tasks.filter((task) => task.claimStatus)}
        columns={columnsOpenTasks}
        rowKey="_id"
        pagination={{ pageSize: 10 }}
      />
 
        <h6>Closed Tasks</h6>
    
      <Table
        dataSource={tasks.filter((task) => !task.claimStatus)}
        columns={columnsClosedTasks}
        rowKey="_id"
        pagination={{ pageSize: 5 }}
      />
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <AddTaskForm
          formData={formData}
          setFormData={setFormData}
          handleSubmit={taskId ? handleEditSubmit : handleAddSubmit}
          title={taskId ? "Edit Task" : "Add Task"}
        />
      </Modal>
    </div>
    )}
    </Container>
  );
};

export default Tasks;
