import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Autocomplete, IconButton, TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify"; // Assuming you're using toast for notifications
import { Col, Row } from "reactstrap";
import styled from "styled-components";
import * as Yup from "yup";
import { useAuth } from "../../context/auth";
import FillBtn from "../../shared/Buttons/FillBtn";

// Styled components
const Btn = styled.button`
  margin: 5px 5px 5px 0;
  padding: 5px 10px;
  width: 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const Field = styled.div`
  margin: 10px 0;
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  align-self: flex-start;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  outline: none;
`;

// Yup validation schema
const claimValidationSchema = Yup.object().shape({
  clients: Yup.array()
    .of(
      Yup.object().shape({
        client: Yup.object().required("Client is required"),
        claimNumber: Yup.string().required("Claim Number is required"),
        sharePercentage: Yup.string().required("Share Percentage is required"),
      })
    )
    .min(1, "At least one client is required"),
  dateOfLoss: Yup.date(),
  typeOfClaimId: Yup.string(),
  lossAddress: Yup.string(),
  lossPostalCode: Yup.string(),
  claimStatus: Yup.string(),
  examinerId: Yup.string(),
  typeOfLossId: Yup.string(),
  proscriptionDate: Yup.date(),
  adjusterId: Yup.string(),
  lossCity: Yup.string(),
  lossProvince: Yup.string(),
  lossDetail: Yup.string(),
});

const Details = ({ claim }) => {
  console.log("myClaimIdDetailComp", claim)

  const [claimId, setClaimId] = useState(claim?._id);
  const [detailsClaim, setDetailsClaim] = useState();
  const [auth] = useAuth();
  const [errors, setErrors] = useState({});
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState();
  const [examiners, setExaminers] = useState([]);
  const [adjusters, setAdjusters] = useState([]);
  const [claimTypes, setClaimTypes] = useState([]);
  const [lossTypes, setLossTypes] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);
  const [selectedAdjuster, setSelectedAdjuster] = useState("");
  const [selectedClaimType, setSelectedClaimType] = useState("");
  const [selectedLossType, setSelectedLossType] = useState("");
  const [selectedExaminer, setSelectedExaminer] = useState("");

  
  const [formData, setFormData] = useState({
    fileNumber: '',
    clients: [],
    claimNumber: '',
    sharePercentage: '',
    dateOfLoss: '',
    typeOfClaimId: '',
    lossAddress: '',
    lossPostalCode: '',
    claimStatus: '',
    examinerId: '',
    typeOfLossId: '',
    proscriptionDate: '',
    adjusterId: '',
    lossCity: '',
    lossProvince: '',
    lossDetail: '',
    rate: '',
    mileageRate: '',
  });
  useEffect(() => {
    if (claim) {
      setFormData({
        fileNumber: claim.fileNumber,
        clients: claim.clients || [],
        claimNumber: '',
        sharePercentage: '',
        dateOfLoss: claim.dateOfLoss?.substr(0, 10) || '',
        typeOfClaimId: claim.typeOfClaimId?._id || '',
        lossAddress: claim.lossAddress || '',
        lossPostalCode: claim.lossPostalCode || '',
        claimStatus: claim.claimStatus || '',
        examinerId: claim.examinerId?._id || '',
        typeOfLossId: claim.typeOfLossId?._id || '',
        proscriptionDate: claim.proscriptionDate?.substr(0, 10) || '',
        adjusterId: claim.adjusterId?._id || '',
        lossCity: claim.lossCity || '',
        lossProvince: claim.lossProvince || '',
        lossDetail: claim.lossDetail || '',
        rate: claim.rate || '',
        mileageRate: claim.mileageRate || '',
      });
    }
  }, [claim]);



  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value })); 
  };
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form submitted", formData);
    try {
      await claimValidationSchema.validate(formData, { abortEarly: false });
      const { our_files, ...restFormData } = formData;
      const url = `/api/v1/claims/${claim?._id}`;

      console.log("VALIDATED")

      const res = await axios.put(
        url,
        { formData: restFormData },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      const fileNumber = formData.fileNumber
      if (res && res.data.success) {
        toast.success(res.data.message);
        setFormData(""); // Reset the form after submission
        window.location.href = `/claims/${fileNumber}`;
      } else {
        toast.error(JSON.stringify(res.data.message));
      }
    } catch (err) {
      if (err.inner) {
        const validationErrors = {};
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        setErrors(validationErrors);
      }
      toast.error(err?.response?.data?.message || "Error occurred");
    }
  };

  // Fetch adjusters on component mount
  useEffect(() => {
    const fetchAdjusters = async () => {
      try {
        const response = await axios.get("/api/v1/users/adjusters", {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        });
        setAdjusters(response.data.adjusters); // Assuming the API response has a key "adjusters"
      } catch (error) {
        console.error("Error fetching adjusters:", error);
      }
    };

    fetchAdjusters();
  }, [auth?.token]);

  useEffect(() => {
    const fetchClaimTypes = async () => {
      try {
        const response = await axios.get("/api/v1/claim-types", {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        });
        setClaimTypes(response.data.claimTypes);
      } catch (error) {
        console.error("Error fetching claim types:", error);
      }
    };

    fetchClaimTypes();
  }, [auth?.token]);

  useEffect(() => {
    const fetchLossTypes = async () => {
      try {
        const response = await axios.get("/api/v1/loss-types", {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        });
        setLossTypes(response.data.lossTypes); // Assuming the API response has a key "lossTypes"
      } catch (error) {
        console.error("Error fetching loss types:", error);       
      }
    };

    fetchLossTypes();
  }, [auth?.token]);
  // Fetch examiners using PUT method
  useEffect(() => {
    const clientIds = claim.clients.map((item) => item.client._id);
    const fetchExaminers = async () => {
      try {
        // Await the API call to ensure we get the response before accessing response.data
        const response = await axios.post(
          "/api/v1/clients/contacts/examiners",
          { clientIds }, // Payload for the POST request
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          }
        );

       
        console.log("examinersUseEffect response:", response);
 
        if (response.data && response.data.examiners) {
          console.log("Examiners Data:", response.data.examiners);
          setExaminers(response.data.examiners);  
        } else {
          console.log("No examiners data found");
        }
      } catch (error) {
        console.error("Error fetching examiners:", error);
      }
    };

    fetchExaminers();
  }, [auth?.token]);

  useEffect(() => {
    // Fetch clients for the dropdown
    const fetchClients = async () => {
      try {
        const response = await axios.get("/api/v1/clients", {
          headers: { Authorization: `Bearer ${auth?.token}` },
        });
        setClients(response.data.clients || []);
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    };
    fetchClients();
  }, [auth?.token]);

  const addClient = (client, claimNumber, sharePercentage) => {
    setFormData((prevData) => {
      const existingClients = prevData.clients || [];

      if (client) {
        // Check if the client already exists in the list
        const clientExists = existingClients.some(
          (existingClient) => existingClient.client === client._id
        );

        if (!clientExists) {
          // Add the new client
          const updatedClient = {
            client: client,
            sharePercentage: sharePercentage,
            claimNumber: claimNumber, 
          };

          return {
            ...prevData,
            clients: [...existingClients, updatedClient],
          };
        }
      }

      return prevData; // Return unchanged data if no new client is selected
    });
  };

  const removeClient = (clientId) => {
    setFormData((prevData) => ({
      ...prevData,
      clients: prevData.clients.filter((client) => client._id !== clientId),
    }));
  };

  const handleAdjusterChange = (e) => {
    const selectedAdjusterId = e.target.value;
    setSelectedAdjuster(selectedAdjusterId);
    setFormData({ ...formData, adjusterId: selectedAdjusterId });
  };

  const handleClaimTypeChange = (e) => {
    const selectedClaimTypeId = e.target.value;
    setSelectedClaimType(selectedClaimTypeId);
    setFormData({ ...formData, typeOfClaimId: selectedClaimTypeId });
  };

  const handleLossTypeChange = (e) => {
    const selectedLossTypeId = e.target.value;
    setSelectedLossType(selectedLossTypeId);
    setFormData({ ...formData, typeOfLossId: selectedLossTypeId });
  };

  const handleExaminerChange = (e) => {
    const selectedExaminerId = e.target.value;
    setSelectedExaminer(selectedExaminerId);
    setFormData({ ...formData, examinerId: selectedExaminerId });
  };

  return (
      
    <Row>
      <Row>
        <Col md={5}>
          <Field style={{margin: "20px 0"}}>
            {/* <Label>Select Client</Label> */}
            <Autocomplete
                style={{fontSize: "14px"}}
                options={clients}
                getOptionLabel={(option) => option.name || ''}
                value={client}
                onChange={(event, newValue) => {
                  setClient(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Client"
                    placeholder="Search client..."
                    fullWidth
                  />
                )}
              />
            {errors.clients && <p>{errors.clients}</p>}
          </Field>
        </Col>

        <Col md={3}>
          <Field>
            <Label>Claim #</Label>
            <Input
              type="text"
              name="claimNumber"
              value={formData.claimNumber}
              onChange={handleChange}
            />
            {errors.claimNumber && <p>{errors.claimNumber}</p>}
          </Field>
        </Col>
        <Col md={3}>
              <Field>
            <Label>Share %</Label>
            <Input type="text" name="sharePercentage" value={formData.sharePercentage} onChange={handleChange}/>
            </Field>
        </Col>
        <Col md={1} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
          <IconButton variant edge="start" aria-label="add client" onClick={() => addClient(client, formData.claimNumber, formData.sharePercentage)}>
            <AddIcon />
          </IconButton>
        </Col>
      </Row>

      {/* <List>
        {
          formData.clients && 
          formData.clients.map((client, index) => (
            <ListItem key={index}>
              <ListItemText
                primary={`Client: ${client?.client?.name} - Share: ${client?.sharePercentage}% Claim Number: ${client?.claimNumber}`}
              />
              <IconButton edge="end" aria-label="delete" onClick={() => removeClient(client._id)}>
                <DeleteIcon />
              </IconButton>
            </ListItem>
          ))
        }
      </List> */}
      
      {
      formData.clients &&
      formData.clients.map((client, index) => (
        <Row key={index}>
          <Col md={5}>
            <Field style={{ margin: "20px 0" }}>
              <Autocomplete
                style={{ fontSize: "14px" }}
                options={clients}
                getOptionLabel={(option) => option.name || ""}
                value={client.client || null}
                onChange={(event, newValue) => {
                  setFormData((prevData) => {
                    const updatedClients = [...prevData.clients];
                    updatedClients[index] = {
                      ...updatedClients[index],
                      client: newValue,
                    };
                    return { ...prevData, clients: updatedClients };
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Client"
                    placeholder="Search client..."
                    fullWidth
                  />
                )}
              />
              {errors.clients && <p>{errors.clients}</p>}
            </Field>
          </Col>

          <Col md={3}>
            <Field>
              <Label>Claim #</Label>
              <Input
                type="text"
                name="claimNumber"
                value={client.claimNumber || ""}
                onChange={(event) => {
                  const { value } = event.target;
                  setFormData((prevData) => {
                    const updatedClients = [...prevData.clients];
                    updatedClients[index] = {
                      ...updatedClients[index],
                      claimNumber: value,
                    };
                    return { ...prevData, clients: updatedClients };
                  });
                }}
              />
              {errors.claimNumber && <p>{errors.claimNumber}</p>}
            </Field>
          </Col>

          <Col md={3}>
            <Field>
              <Label>Share %</Label>
              <Input
                type="text"
                name="sharePercentage"
                value={client.sharePercentage || ""}
                onChange={(event) => {
                  const { value } = event.target;
                  setFormData((prevData) => {
                    const updatedClients = [...prevData.clients];
                    updatedClients[index] = {
                      ...updatedClients[index],
                      sharePercentage: value,
                    };
                    return { ...prevData, clients: updatedClients };
                  });
                }}
              />
            </Field>
          </Col>

          <Col
            md={1}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton
              edge="start"
              aria-label="add client"
              onClick={() => removeClient(client._id)}
            >
              <DeleteIcon />
            </IconButton>
          </Col>
        </Row>
      ))
    }
      <Col md={12}>
        <form onSubmit={handleSubmit}>
          <Row className="d-flex align-items-center">

            <Col md={6}>
              <Field>
                <Label>Rate</Label>
                <Input
                  type="number"
                  name="rate"
                  value={formData.rate}
                  onChange={handleChange}
                />
                {errors.rate && <p>{errors.rate}</p>}
              </Field>
            </Col>
       

            <Col md={6}>
              <Field>
                <Label>Date of Loss</Label>
                <Input
                  type="date"
                  name="dateOfLoss"
                  value={formData.dateOfLoss}
                  onChange={handleChange}
                />
                {errors.dateOfLoss && <p>{errors.dateOfLoss}</p>}
              </Field>
            </Col>
            <Col md={6}>
              <Field>
                <Label>Select Claim Type:</Label>
                <select
                  name="typeOfClaimId"
                  value={formData.typeOfClaimId} // Use the form data value here
                  onChange={handleClaimTypeChange}
                  style={{
                    width: "100%",
                    padding: "10px",
                    border: "1px solid #ccc",
                    borderRadius: "6px",
                    outline: "none",
                  }}
                >
                  <option value="">Select Claim Type</option>
                  {claimTypes.map((claimType) => (
                    <option key={claimType._id} value={claimType?._id}>
                      {claimType?.description}
                    </option>
                  ))}
                </select>
                {errors.typeOfClaimId && <p>{errors.typeOfClaimId}</p>}
              </Field>
            </Col>
            <Col md={6}>
              <Field>
                <Label>Loss Address</Label>
                <Input
                  type="text"
                  name="lossAddress"
                  value={formData.lossAddress}
                  onChange={handleChange}
                />
                {errors.lossAddress && <p>{errors.lossAddress}</p>}
              </Field>
            </Col>
            <Col md={6}>
              <Field>
                <Label>Loss City</Label>
                <Input
                  type="text"
                  name="lossCity"
                  value={formData.lossCity}
                  onChange={handleChange}
                />
                {errors.lossCity && <p>{errors.lossCity}</p>}
              </Field>
            </Col>

            <Col md={6}>
              <Field>
                <Label>Loss Province</Label>
                <Input
                  type="text"
                  name="lossProvince"
                  value={formData.lossProvince}
                  onChange={handleChange}
                />
                {errors.lossProvince && <p>{errors.lossProvince}</p>}
              </Field>
            </Col>
            <Col md={6}>
              <Field>
                <Label>Loss Detail</Label>
                <Input
                  type="text"
                  name="lossDetail"
                  value={formData.lossDetail}
                  onChange={handleChange}
                />
                {errors.lossDetail && <p>{errors.lossDetail}</p>}
              </Field>
            </Col>
            <Col md={6}>
              <Field>
                <Label>Postal Code</Label>
                <Input
                  type="text"
                  name="lossPostalCode"
                  value={formData.lossPostalCode}
                  onChange={handleChange}
                />
                {errors.lossPostalCode && <p>{errors.lossPostalCode}</p>}
              </Field>
            </Col>

            <Col md={6}>
              <Field>
                <label htmlFor="examiner">Select Examiner:</label>
                <select
                  name="examinerId"
                  value={formData.examinerId} // This should be set from formData
                  onChange={handleExaminerChange}
                  style={{
                    width: "100%",
                    padding: "10px",
                    border: "1px solid #ccc",
                    borderRadius: "6px",
                    outline: "none",
                  }}
                >
                  <option value="">Select Examiner</option>
                  {examiners.map((examiner) => (
                    <option key={examiner._id} value={examiner._id}>
                      {examiner.name}
                    </option>
                  ))}
                </select>

                {errors.examinerId && <p>{errors.examinerId}</p>}
              </Field>
            </Col>
            <Col md={6}>
              <Label>
                Select Loss Type:
              </Label>
              <select
                name="typeOfLossId"
                value={formData.typeOfLossId} // This should be set from formData
                onChange={handleLossTypeChange}
                style={{
                  width: "100%",
                  padding: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "6px",
                  outline: "none",
                }}
              >
                <option value="">Select Loss Type</option>
                {lossTypes.map((lossType) => (
                  <option key={lossType._id} value={lossType._id}>
                    {lossType.description}
                  </option>
                ))}
              </select>

              {errors.typeOfLossId && <p>{errors.typeOfLossId}</p>}
            </Col>
            <Col md={6}>
              <Field>
                <Label>Proscription Date</Label>
                <Input
                  type="date"
                  name="proscriptionDate"
                  value={formData.proscriptionDate}
                  onChange={handleChange}
                />
                {errors.proscriptionDate && <p>{errors.proscriptionDate}</p>}
              </Field>
            </Col>
            <Col md={6}>
              <Field>
                <Label>Select Adjuster:</Label>
                <select
                  name="adjusterId"
                  value={formData.adjusterId} // Use the form data value here
                  onChange={handleAdjusterChange}
                  style={{
                    width: "100%",
                    padding: "10px",
                    border: "1px solid #ccc",
                    borderRadius: "6px",
                    outline: "none",
                  }}
                >
                  <option value="">Select Adjuster</option>
                  {adjusters.map((adjuster) => (
                    <option key={adjuster._id} value={adjuster?._id}>
                      {adjuster?.name}
                    </option>
                  ))}
                </select>
                {errors.adjusterId && <p>{errors.adjusterId}</p>}
              </Field>
            </Col>
            <Col md={6}>
              <Field>
                <Label>File #</Label>
                <Input
                  type="text"
                  name="fileNumber"
                  value={formData.fileNumber}
                  onChange={handleChange}
                />
                {errors.fileNumber && <p>{errors.fileNumber}</p>}
              </Field>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <FillBtn className="w-100" type="submit" text="Submit" />
            </Col>
          </Row>
        </form>
      </Col>
    </Row>
  );
};

export default Details;
