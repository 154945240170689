const EmailSignature = (user) => {
  if (!user) return "";

  return `
  <p><br/></p>
  <p><br/></p>
    <p>${user.name || "Your Name"}</p>
    <p><strong>HGM Adjusters Ltd.</strong></p>
    <p>P: ${user.work_phone || "Your Phone"} | F: 587-442-3927</p>
    <p>E-mail: <a href="mailto:${user.work_email || "your.email@example.com"}">${user.work_email || "your.email@example.com"}</a></p>
   <p>
  <span style="color: red;">
    <em>CONFIDENTIALITY:</em> This electronic transmission and any attached files 
    are intended solely for the person or entity to which they are addressed 
    and may contain information that is privileged, confidential, or otherwise 
    protected from disclosure. Any review, retransmission, dissemination, 
    or other use, including taking any action concerning this information 
    by anyone other than the named recipient, is strictly prohibited. 
    If you are not the intended recipient or have received this communication 
    in error, please immediately notify the sender and destroy this communication.
  </span>
</p>

  `;
};
export default EmailSignature;