import React, { useState } from "react";
import CommissionReports from "../../components/reports/commissionReport.js";
import BranchCommissionReports from "../../components/reports/branchCommissionReports.js";
import Layout from "../../components/Layout/Layout";
import { useAuth } from "../../context/auth";

const ManageReports = () => {
  const [auth] = useAuth();
  const [selectedReport, setSelectedReport] = useState("commission");

  const renderReport = () => {
    switch (selectedReport) {
      case "commission":
        return <CommissionReports />;
      case "branchCommission":
        return <BranchCommissionReports />;
      case "userCommission":
      default:
        return <div>Select a report to display</div>;
    }
  };
  const hasAccessToReport =
    auth?.user?.role_id?.name?.toLowerCase() === "manager" ||
    auth?.user?.role_id?.name?.toLowerCase() === "office admin" ||
    auth?.user?.role_id?.name?.toLowerCase() === "supervisor";
  
  return (
    <Layout title="Reports - User">
    <div style={{ display: "flex", height: "100vh" }}>
      {/* Sidebar */}
      <div style={{ width: "250px", background: "#f4f4f4", padding: "20px" }}>
        <h3>Reports</h3>
        <ul style={{ listStyleType: "none", padding: 0 }}>
          
        
          <li
            style={{
              padding: "10px",
              cursor: "pointer",
              background: selectedReport === "commission" ? "#ddd" : "transparent",
            }}
            onClick={() => setSelectedReport("commission")}
          >
            Commission Reports
          </li>
          {hasAccessToReport && (
          <li
            style={{
              padding: "10px",
              cursor: "pointer",
              background: selectedReport === "branchCommission" ? "#ddd" : "transparent",
            }}
            onClick={() => setSelectedReport("branchCommission")}
          >
            Branch Commission Reports
          </li>)}
        </ul>
      </div>
      
      {/* Main Content */}
      <div style={{ flex: 1, padding: "20px" }}>
       
        {renderReport()}
      </div>
    </div>
    </Layout>
  );
};

export default ManageReports;
