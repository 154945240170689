import React, { useState } from 'react';
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import hamburger from '../../assets/hamburger.png';
import AddClaimIcon from '../../assets/icons/add_claim_icon';
import AdminIcon from '../../assets/icons/admin_icon';
import BrokersIcon from '../../assets/icons/brokers_icon';
import ClaimsIcon from '../../assets/icons/claims_icon';
import ClientsIcon from '../../assets/icons/clients_icon';
import DashboardIcon from '../../assets/icons/dashboard_icon';
import ReportsIcon from '../../assets/icons/reports_icon';
import ResourcesIcon from '../../assets/icons/resources_icon';
import VendorsIcon from '../../assets/icons/vendors_icon';
import logo from '../../assets/logo.png';
import { useAuth } from "../../context/auth";

const SidebarParent = styled.div`
  background: #040848;
  padding: 0% 2%;
  display: flex;
  flex-direction: column;
  height: 100vh;
  @media only screen and (max-width: 768px) {
    width: auto;
    height: auto;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`;

const MenuContainer = styled.div`
  flex-direction: column;
  display: flex;
  @media only screen and (max-width: 768px) {
    display: ${props => props.open ? 'flex' : 'none'};
  }
`;

const MenuButton = styled.button`
  display: none;
  background: url(${hamburger}) no-repeat center center;
  background-size: contain;
  width: 44px;
  height: 44px;
  border: none;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    display: block;
  }
`;

const LogoComponent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LogoImage = styled.img`
  max-width: 100%;
  height: auto;
  object-fit: contain;
`;

const AddNewClaim = styled.div`
  font-size: 16px;
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  padding: 10px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
  border: 1px dashed #0337A4;
  border-radius: 6px;
`;

const NavLink = styled.a`
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 10px;
  transition: background-color 0.3s;
  &:hover {
    background-color: #0337A4;
    border-radius: 6px;
  }
`;

const IconWrapper = styled.span`
  margin-right: 10px;
`;

const UserLinks = ({ hasAccessToAdmin }) => (
  <>
    <NavLink href="/add-new-claim">
      <AddNewClaim>
        <IconWrapper><AddClaimIcon /></IconWrapper>
        <small>Add New Claim</small>
      </AddNewClaim>
    </NavLink>
    <NavLink href="/dashboard/user">
      <IconWrapper><DashboardIcon /></IconWrapper>
      Dashboard
    </NavLink>
    <NavLink href="/claims">
      <IconWrapper><ClaimsIcon /></IconWrapper>
      Claims
    </NavLink>
    <NavLink href="/clients">
      <IconWrapper><ClientsIcon /></IconWrapper>
      Clients
    </NavLink>
    <NavLink href="/vendors">
      <IconWrapper><VendorsIcon /></IconWrapper>
      Vendors
    </NavLink>
    <NavLink href="/brokers">
      <IconWrapper><BrokersIcon /></IconWrapper>
      Brokers
    </NavLink>
    <NavLink href="/reports">
      <IconWrapper><ReportsIcon /></IconWrapper>
      Reports
    </NavLink>
    <NavLink href="/resources">
      <IconWrapper><ResourcesIcon /></IconWrapper>
      Resources
    </NavLink>
    {hasAccessToAdmin && (
      <NavLink href="/admin">
        <IconWrapper><AdminIcon /></IconWrapper>
        Admin
      </NavLink>
    )}
  </>
);

const Sidebar = () => {
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const hasAccessToAdmin = auth?.user?.role_id?.name?.toLowerCase() === "manager" ||
                            auth?.user?.role_id?.name?.toLowerCase() === "ceo";

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    setAuth({ ...auth, user: null, token: "" });
    localStorage.removeItem("auth");
    toast.success("Logout Successfully");
    navigate(location.state || "/");
  };

  return (
    <SidebarParent>
      <LogoContainer>
        <LogoComponent>
          <LogoImage src={logo} alt="logo" />
        </LogoComponent>
        <MenuButton onClick={toggleMenu} />
      </LogoContainer>
      <MenuContainer open={isOpen}>
        {auth?.user ? (
          <>
            <h2 style={{ color: 'white' }}>{auth?.user?.name}</h2>
            <UserLinks hasAccessToAdmin={hasAccessToAdmin} />
            <NavLink onClick={handleLogout}>Logout</NavLink>
          </>
        ) : (
          <NavLink href="/login">Login</NavLink>
        )}
      </MenuContainer>
    </SidebarParent>
  );
};

export default Sidebar;
