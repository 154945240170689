import React from 'react';
import styled from 'styled-components';
import sorter from '../../../assets/sorter.png';
import deleteIcon from '../../../assets/delete.png';
import writeIcon from '../../../assets/Write.png';
import { Modal } from 'antd';

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  flex-grow: 1;
  @media only screen and (max-width: 768px) {
    overflow-x: auto; /* Enable horizontal scrolling */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  padding: 15px;
  position: relative;
  text-align: left;
  background: #e0e4f3;
  color: #444444;
  font-family: arial;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  white-space: nowrap;
  letter-spacing: 0.2px;
`;

const SorterIcon = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 15px;
  height: 36px;
  margin-left: 5px;
  background-image: url(${sorter});
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
`;

const TableRow = styled.tr`
  border-bottom: 1px solid #ddd;
`;

const TableCell = styled.td`
  text-align: left;
  padding: 15px;
  white-space: nowrap;
  color: #9fa2b4;
`;

const ActionIcon = styled.span`
  display: inline-block;
  width: 16px;
  height: 16px;
  background-size: cover;
  cursor: pointer;
  background-image: url(${props => props.ic});
`;

const VendorContactTable = ({ loading, vendorContacts, onEdit, onDelete }) => {
  const confirmDelete = (id) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this vendorContact?',
      content: 'This action cannot be undone.',
      onOk: () => onDelete(id),
      okText: 'Yes',
      cancelText: 'No',
    });
  };

  return (
    <TableContainer>
      {loading ? (
        <>Loading...</>
      ) : (
        <Table>
          <thead>
            <TableRow>
              <TableHeader>Name<SorterIcon /></TableHeader>
              <TableHeader>Role<SorterIcon /></TableHeader>
              <TableHeader>Phone<SorterIcon /></TableHeader>
              <TableHeader>Email<SorterIcon /></TableHeader>
              <TableHeader>Actions</TableHeader>
              <TableHeader></TableHeader>
            </TableRow>
          </thead>
          <tbody>
            {vendorContacts.length > 0 ? (
              vendorContacts.map((vendorContact) => (
                <TableRow key={vendorContact._id}>
                  <TableCell>{vendorContact.name}</TableCell>
                  <TableCell>{vendorContact.role}</TableCell>
                  <TableCell>{vendorContact.phone}</TableCell>
                  <TableCell>{vendorContact.email}</TableCell>
                  <TableCell>
                    <ActionIcon
                      ic={writeIcon}
                      onClick={() => onEdit(vendorContact)}
                    />
                  </TableCell>
                  <TableCell>
                    <ActionIcon
                      ic={deleteIcon}
                      onClick={() => confirmDelete(vendorContact._id)}
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan="6">No vendorContacts available</TableCell>
              </TableRow>
            )}
          </tbody>
        </Table>
      )}
    </TableContainer>
  );
};

export default VendorContactTable;
