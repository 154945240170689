import { Modal as AntdModal } from 'antd';
import React from 'react';
import styled from 'styled-components';

const StyledModal = styled(AntdModal)`
  .ant-modal-content {
    border-radius: 8px;
  }
`;


const Modal = ({ open, onClose, children, width = 500 }) => (
  <StyledModal open={open} onCancel={onClose} footer={null} width={width}>
    {children}
  </StyledModal>
);

export default Modal;
