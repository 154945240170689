import { Modal, Table } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import styled from "styled-components";
import hamburger from "../../assets/hamburger.png";
import Layout from "../../components/Layout/Layout";
import BrokerForm from "../../components/brokers/BrokerForm";
import Contacts from "../../components/brokers/contacts/Contacts";
import Details from "../../components/brokers/details";
import Documents from "../../components/brokers/documents";
import Button from "../../components/common/Button";
import SearchBox from "../../components/common/SearchBox";
import { useAuth } from "../../context/auth";

const MainContainer = styled.div`
  display: flex;
  min-height: 100vh;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  width: 450px;
  padding: 20px;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

const MenuButton = styled.button`
  display: none;
  background: url(${hamburger});
  width: 44px;
  height: 44px;
  margin: 20px;
  border: none;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    display: block;
  }
`;

const DetailsContainer = styled.div`
  padding: 20px;
  margin: 20px;
  flex-grow: 1;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 20px 0px;
`;

const TitlesContainer = styled.div`
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  overflow-x: auto;
  flex-direction: row;
  @media only screen and (max-width: 768px) {
    overflow-x: auto; /* Enable horizontal scrolling */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;
  }
`;

const Links = styled.a`
  margin: 0px 40px 10px 0px;
  padding-bottom: 20px;
  &:hover {
    border-bottom: 1px solid #6495ed;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-grow: 1;
`;

const MenuContainer = styled.div`
  flex-direction: column;
  display: flex;
  @media only screen and (max-width: 768px) {
    display: ${(props) => (props.open ? "block" : "none")};
  }
`;

const Brokers = () => {
  const [brokers, setBrokers] = useState([]);
  const [selectedBroker, setSelectedBroker] = useState();
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState("");
  const [auth] = useAuth();
  const [isAdding, setIsAdding] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [brokerTypeFilter, setBrokerTypeFilter] = useState("");
  const [brokerCityFilter, setBrokerCityFilter] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    address: {
      addressLine: "",
      city: "",
      province: "",
      postalCode: "",
    },
  });

  const [selectedLink, setSelectedLink] = useState("details");
  const [isOpen, setIsOpen] = useState(false); // State to control menu visibility

  const canUpdate = ["office admin", "manager", "supervisor"];

  const toggleMenu = () => {
    setIsOpen(!isOpen); // Toggle menu state
  };
  //get 5 recently added brokers
  const getBrokers = async () => {
    try {
      const { data } = await axios.get("/api/v1/brokers", {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      if (data.success) {
        setBrokers(data.brokers);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  const handleDelete = async (brokerId) => {
    try {
      const { data } = await axios.delete(`/api/v1/brokers/${brokerId}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      if (data.success) {
        setBrokers(brokers.filter((broker) => broker._id !== brokerId));
        setSelectedBroker(null);
        toast.success("Broker Deleted Sucessfully");
      }
    } catch (error) {
      console.error("Error deleting broker:", error);
      toast.error(error?.response?.data?.message);
    }
  };

  const handleSearch = async () => {
    try {
      const { data } = await axios.get(`/api/v1/brokers/search/${query}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });

      if (data.success && data.brokers) {
        setBrokers(data.brokers);
        toast.success(data.message);
      } else {
        setBrokers([]);
        toast.error(data.message || "No brokers or contacts found.");
      }
    } catch (error) {
      console.error("Error searching brokers and contacts:", error);
      setBrokers([]);
      toast.error(
        error?.response?.data?.message ||
          "An error occurred while searching for brokers."
      );
    }
  };

  const handleSubmit = async (e) => {
    const url = `/api/v1/brokers`;
    e.preventDefault();

    try {
      const res = await axios.post(
        url,
        {
          formData,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      if (res && res.data.success) {
        console.log(res.data.broker);
        setBrokers([res.data.broker, ...brokers]);
        setFormData({
          name: "",
          email: "",
          address: {
            addressLine: "",
            city: "",
            province: "",
            postalCode: "",
          },

        });

        toast.success(res.data && res.data.message);
      } else {
        toast.error(JSON.stringify(res.data.message));
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  //update a contact
  const handleUpdate = async (e) => {
    console.log(formData);
    const url = `/api/v1/brokers/${selectedBroker._id}`;
    e.preventDefault();
    try {
      const res = await axios.put(
        url,
        {
          formData,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      if (res && res.data.success) {
        const updatedBroker = res.data.broker;
        const updatedBrokers = brokers.filter(
          (broker) => broker._id !== updatedBroker._id
        );
        setBrokers([updatedBroker, ...updatedBrokers]);

        setIsModalOpen(false);

        toast.success(res.data && res.data.message);
      } else {
        toast.error(JSON.stringify(res.data.message));
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getBrokers();
  }, []);

  const handleSelectClick = (broker) => {
    setSelectedBroker(broker);
    setSelectedLink("details");
  };

  const confirmDelete = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this broker?",
      content: "This action cannot be undone.",
      onOk: () => handleDelete(id),
      okText: "Yes",
      cancelText: "No",
    });
  };

  const handleUpdateClick = () => {
    setFormData(selectedBroker);
    setIsModalOpen(true);
  };

  const handleAddClick = () => {
    setSelectedBroker(null);
    setIsAdding(true);
    setFormData({
      name: "",
      email: "",
      address: {
        addressLine: "",
        city: "",
        province: "",
        postalCode: "",
      },
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name), // Sorting by name (alphabetically)
      render: (text) => <a>{text}</a>, // Make the name clickable if needed
    },
    {
      title: "City",
      dataIndex: "address",
      key: "city",
      render: (address) => address?.city || "N/A",
      sorter: (a, b) => (a.address.city || "").localeCompare(b.address.city || ""),
    },
  ];
  

  return (
    <Layout title="Brokers - User">
      {loading ? (
        <>Loading....</>
      ) : (
        <>
          <MainContainer>
            <LeftContainer>
              <div
                style={{
                  display: "flex",
                  padding: "20px",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "30px",
                  }}
                >
                  Brokers <MenuButton onClick={toggleMenu} />
                </div>
                {canUpdate.includes(
                  auth?.user?.role_id?.name?.toLowerCase()
                ) && (
                  <Button
                    onClick={() => {
                      handleAddClick();
                    }}
                  >
                    Add{" "}
                  </Button>
                )}
              </div>
              <MenuContainer open={isOpen}>
              <div style={{ display: "flex", flexDirection: "column", gap: "10px", marginBottom: "5px" }}>
  {/* Search Box */}
  <SearchBox query={query} setQuery={setQuery} onSearch={handleSearch} />
</div>
<div style={{ display: "flex", flexDirection: "row", gap: "10px", marginBottom: "5px" }}>
  {/* Broker Type Filter Input */}
  <input
    type="text"
    placeholder="Filter by City..."
    value={brokerCityFilter}
    onChange={(e) => setBrokerCityFilter(e.target.value)}
    style={{
      padding: "10px",
      borderRadius: "5px",
      border: "1px solid #ccc",
      width: "50%",
      fontSize: "16px"
    }}
  />
</div>
<Table
  dataSource={brokers
    .filter((broker) =>
      broker.address.city.toLowerCase().includes(brokerCityFilter.toLowerCase())
    )
    .map((broker) => ({ ...broker, key: broker._id }))}
  columns={columns}
  loading={loading}
  pagination={{ pageSize: 5 }}
  onRow={(record) => ({
    onClick: () => handleSelectClick(record),
  })}
/>
</MenuContainer>
            </LeftContainer>
            <RightContainer>
  {selectedBroker ? (
    <DetailsContainer>
      <TopBar>
        <h3 style={{ margin: "0" }}>{selectedBroker.name}</h3>
        <div style={{ display: "flex" }}>
          {canUpdate.includes(auth?.user?.role_id?.name?.toLowerCase()) && (
            <>
              <Button onClick={() => handleUpdateClick()}>Update</Button>
              <Button
                style={{ backgroundColor: "red", marginLeft: "10px" }}
                onClick={() => confirmDelete(selectedBroker._id)}
              >
                Delete
              </Button>
            </>
          )}
        </div>

        <Modal
          open={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          footer={null}
        >
          <BrokerForm
            title={`Update Broker`}
            handleSubmit={handleUpdate}
            formData={formData}
            setFormData={setFormData}
          />
        </Modal>
      </TopBar>
      <br />
      <TitlesContainer>
        <Links onClick={() => setSelectedLink("details")}>Details</Links>
        <Links onClick={() => setSelectedLink("contacts")}>Contacts</Links>
        <Links onClick={() => setSelectedLink("documents")}>Documents</Links>
      </TitlesContainer>
      <FormContainer>
        {selectedLink === "details" && <Details broker={selectedBroker} />}
        {selectedLink === "contacts" && <Contacts broker={selectedBroker} />}
        {selectedLink === "documents" && (
          <Documents broker={selectedBroker} brokerId={selectedBroker._id} />
        )}

      </FormContainer>
    </DetailsContainer>
  ) : isAdding ? (
    <BrokerForm
      handleSubmit={handleSubmit}
      formData={formData}
      setFormData={setFormData}
      title={"Create New Broker"}
    />
  ) : (
    <DetailsContainer style={{ textAlign: "center", fontSize: "20px", color: "#555" }}>
      No Brokers Selected
    </DetailsContainer>
  )}
</RightContainer>

          </MainContainer>
        </>
      )}
    </Layout>
  );
};

export default Brokers;
