import React from 'react';

const VendorsIcon = () => {
  return (
    <div>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="3" y="6" width="14" height="8" rx="1" fill="white"/>
        <rect x="4" y="4" width="3" height="3" rx="0.5" fill="white"/>
        <rect x="13" y="4" width="3" height="3" rx="0.5" fill="white"/>
        <rect x="8.5" y="4" width="3" height="3" rx="0.5" fill="white"/>
        <line x1="3" y1="14" x2="17" y2="14" stroke="white" strokeWidth="1.5"/>
      </svg>
    </div>
  );
};

export default VendorsIcon;