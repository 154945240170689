import React from 'react';

const DashboardIcon = () => {
  return (
    <div>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="https:www.w3.org/2000/svg">
            <path d="M5.18509 3.83429C5.55556 3.58675 5.94455 3.37205 6.34761 3.19155C7.49083 2.67957 8.06245 2.42358 8.8229 2.91625C9.58336 3.40892 9.58336 4.21709 9.58336 5.83342V7.08342C9.58336 8.65477 9.58336 9.44044 10.0715 9.9286C10.5597 10.4168 11.3453 10.4168 12.9167 10.4168H14.1667C15.783 10.4168 16.5912 10.4168 17.0839 11.1772C17.5765 11.9377 17.3205 12.5093 16.8086 13.6525C16.6281 14.0556 16.4134 14.4445 16.1658 14.815C15.2959 16.1169 14.0595 17.1316 12.6129 17.7308C11.1664 18.33 9.57457 18.4868 8.03889 18.1813C6.50321 17.8758 5.09259 17.1218 3.98543 16.0147C2.87826 14.9075 2.12427 13.4969 1.81881 11.9612C1.51334 10.4255 1.67012 8.83376 2.26931 7.38717C2.8685 5.94059 3.8832 4.70418 5.18509 3.83429Z" fill="white"/>
            <path d="M17.8717 5.89076C17.1952 4.17351 15.8264 2.80474 14.1092 2.12824C12.8246 1.62216 11.6667 2.78589 11.6667 4.1666V7.49993C11.6667 7.96017 12.0398 8.33327 12.5 8.33327H15.8334C17.2141 8.33327 18.3778 7.17538 17.8717 5.89076Z" fill="white"/>
        </svg>
    </div>
  );
};

export default DashboardIcon;