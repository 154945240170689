import { Table } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaEllipsisV } from "react-icons/fa";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "reactstrap";
import styled from "styled-components";
import { useAuth } from "../../context/auth";
import ConfirmationModal from "../../shared/Confirmation";
import Modal from "../common/Modal";
import AddPartyForm from "./AddPartyForm";

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  height: auto; /* Allow the container to grow as needed */
`;


const HeaderContainer = styled.div`
  height: 5vh;
  border-radius: 4px 4px 0 0;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  flex-grow: 1;
  overflow: visible; /* Allow the container to expand as content grows */
  @media only screen and (max-width: 768px) {
    overflow-x: auto; /* Enable horizontal scrolling */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;
  }
`;



const TableRow = styled.div`
  display: table-row;
`;

const TableHeader = styled.div`
  display: table-cell;
  border-radius: 4px 4px 0 0;
  padding: 10px;
  background-color: #fafafa;
  font-weight: bold;
  text-align: left;
  border-bottom: 4px solid #ddd;
`;

const TableCell = styled.div`
  display: table-cell;
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
`;

// Button styled component
const Btn = styled.button`
  background-color: #6495ed;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 12px;
  cursor: pointer;
  margin-left: 20px;
  width: 100px;
  &:hover {
    background-color: #0069d9;
  }
`;

const Parties = ({ claimId, claimData }) => {
  const [auth, setAuth] = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [parties, setParties] = useState([]);
  const [selectedParty, setSelectedParty] = useState(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    company: "",
    claimRole: "",
    phone: [], // Default to empty array
    email: [], // Default to empty array
    address: "",
    city: "",
    province: "",
    postal_code: "",
  });

  const openEmail = (emailAddress) => {
    if (!claimData) {
      toast.error("Claim data is not available.");
      return;
    } const subject = `Insured: ${claimData.policyDetails.name} / Claim Number: ${claimData.clients[0].claimNumber} / File Number: ${claimData.fileNumber}`;
    const body = generateEmailBody(claimData);
    const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(
      subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  const generateEmailBody = (claimData) => `
  Insured: ${claimData.policyDetails.name}
  Mailing Address: ${claimData.policyDetails.address.addressLine}, ${claimData.policyDetails.address.city}, ${claimData.policyDetails.address.province}, ${claimData.policyDetails.address.postalCode}
  Loss Address: ${claimData.lossAddress}, ${claimData.lossCity}, ${claimData.lossProvince}, ${claimData.lossPostalCode}
  Date of Loss: ${claimData.dateOfLoss}
  Insurer: ${claimData.clients[0].client.name}
  Policy Number: ${claimData.policyDetails.policyNumber}
  Claim #: ${claimData.clients[0].claimNumber}
  Our File #: ${claimData.fileNumber}
`;

  useEffect(() => {
    fetchParties();
  }, [claimId]);
  const [openDropdown, setOpenDropdown] = useState(null);

  const openConfirmationModal = (id) => {
    setSelectedParty(id);
    setIsConfirmationModalOpen(true);
  };

  const toggleDropdown = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  const ViewPartyModal = ({ isOpen, onClose, party }) => (
    <Modal open={isOpen} onClose={onClose}>
      <h5>Party Details</h5>

      {party ? (
        <>
          <p>
            <strong>Name:</strong>
          </p>
          <p>{party.name}</p>
          <p>{party.address}</p>
          <p>{party.city}, {party.province} {party.postal_code}
          </p>
          <p>
            <strong>Claim Role:</strong></p><p>{party.claimRole}</p>
          <p>
            <strong>Company:</strong> </p><p>{party.company}
          </p>
          <p>
            <strong>Email:</strong>
          </p>
          {Array.isArray(party.email) ? (
            party.email.map((emailObj, index) => (
              <p
                key={index}
                style={{ cursor: "pointer", color: "blue" }}
                onClick={() => openEmail(emailObj.address)}
              >
                {emailObj.label}: {emailObj.address}
              </p>
            ))
          ) : (
            <p
              style={{ cursor: "pointer", color: "blue" }}
              onClick={() => openEmail(party.email)}
            >
              {party.email}
            </p>
          )}
          <p>
            <strong>Phone:</strong>
          </p>
          {Array.isArray(party.phone) ? (
            party.phone.map((phoneObj, index) => (
              <p key={index}>
                {phoneObj.label}: {phoneObj.number}
              </p>
            ))
          ) : (
            <p>{party.phone}</p>
          )}
        </>
      ) : (
        <p>Party details are not available.</p>
      )}
    </Modal>
  );

  const fetchParties = async () => {
    try {
      const { data } = await axios.get(`/api/v1/parties?claim_id=${claimId}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
  
      if (data.success) {
        // Sorting parties by claimRole alphabetically
        const sortedParties = data.parties1.sort((a, b) =>
          a.claimRole.localeCompare(b.claimRole)
        );
  
        setParties(sortedParties);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("Something went wrong while getting the parties");
    }
  };
  

  const handleAddContact = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `/api/v1/parties`,
        {
          ...formData,
          claim_id: claimId,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );

      if (data.success) {
        toast.success("Party created successfully");
        setIsModalOpen(false);
        fetchParties();
        setFormData({
          name: "",
          company: "",
          claimRole: "",
          phone: [{ number: "", label: "Cell" }], // Default to empty object
          email: [{ address: "", label: "Primary" }], // Default to empty object
          address: "",
          city: "",
          province: "",
          postal_code: "",
        });
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("Error creating party.");
    }
  };


  const handleEditClick = (party) => {
    const updatedFormData = {
      name: party?.name || "",
      company: party?.company || "",
      phone: Array.isArray(party?.phone)
        ? party.phone
        : [{ number: party.phone || "", label: "Cell" }],
      email: Array.isArray(party?.email) && party.email.length > 0
        ? party.email
        : [{ address: "", label: "Primary" }],
      address: party?.address || "",
      city: party?.city || "",
      province: party?.province || "",
      claimRole: party?.claimRole || "",
      postal_code: party?.postal_code || "",
    };

    setFormData(updatedFormData); // Set formData synchronously
    setSelectedParty(party); // Update selected party
    setIsModalOpen(true); // Open modal
  };



  const handleDeleteClick = async () => {
    if (!selectedParty) return;
    try {
      const { data } = await axios.delete(`/api/v1/parties/${selectedParty}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      if (data.success) {
        fetchParties();
        toast.success("Party deleted successfully");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("Something went wrong while deleting the party");
    }
    setIsConfirmationModalOpen(false);
  };

  const handleUpdateContact = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.put(
        `/api/v1/parties/${selectedParty._id}`,
        { ...formData },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      if (data.success) {
        fetchParties();
        setSelectedParty(null);
        setIsModalOpen(false);
        setFormData({
          name: "",
          company: "",
          phone: [{ number: "", label: "Cell" }], // Default to empty object
          email: [{ address: "", label: "Primary" }], // Default to empty object
          claimRole: "",
          address: "",
          city: "",
          province: "",
          postal_code: "",
        });
        toast.success("Party updated successfully");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("Something went wrong while updating the party");
    }
  };

  const handleViewParty = (party) => {
    setSelectedParty(party);
    setIsViewModalOpen(true);
  };

  const [searchTerm, setSearchTerm] = useState("");

  const filteredParties = parties
    .filter(
      (party) =>
        party.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        party.company?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        party.claimRole?.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      if (a.claimRole.toLowerCase() < b.claimRole.toLowerCase()) return -1;
      if (a.claimRole.toLowerCase() > b.claimRole.toLowerCase()) return 1;
      return 0;
    });


  const handleSubmit = (e, resetForm) => {
    e.preventDefault(); // Prevent form refresh
    if (selectedParty) {
      handleUpdateContact();
    } else {
      handleAddContact();
    }
    resetForm(); // Reset form after successful submission
  };



  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => (
        <span
          style={{ color: "blue", cursor: "pointer" }}
          onClick={() => handleViewParty(record)}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Claim Role",
      dataIndex: "claimRole",
      key: "claimRole",
      sorter: (a, b) => a.claimRole.localeCompare(b.claimRole),
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      sorter: (a, b) => a.company.localeCompare(b.company),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (emails) =>
        Array.isArray(emails)
          ? emails.map((email, index) => (
            <span
              key={index}
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => openEmail(email.address)}
            >
              {email.label}: {email.address}
              {index < emails.length - 1 && ", "}
            </span>
          ))
          : "No email provided",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      render: (phones) =>
        Array.isArray(phones)
          ? phones.map((phone, index) => (
            <span key={index}>
              {phone.label}: {phone.number}
              {index < phones.length - 1 && ", "}
            </span>
          ))
          : "No phone provided",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Dropdown
          isOpen={openDropdown === record}
          toggle={() => toggleDropdown(record)}
          direction="up"
        >
          <DropdownToggle className="bg-transparent border">
            <FaEllipsisV color="black" />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => handleEditClick(record)}>Edit</DropdownItem>
            <DropdownItem
  onClick={() => {
    setSelectedParty(record._id);
    setIsConfirmationModalOpen(true);
  }}
>
  Delete
</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      ),
      onCell: () => ({ style: { padding: "5px 8px", textAlign: "center", width: "75" } }), // Align text to the right
    },
  ];
  return (
    <>
      <Container>
        <HeaderContainer>
          <h5>Parties</h5>
        </HeaderContainer>
        <Btn
          onClick={() => {
            const newFormData = {
              name: "",
              company: "",
              claimRole: "",
              phone: [{ number: "", label: "Cell" }], // Default empty phone object
              email: [{ address: "", label: "Primary" }], // Default empty email object
              address: "",
              city: "",
              province: "",
              postal_code: "",
            };

            setFormData(newFormData); // Reset form data
            setSelectedParty(null); // Clear selected party
            setIsModalOpen(true); // Open modal
          }}
        >
          Add
        </Btn>

        <TableContainer>
          <Table dataSource={parties} columns={columns} rowKey="_id" />
          <ConfirmationModal
            isOpen={isConfirmationModalOpen}
            toggle={() => setIsConfirmationModalOpen(!isConfirmationModalOpen)}
            title="Confirm Action"
            body="Are you sure you want to delete this party?"
            onConfirm={handleDeleteClick}
          />
          <Modal
            open={isModalOpen}
            onClose={() => {
              setIsModalOpen(false);
              fetchParties(); // Fetch updated parties after closing the modal
            }}
          >
            <AddPartyForm
              key={selectedParty ? selectedParty._id : "new"} // Force remount based on selectedParty
              formData={formData}
              setFormData={setFormData}
              handleSubmit={selectedParty ? handleUpdateContact : handleAddContact}
            />
          </Modal>
          <ViewPartyModal
            isOpen={isViewModalOpen}
            onClose={() => setIsViewModalOpen(false)}
            party={selectedParty}
          />
        </TableContainer>
      </Container>
    </>
  );
};

export default Parties;