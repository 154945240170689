import React, { useState } from "react";
import styled from "styled-components";
// import usePolicyTypes from '../../../hooks/usePolicyTypes';
import { Col, Row } from "reactstrap";
import { useAuth } from "../../context/auth";
import usePolicyTypes from "../../hooks/usePolicyTypes";


const MainContainer = styled.div`
  padding: 20px;
  background-color: white;
  width: 100%;
`;

const Field = styled.div`
  margin: 10px 10px 10px 10px;
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-weight: bold;
  align-self: flex-start;
`;

const Value = styled.div`
  margin-left: 20px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin: 10px 0;
`;

const PolicyDetails = ({ claim, claimData }) => {
  const policytypes = usePolicyTypes(); // Fetch policy types
  const [auth] = useAuth();
  const [policyDetails, setPolicyDetails] = useState(
    claim?.policyDetails || {}
  );
  const [formData, setFormData] = useState({
    policyDetails: {
      policyNumber: claim?.policyDetails?.policyNumber || "",
      typeOfPolicyId: claim?.policyDetails?.typeOfPolicyId?._id || "",
      effectiveDate: claim?.policyDetails?.effectiveDate || "",
      expiryDate: claim?.policyDetails?.expiryDate || "",
      deductible: claim?.policyDetails?.deductible || 0,
      name: claim?.policyDetails?.name || "",
      address: claim?.policyDetails?.address?.addressLine || "",
      city: claim?.policyDetails?.address?.city || "",
      postalCode: claim?.policyDetails?.address?.postalCode || "",
      province: claim?.policyDetails?.address?.province || "",
      brokerId: claim?.brokerId || "",
    },
  });
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const [year, month, day] = dateString.split("T")[0].split("-");
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    return `${monthNames[parseInt(month, 10) - 1]} ${parseInt(day, 10)}, ${year}`;
  };

  return (
    <MainContainer>
      <Row>
        <Col md={12}>
          <h5>Policy Details</h5>
          <Row className="d-flex align-items-center">
            <Col md={6}>
              <Field>
                <Label>Insured</Label>
                <Value>{claim?.policyDetails?.name || ""}</Value>
                <Value>{claim?.policyDetails?.address?.addressLine || ""}</Value>
                <Value>
                  {claim?.policyDetails?.address?.city || ""}{" "}
                  {claim?.policyDetails?.address?.province || ""},{" "}
                  {claim?.policyDetails?.address?.postalCode || ""}
                </Value>
              </Field>
            </Col>
            <Col md={6}>
              <Field>
                <Label>Broker</Label>
                <Value>{claim?.brokerId.name || ""}</Value>
                <Value>{claim?.brokerId.address.addressLine || ""}</Value>
                <Value>
                  {claim?.brokerId.address.city || ""},{" "}
                  {claim?.brokerId.address.province || ""}{" "}
                  {claim?.brokerId.address.postalCode || ""}
                </Value>
                <Value>
                  {claim?.brokerId.email && (
                    <a
                      href={`mailto:${claim.brokerId.email
                        }?subject=${encodeURIComponent(
                          `Insured: ${claim?.policyDetails?.name || ""
                          } / Claim #: ${claim?.clients?.[0]?.claimNumber || ""
                          } / File #: ${claim?.fileNumber || ""}`
                        )}&body=${encodeURIComponent(
                          `Insured: ${claim?.policyDetails?.name || ""}
                          Mailing Address: ${claim?.policyDetails?.address?.addressLine || ""}, ${claim?.policyDetails?.address?.city || ""
                          }, ${claim?.policyDetails?.address?.province || ""} ${claim?.policyDetails?.address?.postalCode || ""
                          }
                          Loss Address: ${claim?.lossAddress || ""}, ${claim?.lossCity || ""}, ${claim?.lossProvince || ""
                          } ${claim?.lossPostalCode || ""}
                          Insurer: ${claim?.clients?.[0]?.client?.name || ""}
                          Policy Number: ${claim?.policyDetails?.policyNumber || ""}
                          Claim #: ${claim?.clients?.[0]?.claimNumber || ""}
                          Our File #: ${claim?.fileNumber || ""}`
                        )}`}
                      style={{ textDecoration: "none", color: "blue" }}
                    >
                      {claim.brokerId.email}
                    </a>
                  )}
                </Value>
              </Field>
            </Col>

            <Divider />
            <Col md={6}>
              <Field>
                <Label>Policy #</Label>
                <Value>{claim?.policyDetails?.policyNumber || ""}</Value>
              </Field>
            </Col>
            <Col md={6}>
              <Field>
                <Label>Policy Type</Label>
                <Value>
                  {claim?.policyDetails?.typeOfPolicyId?.description || ""}
                </Value>
              </Field>
            </Col>
            <Divider />

            <Col md={6}>
              <Field>
                <Label>Deductible</Label>
                <Value>${claim?.policyDetails?.deductible || ""}</Value>
              </Field>
            </Col>
            <Divider />
            <Col md={6}>
              <Field>
                <Label>Effective Date</Label>
                <Value>{formatDate(claim?.policyDetails?.effectiveDate)}</Value>
              </Field>
            </Col>
            <Col md={6}>
              <Field>
                <Label>Expiry Date</Label>
                <Value>{formatDate(claim?.policyDetails?.expiryDate)}</Value>
              </Field>
            </Col>
            <Divider />
          </Row>
        </Col>
      </Row>
    </MainContainer>
  );
};

export default PolicyDetails;
