import { Modal, Table } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Details from "../../components/claims/details";
import Documents from "../../components/claims/documents";
import EmailClient from "../../components/claims/emailClient";
import Notes from "../../components/claims/notes";
import Parties from "../../components/claims/parties";
import PolicyData from "../../components/claims/policyData";
import Reserves from "../../components/claims/reserves";
import Tasks from "../../components/claims/tasks";
import TimeExpenses from "../../components/claims/TimeExpenses";
import UpdateDetails from "../../components/claims/updateDetails";
import UpdatePolicyDetails from "../../components/claims/updatePolicyData";
import SearchBox from "../../components/common/SearchBox";
import Layout from "../../components/Layout/Layout";
import { useAuth } from "../../context/auth";

const StatusIcon = styled.span`
  display: inline-block;
  width: auto;
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: ${(props) => (props.isOpen ? "#27a843ff" : "red")};
  color: white;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  align-self: center; // Ensures alignment with the Options button
  margin-left: 10px;
  cursor: pointer;
`;

const MainContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  @media only screen and (max-width: 768px) {
    flex-direction: row;
  }
`;
const BodyContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
const Toggle = styled.button`
  background-color: transparent;
  color: black;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  padding: 4px;
  margin-right: 10px; /* Add a gap between toggle and menu */
`;

const DropdownContainer = styled.div`
  position: relative;
`;

const DropdownButton = styled.button`
  background-color: white;
  color: grey;
  font-size: 12px;
  font-weight: bold;
  font-family: Arial;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
  height: 30px;
  width: 100px;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const DropdownMenu = styled.div`
  display: ${(props) => (props.open ? "block" : "none")};
  position: absolute;
  top: 35px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 170px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

const DropdownItem = styled.div`
  padding: 10px;
  font-size: 14px;
  color: grey;
  font-weight: 500;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  &:hover {
    background-color: #f1f1f1;
  }
`;
const HeadContainer = styled.div`
  padding: 10px;
  position: relative;
  width: auto;
  margin: 10px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const SubHeadContainer = styled.div`
  padding: 2px 0;
  margin: 0;
  width: auto;
  background-color: white;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 5px;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const Item = styled.div`
  flex: 0 0 calc(50% - 10px);
`;

const KeyValue = styled.div`
  margin-bottom: 2px;
  display: flex;
  justify-content: space-between;
`;

const Key = styled.span`
  color: rgba(0, 0, 0, 0.45);
  font-size: 13px;
  font-weight: 500;
`;

const Value = styled.span`
  color: #000000;
  font-size: 13px;
  font-weight: 500;
`;


const DetailsContainer = styled.div`
  padding: 20px;
  margin: 20px;
  flex-grow: 1;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TableContainer = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  overflow-x: auto; /* Allow horizontal scrolling */
  @media only screen and (max-width: 768px) {
    width: 100%; /* Ensure it adjusts to the mobile screen width */
  }
`;

const TitlesContainer = styled.div`
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  overflow-x: auto;
  justify-content: space-between;
  border-bottom: 4px solid #e0e4f3;
  flex-direction: row;
  @media only screen and (max-width: 768px) {
    overflow-x: auto; /* Enable horizontal scrolling */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;
  }
`;

const Links = styled.a`
  text-decoration: none;
  cursor: pointer;
  background: ${(props) => (props.isActive ? "#bdd2f9" : "transparent")};
  padding: 8px;
  font-family: arial, sans-serif;
  border-radius: 4px 4px 0px 0px;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.2px;
  transition: background-color 0.3s ease;

  &:hover {
    text-decoration: none;
    cursor: pointer;
    background-color: ${(props) => (props.isActive ? "#bdd2f9" : "#e0e4f3")};
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-grow: ;
`;

const MenuContainer = styled.div`
  flex-direction: column;
  display: flex;
  width: 800%;
  height: 100%; /* Ensure it spans the full viewport height */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  @media only screen and (max-width: 768px) {
    display: ${(props) => (props.open ? "block" : "none")};
  }
`;

const SORT_ORDER = {
  ASC: "asc",
  DESC: "desc",
};

const Claims = () => {
  const [filterText, setFilterText] = useState("");
  const [claims, setClaims] = useState([]);
  const [lossTypes, setLossTypes] = useState([]);
  const [selectedClaim, setSelectedClaim] = useState();
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState("");
  const [auth] = useAuth();
  const { fileNumber } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false); // State for opening/closing the modal
  const [modalContent, setModalContent] = useState("");
  const [locationId, setLocationId] = useState(null);
  const [selectedLink, setSelectedLink] = useState("parties");
  const [searchIsOpen, setSearchIsOpen] = useState(true);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [statusDropdownVisible, setStatusDropdownVisible] = useState(false); // State to control dropdown visibility
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const rowsPerPage = 10; // Number of rows per page
  const [sortedClaims, setSortedClaims] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });

  const totalPages = Math.ceil(sortedClaims.length / rowsPerPage);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = sortedClaims.slice(indexOfFirstRow, indexOfLastRow);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const toggleStatusDropdown = () => {
    setStatusDropdownVisible((prev) => !prev);
  };
  const getNestedValue = (obj, keyPath) => {
    return keyPath.split('.').reduce((value, key) => {
      return value && value[key] !== undefined ? value[key] : null;
    }, obj);
  };

  useEffect(() => {
    setSortedClaims(claims);
  }, [claims]);

  const handleSort = (key) => {
    let direction = SORT_ORDER.ASC;
    if (sortConfig.key === key && sortConfig.direction === SORT_ORDER.ASC) {
      direction = SORT_ORDER.DESC;
    }

    const sorted = [...claims].sort((a, b) => {
      const valueA = getNestedValue(a, key);
      const valueB = getNestedValue(b, key);

      if (valueA < valueB) return direction === SORT_ORDER.ASC ? -1 : 1;
      if (valueA > valueB) return direction === SORT_ORDER.ASC ? 1 : -1;
      return 0;
    });

    setSortedClaims(sorted);
    setSortConfig({ key, direction });
    setCurrentPage(1); // Reset to the first page after sorting
  };


  const updateClaimStatus = async (newStatus) => {
    if (newStatus === 0) {
      // Show confirmation prompt for closing the claim
      Modal.confirm({
        title: "Are you sure?",
        content:
          "Do you really want to close this claim? All open tasks will be closed",
        okText: "Yes",
        cancelText: "Cancel",
        onOk: async () => {
          try {
            const { data } = await axios.put(
              `/api/v1/claims/${selectedClaim._id}/status`,
              { claimStatus: newStatus },
              {
                headers: {
                  Authorization: `Bearer ${auth?.token}`,
                },
              }
            );

            if (data.success) {
              setSelectedClaim((prev) => ({ ...prev, claimStatus: newStatus }));
              setClaims((prev) =>
                prev.map((claim) =>
                  claim._id === selectedClaim._id
                    ? { ...claim, claimStatus: newStatus }
                    : claim
                )
              );
              toast.success(
                `Claim ${newStatus === 1 ? "re-opened" : "closed"
                } successfully.`
              );
            } else {
              toast.error(data.message || "Failed to update claim status.");
            }
          } catch (error) {
            toast.error(
              error.response?.data?.message || "An unexpected error occurred."
            );
          } finally {
            setStatusDropdownVisible(false); // Ensure dropdown closes after action
          }
        },
        onCancel: () => {
          // Do nothing, just close the confirmation dialog
          setStatusDropdownVisible(false);
        },
      });
    } else {
      // Directly update status for re-opening
      try {
        const { data } = await axios.put(
          `/api/v1/claims/${selectedClaim._id}/status`,
          { claimStatus: newStatus },
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          }
        );

        if (data.success) {
          setSelectedClaim((prev) => ({ ...prev, claimStatus: newStatus }));
          setClaims((prev) =>
            prev.map((claim) =>
              claim._id === selectedClaim._id
                ? { ...claim, claimStatus: newStatus }
                : claim
            )
          );
          toast.success(`Claim re-opened successfully.`);
        } else {
          toast.error(data.message || "Failed to update claim status.");
        }
      } catch (error) {
        toast.error(
          error.response?.data?.message || "An unexpected error occurred."
        );
      } finally {
        setStatusDropdownVisible(false); // Ensure dropdown closes
      }
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev); // Toggle dropdown visibility
  };

  const handleDropdownAction = (action) => {
    console.log(`Action selected: ${action}`);
    // Add logic for the specific action
    setIsDropdownOpen(false); // Close the dropdown after an action
  };

  const getClaims = async () => {
    try {
      const { data } = await axios.get("/api/v1/claims", {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });

      if (data.success) {
        const sortedClaims = data.claims.sort((a, b) => {
          const fileNumA = parseInt(a.fileNumber.slice(5), 10);
          const fileNumB = parseInt(b.fileNumber.slice(5), 10);
          return fileNumB - fileNumA;
        });

        setClaims(sortedClaims);

        // Check if there's a matching claim for the provided fileNumber in the URL
        if (fileNumber) {
          const matchingClaim = sortedClaims.find(
            (claim) => claim?.fileNumber === fileNumber
          );

          if (matchingClaim) {
            setSelectedClaim(matchingClaim);
          } else {
            // Fetch the claim if not found in the list
            fetchClaimByFileNumber(fileNumber);
          }
        }
      } else {
        toast.error(data.message || "Failed to fetch claims.");
      }
    } catch (error) {
      console.error("Error fetching claims:", error);
      toast.error(error?.response?.data?.message || "An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };

  const fetchClaimByFileNumber = async (fileNumber) => {
    try {
      const { data } = await axios.get(`/api/v1/claims/fileNumber/${fileNumber}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });

      if (data.success && data.claim) {
        setSelectedClaim(data.claim);
        setSelectedLink("parties");
      } else {
        toast.error("You do not have access to this claim.");
      }
    } catch (error) {
      console.error("Error fetching claim by fileNumber:", error);
      toast.error(
        error?.response?.data?.message || "You do not have access to this claim."
      );
    }
  };



  const handleSearch = async () => {
    if (query === "") {
      getClaims();
    } else {
      try {
        const { data } = await axios.get(`/api/v1/claims/search/${query}`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        });

        if (data.success) {
          console.log("awdaw", data);
          setClaims(data?.claim);
          toast.success(data.message);
        } else {
          console.log(data, "Awdwa");
          toast.error(data.message);
        }
      } catch (error) {
        console.error("Error searching claim", error);
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const handleSelectClick = (claim) => {
    setSelectedClaim(claim);
    setSelectedLink("parties");
    setSearchIsOpen(false); // Hide the MenuContainer after selecting a claim
  };

  const handleUpdateClick = (content) => {
    setModalContent(content); // Set the desired content for the modal
    setIsModalOpen(true); // Open the modal
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
    setModalContent(""); // Reset modal content
  };

  useEffect(() => {
    getClaims();
  }, [fileNumber]);


  useEffect(() => {
    const fetchLossTypes = async () => {
      try {
        const response = await axios.get("/api/v1/loss-types", {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        });
        setLossTypes(response.data.lossTypes);
      } catch (error) {
        console.error("Error fetching loss types:", error);
      }
    };
    fetchLossTypes();
  }, [auth?.token]);

  useEffect(() => {
    // Close the MenuContainer if a claim is already selected
    if (selectedClaim) {
      setSearchIsOpen(false);
    }
  }, [selectedClaim]);
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const [year, month, day] = dateString.split("T")[0].split("-");
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    return `${monthNames[parseInt(month, 10) - 1]} ${parseInt(day, 10)}, ${year}`;
  };

  const getDescriptionById = (list, id) =>
    list.find((item) => item._id === id)?.description || "N/A";

  const columns = [
    {
      title: "File Number",
      dataIndex: "fileNumber",
      key: "fileNumber",
      sorter: (a, b) => a.fileNumber.localeCompare(b.fileNumber),
      render: (text, record) => (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => handleSelectClick(record)}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Name",
      key: "name",
      sorter: (a, b) =>
        (a.policyDetails?.name || "").localeCompare(b.policyDetails?.name || ""),
      render: (record) =>
        <span
          style={{ color: "grey", cursor: "pointer", fontWeight: "bold" }}
          onClick={() => handleSelectClick(record)}
        >
          {record.policyDetails?.name || "N/A"}
        </span>
    },
    {
      title: "Type of Loss",
      key: "typeOfLoss",
      render: (record) =>
        record.typeOfLossId?.description || "N/A", // Handle nested key safely
      sorter: (a, b) =>
        (a.typeOfLossId?.description || "").localeCompare(
          b.typeOfLossId?.description || ""
        ),
    },
    {
      title: "Client",
      key: "client",
      sorter: (a, b) =>
        (a.clients?.[0]?.client?.name || "").localeCompare(
          b.clients?.[0]?.client?.name || ""
        ),
      render: (record) =>
        <span
          style={{ cursor: "pointer" }}
          onClick={() => handleSelectClick(record)}
        >
          {record.clients?.[0]?.client?.name || "N/A"}
        </span>
    },
    {
      title: "Claim #",
      key: "claimNumber",
      sorter: (a, b) =>
        (a.clients?.[0]?.claimNumber || "").localeCompare(
          b.clients?.[0]?.claimNumber || ""
        ),
      render: (record) =>
        <span
          style={{ cursor: "pointer" }}
          onClick={() => handleSelectClick(record)}
        >
          {record.clients?.[0]?.claimNumber || "N/A"}
        </span>


    },
    {
      title: "Date of Loss",
      dataIndex: "dateOfLoss",
      key: "dateOfLoss",
      sorter: (a, b) => new Date(a.dateOfLoss) - new Date(b.dateOfLoss),
      render: (text) => (text ? moment(text).format("YYYY-MM-DD") : "-"),
    },
  ];


  return (
    <Layout title="Claims - User">
      {loading ? (
        <>Loading...</>
      ) : (
        <>
          <MainContainer>
            {searchIsOpen && (
              <MenuContainer>
                <h1>Claims</h1>
                <SearchBox
                  query={query}
                  setQuery={setQuery}
                  onSearch={handleSearch}
                /><br />
                <>
                  <TableContainer>
                    {loading ? (
                      <>Loading...</>
                    ) : (
                      <>
                        <Table
                          columns={columns}
                          dataSource={claims
                            ?.filter((claim) =>
                              claim.policyDetails?.name
                                ?.toLowerCase()
                                .includes(filterText.toLowerCase())
                            )}
                          rowKey={(record) => record._id} // Ensure unique key for each row
                          onRow={(record) => ({
                            onClick: () => handleSelectClick(record),
                          })}
                          pagination={{
                            current: currentPage,
                            pageSize: rowsPerPage,
                            total: claims.length,
                            onChange: (page) => setCurrentPage(page),
                          }}
                        />

                      </>
                    )}
                  </TableContainer>
                </>
              </MenuContainer>
            )}

            <BodyContainer>
              {selectedClaim ? (
                <>
                  <HeadContainer>
                    <SubHeadContainer>
                      <Item>
                        <KeyValue>
                          <Key>File #:</Key>{" "}
                          <Value>{selectedClaim.fileNumber}</Value>
                        </KeyValue>
                        <KeyValue>
                          <Key>Name:</Key>{" "}
                          <Value>{selectedClaim?.policyDetails?.name}</Value>
                        </KeyValue>
                        <KeyValue>
                          <Key>Date of Loss:</Key>{" "}
                          <Value>{formatDate(selectedClaim?.dateOfLoss)}
                          </Value>
                        </KeyValue>
                      </Item>
                      <Item>
                        <KeyValue>
                          <Key>Claim #:</Key>{" "}
                          <Value>{selectedClaim.clients[0].claimNumber}</Value>
                        </KeyValue>
                        <KeyValue>
                          <Key>Client:</Key>{" "}
                          <Value>
                            {selectedClaim?.clients[0]?.client.name}
                          </Value>
                        </KeyValue>
                        <KeyValue>
                          <Key>Examiner:</Key>{" "}
                          <Value>{selectedClaim?.examinerId?.name}</Value>
                        </KeyValue>
                      </Item>
                    </SubHeadContainer>
                    <SubHeadContainer>
                      <div>
                        <DropdownContainer>
                          <Toggle
                            onClick={() => setSearchIsOpen((prev) => !prev)}
                          >
                            {searchIsOpen ? "🡠" : "🡢"}
                          </Toggle>
                          <DropdownButton onClick={toggleDropdown}>
                            Menu
                          </DropdownButton>
                          <DropdownMenu open={isDropdownOpen}>
                            <DropdownItem
                              onClick={() => {
                                handleUpdateClick("Update Policy Details");
                                setIsDropdownOpen(false);
                              }}
                            >
                              Update Policy Details
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => {
                                handleUpdateClick("Update Claim Details");
                                setIsDropdownOpen(false);
                              }}
                            >
                              Update Claim Details
                            </DropdownItem>
                          </DropdownMenu>
                        </DropdownContainer>
                      </div>
                      {/* Status Icon with Dropdown */}
                      <div style={{ position: "relative" }}>
                        <StatusIcon
                          isOpen={selectedClaim.claimStatus === 1}
                          onClick={toggleStatusDropdown}
                        >
                          {selectedClaim.claimStatus === 1 ? "Open" : "Closed"}
                        </StatusIcon>
                        {statusDropdownVisible && (
                          <DropdownMenu open={statusDropdownVisible}>
                            {selectedClaim.claimStatus === 1 ? (
                              <DropdownItem
                                onClick={() => updateClaimStatus(0)}
                              >
                                Close Claim
                              </DropdownItem>
                            ) : (
                              <DropdownItem
                                onClick={() => updateClaimStatus(1)}
                              >
                                Re-open Claim
                              </DropdownItem>
                            )}
                          </DropdownMenu>
                        )}
                      </div>
                    </SubHeadContainer>
                  </HeadContainer>
                  <div />
                  <DetailsContainer>
                    <TitlesContainer>
                      <Links
                        onClick={() => setSelectedLink("policy-data")}
                        isActive={selectedLink === "policy-data"}
                      >
                        Policy Details
                      </Links>
                      <Links
                        onClick={() => setSelectedLink("details")}
                        isActive={selectedLink === "details"}
                      >
                        Claim Details
                      </Links>
                      <Links
                        onClick={() => setSelectedLink("parties")}
                        isActive={selectedLink === "parties"}
                        claimId={selectedClaim._id}
                      >
                        Parties
                      </Links>
                      <Links
                        onClick={() => setSelectedLink("documents")}
                        isActive={selectedLink === "documents"}
                      >
                        Documents
                      </Links>
                      <Links
                        onClick={() => setSelectedLink("tasks")}
                        isActive={selectedLink === "tasks"}
                      >
                        Tasks
                      </Links>
                      <Links
                        onClick={() => setSelectedLink("time-expenses")}
                        isActive={selectedLink === "time-expenses"}
                      >
                        Time & Expenses
                      </Links>
                      <Links
                        onClick={() => setSelectedLink("notes")}
                        isActive={selectedLink === "notes"}
                      >
                        Notes
                      </Links>
                      <Links
                        onClick={() => setSelectedLink("reserves")}
                        isActive={selectedLink === "reserves"}
                      >
                        Reserves
                      </Links>
                      <Links
                        onClick={() => setSelectedLink("emails")}
                        isActive={selectedLink === "emails"}
                      >Email
                      </Links>
                    </TitlesContainer>

                    <FormContainer>
                      {selectedLink === "details" && (
                        <Details
                          claim={selectedClaim}
                          locationId={locationId}
                          claimId={selectedClaim._id}
                        />
                      )}
                      {selectedLink === "policy-data" && (
                        <PolicyData claim={selectedClaim} />
                      )}
                      {selectedLink === "parties" && (
                        <Parties
                          claimId={selectedClaim._id}
                          claimData={selectedClaim}
                        />
                      )}
                      {selectedLink === "documents" && (
                        <Documents
                          claimId={selectedClaim._id}
                          locationId={locationId}
                          claimData={selectedClaim}
                        />
                      )}
                      {selectedLink === "tasks" && (
                        <Tasks
                          claimId={selectedClaim._id}
                          userCode={selectedClaim?.adjusterId?.user_code}
                        />
                      )}
                      {selectedLink === "time-expenses" && (
                        <TimeExpenses
                          mileageRate={
                            selectedClaim?.clients?.[0]?.client?.mileage_rate
                          }
                          cityAuto={
                            selectedClaim?.clients?.[0]?.client?.cityAuto
                          }
                          rate={selectedClaim?.rate}
                          claimData={selectedClaim}
                          claimId={selectedClaim._id}
                          userCode={selectedClaim?.adjusterId?.user_code}
                          setSelectedLink={setSelectedLink}
                        />
                      )}
                      {selectedLink === "notes" && (
                        <Notes claimId={selectedClaim._id} />
                      )}
                      {selectedLink === "reserves" && (
                        <Reserves claimId={selectedClaim._id} />
                      )}
                      {selectedLink === "emails" && (
                        <EmailClient
                          claimId={selectedClaim._id}
                          fileNumber={selectedClaim.fileNumber}
                          claimData={selectedClaim} />
                      )}
                    </FormContainer>
                  </DetailsContainer>
                </>
              ) : (
                <></>
              )}
            </BodyContainer>
          </MainContainer>
        </>
      )}
      <Modal
        title="Update Details"
        visible={isModalOpen}
        footer={null} // Hides the default footer, including "Ok" and "Cancel" buttons
        onCancel={closeModal} // You can still handle the modal close with this
      >
        {modalContent === "Update Policy Details" && (
          <UpdatePolicyDetails claim={selectedClaim} />
        )}
        {modalContent === "Update Claim Details" && (
          <UpdateDetails claim={selectedClaim} />
        )}
      </Modal>
    </Layout>
  );
};

export default Claims;
