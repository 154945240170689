import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from "styled-components";
import { Col, Row } from "reactstrap";
import { useAuth } from "../../context/auth";

const Container = styled.main`
  padding: 20px;
  overflow-y: auto;
  width: auto;
  flex: 1;
`;
const Btn = styled.button`
  margin: 30px;
  display: flex;
  justify-content: center;
  background-color: blue;
  color: white;
  border-radius: 6px;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  width: 25%;  
  border: none;
  &:hover {
    background-color: lightblue;
  }
`;


const Input = styled.input`
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 6px;
  outline: none;
`;

const UpdateProfile = ({ userId }) => {
    const [auth, setAuth] = useAuth();
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    city: '',
    province: '',
    postalCode: '',
    phone: '',
    email: '',
    sin: '',
    dob: '',
    license: '',
    answer: '',
  });

  useEffect(() => {
    // Fetch current user data
    axios
      .get(`/api/v1/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => setFormData(res.data.user))
      .catch((err) => console.error('Error fetching user data:', err));
  }, [userId, auth?.token]);
  
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
  
    const updatedFormData = {
      ...formData,
      dob: formData.dob ? new Date(formData.dob).toISOString() : null,
    };
  
    axios
      .put(`/api/v1/users/${userId}`, updatedFormData, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(() => alert('Profile updated successfully!'))
      .catch((err) => console.error('Error updating profile:', err));
  };
  

  return (
    <Container>
    <form onSubmit={handleSubmit}><Row><Col>
      Name<Input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Name"  /><p></p>
      Address<Input type="text" name="address" value={formData.address} onChange={handleChange} placeholder="Address"  /><p></p>
      Phone Number<Input type="tel" name="phone" value={formData.phone} onChange={handleChange} placeholder="Phone"  /><p></p>
           Date of Birth<Input
  type="date"
  name="dob"
  value={formData.dob ? new Date(formData.dob).toISOString().split('T')[0] : ''}
  onChange={handleChange}
/>
<p></p>
      </Col><Col>
      
     Email <Input type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Email"  /><p></p>
      SIN<Input type="text" name="sin" value={formData.sin} onChange={handleChange} placeholder="SIN" /><p></p>
      
      Adjuster License #<Input type="text" name="license" value={formData.license} onChange={handleChange} placeholder="Adjuster License #"  /><p></p>
      Your first pets name<Input type="password" name="answer" value={formData.answer} onChange={handleChange} placeholder="Your first pets name"  /><p></p>
      </Col></Row>
      <Btn type="submit">Update</Btn>
    </form>
    </Container>
  );
};

export default UpdateProfile;
