import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  width:100%;
  height:100%;
`;


const Item = styled.div`
  flex: 0 0 calc(33% - 20px); /* Two items per row with some space */
  margin: 10px;
`;

const Key = styled.span`
  color: rgba(0, 0, 0, 45%);
  font-size: 14px;
  text-align:left;
  margin-bottom:10px;
`;

const Value = styled.span`
  color : #000000;
  text-align:left;
`;

const KeyValue = styled.div`
  margin-bottom: 20px;
  display:flex;
  flex-direction:column;
`;



const Details = ({broker}) => {
    
      return (
        <Container>
          <Item>
           <KeyValue><Key>Name</Key> <Value>{broker.name}</Value>
           <Value>{broker.address.addressLine}</Value>
           <Value>{broker.address.city}, {broker.address.province}   {broker.address.postalCode}</Value></KeyValue>
           <Item>
           <KeyValue><Key>Email</Key> <Value>{broker.email}</Value>
           </KeyValue>
           <KeyValue><Key>Phone</Key> <Value>{broker.phone}</Value>
           </KeyValue>
         </Item>
         </Item>

        </Container>
      );
    
}

export default Details
