import React from "react";
import styled from "styled-components";
import { Search } from "@mui/icons-material";

const Box = styled.div`
  display: flex;
  height: 40px;
  width: auto;
`;

const Input = styled.input`
  border: 1px solid #ccc;
  border-radius: 6px;
  outline: none;
  padding: 5px;
  width: 250px;
`;

const SearchBox = ({ query, setQuery, onSearch }) => {
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      onSearch();
    }
  };

  return (
    <Box className="d-flex justify-content-start align-items-center">
      <Input
        type="text"
        placeholder="Search..."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      <Search onClick={onSearch} style={{ cursor: "pointer" }} />
    </Box>
  );
};

export default SearchBox;
